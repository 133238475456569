import React from "react";

export default function Alert({
  setAlert,
  type,
  message,
}: {
  [key: string]: any;
}) {
  return (
    <div
      className={`alert alert-${type} alert-dismissible position-absolute w-100 ${
        type && message ? "show" : ""
      }`}
    >
      {message.split("\n").map((txt: string, j: number) => (
        <React.Fragment key={j}>
          {txt}
          <br />
        </React.Fragment>
      ))}
      <button
        type="button"
        className="btn-close"
        onClick={() => {
          setAlert(["", ""]);
        }}
      />
    </div>
  );
}
