import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const data = Object.fromEntries(urlSearchParams.entries());
  const { provider } = useParams();
  let [error, setError] = useState<string>();
  const typ: string = data.state.charAt(8);

  useEffect(() => {
    const fetchFunction = async () => {
      const resp = await (
        await fetch(`/api/login/${provider}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
      ).json();

      if (resp.error) {
        setError(resp.error);
      } else {
        setError("");
        setTimeout(() => {
          window.location.href = typ === "3" ? "/profile#3" : "/";
        }, 500);
      }
    };
    fetchFunction();
  }, [provider, data, typ]);

  return (
    <div id="signup">
      <div className="login">
        <h1>ログイン</h1>
        {error === undefined ? (
          <div className="text-center py-4">
            <i className="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse me-2" />
            ログイン中...
          </div>
        ) : error ? (
          <>
            <div className="text-center text-danger py-4">{error}</div>
            <div className="signup text-center p-3">
              <Link to="/login">ログインはこちら</Link>
            </div>
          </>
        ) : (
          <div className="text-center py-4">
            {typ === "1" ? "会員登録" : typ === "3" ? "連携" : "ログイン"}
            しました
          </div>
        )}
      </div>
    </div>
  );
}
