/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";

export default function Select(args: any) {
  const val = args.value;
  const disable_values = args.disable_values || [];
  let [value, setValue] = useState<any>(val);
  let [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (!val) setValue(null);
  }, [val]);

  let display = <>選択してください</>;
  if (value) {
    for (let option of args.options) {
      if (option.value !== value) continue;
      display = <CustomOption {...option} />;
    }
  }

  return (
    <>
      <div
        className="form-control form-control-lg"
        onClick={(e) => {
          setActive(!active);
        }}
      >
        {display}
        <i className="fa-solid fa-angle-down" />
      </div>
      <input type="hidden" name={args.name} value={value} />
      {active && (
        <>
          <div className="cover" onClick={(e) => setActive(false)} />
          <ul>
            <li
              onClick={(e) => {
                setValue(null);
                setActive(false);
              }}
            >
              選択してください
            </li>
            {args.options.map((option: any, i: number) => (
              <li
                className={
                  disable_values.includes(option.value) ? "disabled" : ""
                }
                key={i}
                onClick={(e) => {
                  if (disable_values.includes(option.value)) return;

                  setValue(option.value);
                  setActive(false);
                  if (!args.onClick) return;
                  args.onClick(option.value);
                }}
              >
                <CustomOption {...option} />
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}

export const CustomOption = (args: any) => {
  // if (!args.value) {
  //   return (
  //     <div style={{ lineHeight: "26px", paddingLeft: "5px" }}>
  //       選択してください
  //     </div>
  //   );
  // }

  return (
    <dl>
      <dt className="icon-img">
        <div style={{ backgroundImage: `url("${args.src}")` }}></div>
      </dt>
      <dd>
        {args.disp}
        {args.sub && <small>{args.sub}</small>}
      </dd>
    </dl>
  );
};
