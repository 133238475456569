import { Link } from "react-router-dom";

export default function Main({
  current,
  date,
}: {
  current: string;
  date: string;
}) {
  if (date) date = `/${date}`;
  return (
    <ul className="nav nav-tabs mb-3">
      <li className="nav-item">
        <Link
          to={`/results${date}`}
          className={`nav-link ${current === "results" ? "active" : ""}`}
        >
          履歴
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={`/progress${date}`}
          className={`nav-link ${current === "progress" ? "active" : ""}`}
        >
          実績
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={`/recoveries${date}`}
          className={`nav-link ${current === "recoveries" ? "active" : ""}`}
        >
          回復
        </Link>
      </li>
    </ul>
  );
}
