import { useEffect, useState } from "react";
import Loading from "../../concern/Loading";
import { useParams } from "react-router-dom";
import Alert from "../../concern/Alert";
import Image from "../../concern/Image";
import Input, { Email, Name, Password } from "../../concern/Input";
import ThumbPath from "../../concern/ThumbPath";

let intervalID: NodeJS.Timeout | null = null;

const Row = (args: { [key: string]: any }) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className={`accordion-button${
            args.index === args.pos ? "" : " collapsed"
          }`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${args.index}`}
          onClick={(_) => {
            window.location.hash = args.index;
          }}
        >
          {args.title}
        </button>
      </h2>
      <div
        id={`collapse${args.index}`}
        className={`accordion-collapse collapse${
          args.index === args.pos ? " show" : ""
        }`}
        data-bs-parent="#accordion"
      >
        <div className="accordion-body">{args.children}</div>
      </div>
    </div>
  );
};

export default function Edit({ table = "trainings" }) {
  const thumbPath: string = ThumbPath((document as any).user || {});
  const { id } = useParams();
  let [row, setRow] = useState<{ [key: string]: any }>();
  let [alert, setAlert] = useState<[string, string]>(["", ""]);
  let [errors, setErrors] = useState<{ [key: string]: any }>();
  let [qr, setQR] = useState<string>("");
  const pos: number =
    parseInt(window.location.hash.replace(/[^0-9]/g, "")) || 1;

  useEffect(() => {
    (async () => {
      const resp = await (document as any).fetch("profile");
      setRow(Object.assign(resp.row, { isEdit: true }));
    })();
  }, [id, table]);

  useEffect(() => {
    intervalID = setTimeout(() => {
      setAlert(["", ""]);
    }, 5000);
  }, [alert]);

  if (row === undefined) return <Loading />;

  const setMessage = (type: string, message: string) => {
    clearTimeout(intervalID as NodeJS.Timeout);
    setAlert([type, message]);
  };

  const submit = async (e: any, page: string) => {
    e.preventDefault();

    const resp = await (document as any).fetch(`profile/${page}`, {
      method: "POST",
      target: e.target,
    });

    if (!resp.errors) {
      setErrors(undefined);
      setMessage(
        "success",
        page === "email"
          ? "メールアドレスにメッセージを送信しました。\nメッセージに従って変更を完了してください。"
          : "更新しました"
      );
      (document as any).querySelector('[name="unconfirmed_email"]').value = "";
      (document as any).querySelector('[name="current_password"]').value = "";
      (document as any).querySelector('[name="password"]').value = "";
    } else {
      setErrors(resp.errors);
    }
  };

  return (
    <main>
      <Alert setAlert={setAlert} type={alert[0]} message={alert[1]} />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 mb-3">
            <h2 className="header mb-0">設定</h2>
          </div>
        </div>
        <div className="accordion" id="accordion">
          <Row index={1} pos={pos} title="基本情報の変更">
            <form onSubmit={(e) => submit(e, "basic")}>
              <div className="mb-3">
                <Image defaultValue={thumbPath} />
              </div>
              <Name defaultValue={row!.name} errors={errors} />
              <div className="my-3">
                <Input
                  label="インターバル(秒)"
                  prefix={<i className="fa-regular fa-clock fa-fw" />}
                  name="interval_second"
                  type="number"
                  min={1}
                  defaultValue={row!.interval_second}
                  errors={errors}
                  autoComplete=""
                  required={false}
                />
              </div>
              <Input
                label="セット数"
                prefix={<i className="fa-solid fa-layer-group fa-fw" />}
                name="sets"
                type="number"
                min={1}
                defaultValue={row!.sets}
                errors={errors}
                autoComplete=""
                required
              />
              <div className="text-center mt-3">
                <button type="submit" className="btn btn-success">
                  保存する
                </button>
              </div>
            </form>
          </Row>
          {/* <Row index={6} pos={pos} title="AppleWatch連携">
            <input
              type="text"
              placeholder="Tracking Code"
              className="qrcode-text"
            />
            <label className="qrcode-text-btn">
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={(e) => {
                  const qrcode = (document as any).qrcode;
                  let node: any = e.target;
                  console.log(node);
                  var reader = new FileReader();
                  reader.onload = function () {
                    node.value = "";
                    qrcode.callback = function (res: any) {
                      if (res instanceof Error) {
                        window.alert(
                          "No QR code found. Please make sure the QR code is within the camera's frame and try again."
                        );
                      } else {
                        console.log(res);
                        node.parentNode.previousElementSibling.value = res;
                      }
                    };
                    qrcode.decode(reader.result);
                  };
                  reader.readAsDataURL(node!.files![0]);
                }}
              />
            </label>
            <input type="button" value="Go" disabled />
          </Row> */}
          <Row index={2} pos={pos} title="QRコードの表示">
            {row!.name ? (
              <div className="text-center">
                {qr ? (
                  <img src={qr} alt="" style={{ width: "300px" }} />
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={async () => {
                      const resp = await (document as any).fetch("profile/qr", {
                        method: "POST",
                      });
                      setQR(resp.src);
                    }}
                  >
                    QRコードを表示する
                  </button>
                )}
              </div>
            ) : (
              <div className="text-danger">ユーザ名が設定されていません。</div>
            )}
          </Row>
          <Row index={3} pos={pos} title="連携サービス">
            <form className="profile" onSubmit={(e) => submit(e, "email")}>
              <div className="btns">
                {Object.keys(row!.collaborates || {}).map((brand: string) => (
                  <a
                    key={brand}
                    href={(row!.collaborates[brand] || "").replace("***", "3")}
                    className={`text-body${
                      row!.collaborates[brand] ? "" : " done"
                    }`}
                    onClick={(e) => {
                      if (row!.collaborates[brand]) return;
                      e.preventDefault();
                      window.alert("連携済みです");
                    }}
                  >
                    <i className={`fa-brands fa-${brand} fa-fw`} />
                    {brand.charAt(0).toUpperCase() +
                      brand.slice(1).toLowerCase()}
                    <span>
                      連携{row!.collaborates[brand] ? "する" : "済み"}
                    </span>
                  </a>
                ))}
              </div>
            </form>
          </Row>
          <Row index={4} pos={pos} title="メールアドレスの変更">
            <form onSubmit={(e) => submit(e, "email")}>
              <Email
                label="現在のメールアドレス"
                name="current_email"
                defaultValue={row!.email}
                disabled
              />
              <div className="my-3">
                <Email label="新しいメールアドレス" errors={errors} />
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn btn-success">
                  保存する
                </button>
              </div>
            </form>
          </Row>
          <Row index={5} pos={pos} title="パスワードの変更">
            <form onSubmit={(e) => submit(e, "password")}>
              <Password
                placeholder=" "
                label="現在のパスワード"
                name="current_password"
                errors={errors}
              />
              <div className="my-3">
                <Password label="新しいパスワード" errors={errors} />
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn btn-success">
                  保存する
                </button>
              </div>
            </form>
          </Row>
          <Row index={7} pos={pos} title="アカウントの削除">
            <form
              onSubmit={async () => {
                const ok = window.confirm(
                  "アカウントを削除してもよろしいでしょうか？"
                );
                if (!ok) return;

                const resp = await (document as any).fetch("profile", {
                  method: "DELETE",
                });

                if (!resp.errors) {
                  window.location.reload();
                } else {
                  setErrors(resp.errors);
                }
              }}
            >
              <div className="text-center">
                <button type="submit" className="btn btn-danger">
                  削除する
                </button>
              </div>
            </form>
          </Row>
        </div>
      </div>
    </main>
  );
}
