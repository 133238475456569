import moment, { Moment } from "moment";
import { useEffect, useState } from "react";

let intervalID: NodeJS.Timeout | null = null;
const DELAY = 1000;

const getDays = (cur: Moment) => {
  const _cur = moment(cur).date(1);
  _cur.add(_cur.day() * -1, "days");
  const days: Moment[][] = [];
  for (let w = 0; w < 6; w++) {
    days[w] = [];
    for (let d = 0; d < 7; d++) {
      days[w].push(moment(_cur));
      _cur.add(1, "days");
    }
  }
  return days;
};

export default function Calendar(args: { [key: string]: any }) {
  let _date = new Date(args.defaultValue);
  if (isNaN(_date.getTime())) _date = new Date();
  const fetch: any = args.fetch !== undefined ? args.fetch : async () => {};
  const onChange: any =
    args.onChange !== undefined ? args.onChange : async () => {};

  let [date, setDate] = useState<Moment>(moment(_date));
  let [days, setDays] = useState<Moment[][]>(getDays(date));
  let [today] = useState<Date>(new Date());
  let [exists, setExists] = useState<string[]>();
  let [active, setActive] = useState<boolean>(false);

  const fetchFunction = async () => {
    const exists = await fetch(
      days[0][0].format("YYYY-MM-DD"),
      days[5][6].format("YYYY-MM-DD")
    );
    setExists(exists);
  };

  useEffect(() => {
    fetchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moveMonth = async (e: any, month: number) => {
    date.add("month", month);
    changeMonth(e, date.date(1));
  };

  const changeMonth = async (e: any, date: Moment) => {
    if (e) e.preventDefault();
    setExists(undefined);
    days = getDays(date);
    setDate(date);
    setDays(days);
    setExists(
      await fetch(
        days[0][0].format("YYYY-MM-DD"),
        days[5][6].format("YYYY-MM-DD")
      )
    );
  };

  return (
    <div
      className={`calendar${exists === undefined ? " loading" : ""}${
        active ? " active" : ""
      }`}
    >
      <div className="input-group">
        <a
          className="input-group-text"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setActive(true);
          }}
        >
          <i className="fa-regular fa-calendar-days" />
        </a>
        <input
          type="date"
          className="form-control"
          key={date.format("YYYY-MM-DD")}
          defaultValue={date.format("YYYY-MM-DD")}
          onChange={(e) => {
            if (intervalID) clearTimeout(intervalID as NodeJS.Timeout);
            intervalID = setTimeout(() => {
              const day = moment(e.target.value);
              setDate(day);
              onChange(day.format("YYYY-MM-DD"));
            }, DELAY);
          }}
        />
      </div>
      <div className="cover" onClick={() => setActive(false)} />
      <section>
        <header>
          <a href="/" onClick={(e) => moveMonth(e, -1)}>
            <i className="fa-solid fa-angle-left" />
          </a>
          <input
            type="month"
            key={date.format("YYYY-MM")}
            defaultValue={date.format("YYYY-MM")}
            onChange={(e) => {
              if (intervalID) clearTimeout(intervalID as NodeJS.Timeout);
              intervalID = setTimeout(() => {
                changeMonth(e, moment(`${e.target.value}-01`));
              }, DELAY);
            }}
          />
          <a href="/" onClick={(e) => moveMonth(e, 1)}>
            <i className="fa-solid fa-angle-right" />
          </a>
        </header>
        <table>
          <thead>
            <tr>
              {days[0].map((day) => (
                <th key={day.day()}>{day.format("ddd")}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {days.map((week, i) => (
              <tr key={i}>
                {week.map((day) => (
                  <td
                    key={day.format("YYYY-MM-DD")}
                    data-day={day.date()}
                    data-month={day.month()}
                    data-year={day.year()}
                    data-ymd={day.format("YYYY-MM-DD")}
                    data-ym={day.format("YYYY-MM")}
                    data-md={day.format("MM-DD")}
                    className={`${
                      exists?.includes(day.format("YYYY-MM-DD")) ? "exist" : ""
                    }${
                      day.format("YYYY-MM-DD") ===
                      moment(today).format("YYYY-MM-DD")
                        ? " today"
                        : ""
                    }${
                      day.format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
                        ? " current"
                        : ""
                    }`}
                    onClick={() => {
                      setDate(day);
                      onChange(day.format("YYYY-MM-DD"));
                      setActive(false);
                    }}
                  >
                    {day.format("D")}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}
