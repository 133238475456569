import React from "react";
import { useState } from "react";
import Input from "../concern/Input";

export default function Forget() {
  let [errors, setErrors] = useState<{ [key: string]: any }>();
  let [success, setSuccess] = useState<string>();

  return (
    <>
      <form
        className="py-4 px-3"
        onSubmit={async (e: any) => {
          e.preventDefault();
          const formData = new FormData(e.target);
          const data = Object.fromEntries(formData.entries());

          const resp = await (document as any).fetch("signup/forgot", {
            method: "POST",
            body: JSON.stringify(data),
          });

          if (!resp.errors) {
            setErrors(undefined);
            setSuccess(
              "ご登録のメールアドレス宛にパスワード変更のためのメールをお送りしました。\n本文に記載されているURLを開いてパスワードの変更手続きに進んでください。"
            );
            (document as any).querySelector('[name="email"]').value = "";
          } else {
            setSuccess(undefined);
            setErrors(resp.errors);
          }
        }}
      >
        <Input
          label="メールアドレス"
          prefix={<i className="fa-solid fa-envelope fa-fw" />}
          type="email"
          name="email"
          errors={errors}
          autoComplete=""
          required
          onChange={(e: any) => {
            setSuccess(undefined);
            setErrors(undefined);
          }}
        />
        {success && (
          <div className="text-success mt-3">
            {success.split("\n").map((txt: string, j: number) => (
              <React.Fragment key={j}>
                {txt}
                <br />
              </React.Fragment>
            ))}
          </div>
        )}
        <div className="mx-2 mt-4 mb-2 ">
          指定のメールアドレスに
          <br />
          パスワードリセットのリンクを送信します。
        </div>
        <div className="d-grid">
          <button className="btn btn-lg btn-primary">送信</button>
        </div>
      </form>
    </>
  );
}
