import { Link } from "react-router-dom";

export default function ShowHeader(args: { [key: string]: any }) {
  return (
    <div className="row align-items-center">
      <div className="col-sm-6 mb-3">
        <h2 className="header mb-0">{args.title}</h2>
      </div>
      <div className="col-sm-6 mb-3 text-end">
        <Link to={args.to} className="btn btn-outline-secondary">
          一覧へ
        </Link>
      </div>
    </div>
  );
}
