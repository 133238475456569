import React from "react";

export default function NotFound(args: { [key: string]: any }) {
  if (args.count < 2) return <></>;

  return (
    <nav className="mt-3">
      <ul className="pagination justify-content-center">
        {Array(args.count)
          .fill("")
          .map((_, i) => (
            <React.Fragment key={i}>
              {i + 1 === args.page ? (
                <li className="page-item active" aria-current="page">
                  <span className="page-link">{i + 1}</span>
                </li>
              ) : (
                <li className="page-item">
                  <a
                    className="page-link"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      args.onClick(i + 1);
                    }}
                  >
                    {i + 1}
                  </a>
                </li>
              )}
            </React.Fragment>
          ))}
      </ul>
    </nav>
  );
}
