import { useEffect, useState } from "react";
import Alert from "../../concern/Alert";
import Body from "../../concern/Body";
import Loading from "../../concern/Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import qs from "qs";
import ShowHeader from "../../concern/ShowHeader";
import NotFound from "../../concern/NotFound";
import CustomSelect, { CustomOption } from "../../concern/CustomSelect";
import ThumbPath from "../../concern/ThumbPath";

let intervalID: NodeJS.Timeout | null = null;
const table = "menus";

const formDataToQueryString = (formData: any) => {
  const params = new URLSearchParams();
  for (const [key, value] of formData) {
    params.append(key, value as string);
  }
  return params.toString();
};

export default function Edit(args: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  let [resp, setResp] = useState<{ [key: string]: any }>();
  let [trainings, setTrainings] = useState<{ [key: string]: any }>();
  let [trainingID, setTrainingID] = useState<number>();
  let [alert, setAlert] = useState<[string, string]>(["", ""]);

  useEffect(() => {
    (async () => {
      try {
        setResps(await (await fetch(`/api/${table}/${id}?in_menu=1`)).json());
      } catch (error) {
        setResp({});
      }
    })();
  }, [id]);

  useEffect(() => {
    intervalID = setTimeout(() => {
      setAlert(["", ""]);
    }, 5000);
  }, [alert]);

  const setResps = (resp: { [key: string]: any }) => {
    const _trainings: { [key: string]: any } = {};
    resp.trainings.forEach((training: { [key: string]: any }) => {
      _trainings[training.id] = {};
      training.TrainingParts.forEach(
        (trainingParts: { [key: string]: any }) => {
          _trainings[training.id][trainingParts.part_id] = trainingParts.impact;
        }
      );
    });
    setResp(resp);
    setTrainings(_trainings);
  };

  if (resp === undefined) return <Loading />;
  if (Object.keys(resp).length === 0) return <NotFound />;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = qs.parse(formDataToQueryString(formData));

    if (trainingID) {
      for (let training of (resp || {}).trainings) {
        if (training.id !== trainingID) continue;

        if (
          !window.confirm(
            `「${training.name}」は追加されていませんが、保存してもよろしいでしょうか？`
          )
        ) {
          return;
        }
      }
    }

    if ((data.training_ids || []).length === 0) {
      window.alert("トレーニングは最低でも1つは必要です");
      return;
    }

    setResp(undefined);

    const _resp = await (
      await fetch(`/api/${table}/${id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();

    if (id !== `${_resp.row.id}`) {
      navigate(`/${table}/${_resp.row.id}/edit`, {
        // state: { defaultAlert: ["success", "登録しました"] },
      });
      setMessage("success", "作成しました");
    } else {
      setMessage("success", "更新しました");
    }
    setResps(_resp);
  };

  const setMessage = (type: string, message: string) => {
    clearTimeout(intervalID as NodeJS.Timeout);
    setAlert([type, message]);
  };

  const columns: { [key: string]: string } = {
    name: "名前",
  };

  const exists: number[] = [];
  let _trainingParts: { [key: string]: { [key: string]: number } } = {};
  resp.row.MenuTrainings.forEach((menuTrainings: { [key: string]: any }) => {
    exists.push(menuTrainings.Training.id);
    const training = (trainings || {})[menuTrainings.Training.id];
    Object.keys(training).forEach((part_id: string) => {
      _trainingParts[part_id] ||= {
        part_id: parseInt(part_id),
        impact: 0,
      };
      _trainingParts[part_id].impact += training[part_id];
    });
  });

  const max = 4;
  let trainingParts: { [key: string]: number }[] = Object.values(
    _trainingParts
  ).map((trainingPart: { [key: string]: number }) => {
    if (trainingPart.impact > max) {
      trainingPart.impact = max;
    }
    return trainingPart;
  });

  const options = resp.trainings.map((training: any) => {
    return {
      value: training.id,
      disp: training.name,
      sub: training.User.name,
      src: ThumbPath(training.User),
    };
  });

  const disable_values = resp.row.MenuTrainings.map(
    (menuTraining: { [key: string]: any }) => {
      return menuTraining.Training.id;
    }
  );

  return (
    <main>
      <Alert setAlert={setAlert} type={alert[0]} message={alert[1]} />
      <div className="container">
        <ShowHeader title="メニュー" to="/menus" />
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-sm-6 mb-4">
              {Object.keys(columns).map((col) => (
                <div key={col} className="mb-3">
                  <label htmlFor={`label-${col}`} className="form-label">
                    {columns[col]}
                  </label>
                  <input
                    id={`label-${col}`}
                    name={col}
                    className="form-control"
                    defaultValue={resp!.row[col]}
                    required
                  />
                </div>
              ))}
              <label htmlFor="exampleFormControlInput1" className="form-label">
                トレーニング
              </label>
              {resp.row.MenuTrainings.map(
                (menuTraining: { [key: string]: any }, i: number) => (
                  <div key={i} className="input-group custom-select mb-2">
                    <div className="form-control form-control-lg">
                      <CustomOption
                        disp={menuTraining.Training.name}
                        sub={menuTraining.Training.User.name}
                        src={ThumbPath(menuTraining.Training.User)}
                      />
                      <input
                        name="training_ids[]"
                        type="hidden"
                        defaultValue={menuTraining.Training.id}
                      />
                    </div>
                    <a
                      href="/"
                      style={{ textDecoration: "none" }}
                      className="input-group-text text-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        const _resp = JSON.parse(JSON.stringify(resp));
                        _resp.row.MenuTrainings =
                          _resp.row.MenuTrainings.filter(
                            (n: { [key: string]: any }) =>
                              n.training_id !== menuTraining.Training.id
                          );
                        setResp(_resp);
                      }}
                    >
                      <i className="fa-regular fa-trash-can" />
                    </a>
                  </div>
                )
              )}
              <div id="add-input" className="input-group custom-select">
                <CustomSelect
                  value={trainingID}
                  options={options}
                  onClick={(value: any) => {
                    setTrainingID(parseInt(value) || undefined);
                  }}
                  disable_values={disable_values}
                />
                <a
                  href="/"
                  style={{ textDecoration: "none" }}
                  className={`input-group-text ${
                    trainingID ? "text-primary" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();

                    let training;
                    for (let _training of resp!.trainings) {
                      if (_training.id !== trainingID) continue;
                      training = _training;
                      break;
                    }
                    if (!training) return;

                    const _resp = JSON.parse(JSON.stringify(resp));
                    _resp.row.MenuTrainings.push({
                      training_id: training.id,
                      Training: {
                        id: training.id,
                        name: training.name,
                        User: {
                          id: training.User.id,
                          name: training.User.name,
                          thumb: training.User.thumb,
                        },
                      },
                    });
                    setTrainingID(undefined);
                    setResp(_resp);
                  }}
                >
                  <i
                    className={`fa-solid fa-plus ${
                      trainingID ? "" : "invisible"
                    }`}
                  />
                </a>
              </div>
              <div className="form-text text-end">
                <Link to="/trainings/0/edit">新しいトレーニングを追加する</Link>
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <Body
                key={resp.row.MenuTrainings.length}
                trainings={trainingParts}
              />
            </div>
          </div>
          <div className="text-center pt-3 border-top">
            <button type="submit" className="btn btn-success">
              保存する
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}
