export default function Input(args: { [key: string]: any }) {
  const options: { [key: string]: any } = {};
  Object.keys(args).forEach((key: string) => {
    if (["label", "errors", "prefix", "suffix", "help"].includes(key)) return;
    options[key] = args[key];
  });
  const error = (args.errors || {})[args.name];
  const isInit = Object.keys(args.errors || {}).length === 0;
  return (
    <label className="d-block">
      <div className="form-label">{args.label}</div>
      <div className="input-group has-validation">
        {args.prefix && <span className="input-group-text">{args.prefix}</span>}
        <input
          className={`form-control ${
            error ? "is-invalid" : isInit ? "" : "is-valid"
          }`}
          {...options}
        />
        {args.suffix && <span className="input-group-text">{args.suffix}</span>}
        {error ? (
          <div className="invalid-feedback ms-5">{error}</div>
        ) : (
          <div className="valid-feedback ms-5">OK!</div>
        )}
      </div>
      {args.help && <div className="form-text">{args.help}</div>}
    </label>
  );
}

export function Name(args: { [key: string]: any }) {
  const pass: { [key: string]: any } = {};
  Object.keys(args).forEach((key: string) => {
    pass[key] = args[key];
  });
  pass.label ||= "ユーザ名";
  pass.prefix ||= <i className="fa-solid fa-user fa-fw" />;
  pass.type ||= "text";
  pass.name ||= "name";
  pass.minLength ||= "4";
  pass.maxLength ||= "14";
  pass.placeholder ||= "英数字のみ4〜14文字";
  if (pass.required === undefined) pass.required = true;

  return <Input {...pass} />;
}

export function Email(args: { [key: string]: any }) {
  const pass: { [key: string]: any } = {};
  Object.keys(args).forEach((key: string) => {
    pass[key] = args[key];
  });
  pass.label ||= "メールアドレス";
  pass.prefix ||= <i className="fa-solid fa-envelope fa-fw" />;
  pass.type ||= "email";
  pass.name ||= "unconfirmed_email";
  pass.autoComplete ||= "";
  if (pass.required === undefined) pass.required = true;

  return <Input {...pass} />;
}

export function Password(args: { [key: string]: any }) {
  const pass: { [key: string]: any } = {};
  Object.keys(args).forEach((key: string) => {
    pass[key] = args[key];
  });
  pass.label ||= "パスワード";
  pass.prefix ||= <i className="fa-solid fa-key fa-fw" />;
  pass.type ||= "password";
  pass.name ||= "password";
  pass.minLength ||= "8";
  pass.maxLength ||= "40";
  // pass.pattern ||= "^(?=.*[a-zA-Z])(?=.*d)[A-Za-zd@$!%*?&]{8,40}$";
  // pass.placeholder ||= "大、小英字、数字、記号(@$!%*?&)を含む、8以上40文字以下";
  pass.placeholder ||= "英字、数字を含む、8以上40文字以下";
  pass.autoComplete ||= "";

  if (pass.required === undefined) pass.required = true;

  return <Input {...pass} />;
}
