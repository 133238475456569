import { useEffect, useState } from "react";
import Loading from "../../concern/Loading";
import Alert from "../../concern/Alert";
import Body from "../../concern/Body";
import { useNavigate, useParams } from "react-router-dom";
import ShowHeader from "../../concern/ShowHeader";

let intervalID: NodeJS.Timeout | null = null;
const table = "trainings";

export default function Edit() {
  const navigate = useNavigate();
  const { id } = useParams();
  let [row, setRow] = useState<{ [key: string]: any }>();
  let [alert, setAlert] = useState<[string, string]>(["", ""]);

  useEffect(() => {
    (async () => {
      try {
        const resp = await (document as any).fetch(`${table}/${id}`);
        setRow(resp.row);
      } catch (error) {
        setRow({});
      }
    })();
  }, [id]);

  useEffect(() => {
    intervalID = setTimeout(() => {
      setAlert(["", ""]);
    }, 5000);
  }, [alert]);

  if (row === undefined) return <Loading />;

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setRow(undefined);

    const resp = await (document as any).fetch(`${table}/${id}`, {
      method: "POST",
      target: e.target,
    });

    if (id !== `${resp.row.id}`) {
      navigate(`/${table}/${resp.row.id}/edit`, {
        // state: { defaultAlert: ["success", "登録しました"] },
      });
      setMessage("success", "作成しました");
    } else {
      setMessage("success", "更新しました");
    }
    setRow(Object.assign(resp.row, { isEdit: true }));
  };

  const setMessage = (type: string, message: string) => {
    clearTimeout(intervalID as NodeJS.Timeout);
    setAlert([type, message]);
  };

  const columns: { [key: string]: string } = {
    name: "名前",
  };

  return (
    <main>
      <Alert setAlert={setAlert} type={alert[0]} message={alert[1]} />
      <div className="container">
        <ShowHeader title="トレーニング" to="/trainings" />
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-sm-6">
              {Object.keys(columns).map((col) => (
                <div key={col} className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    {columns[col]}
                  </label>
                  <input
                    name={col}
                    className="form-control"
                    defaultValue={row![col]}
                    required
                  />
                </div>
              ))}
            </div>
            <div className="col-sm-6 mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                部位
              </label>
              <Body trainings={row.TrainingParts} isEdit={true} />
            </div>
          </div>
          <div className="text-center pt-3 border-top">
            <button type="submit" className="btn btn-success">
              保存する
            </button>
          </div>
        </form>
      </div>
    </main>
  );
}
