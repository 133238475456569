import { useEffect, useState } from "react";
import Loading from "../../concern/Loading";
import Input, { Name } from "../../concern/Input";
import React from "react";

export default function NameEdit() {
  const [row, setRow] = useState<{ [key: string]: any }>();
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: any }>();
  const defaultMessage = "まずはあなたのユーザ名を決めて下さい";

  useEffect(() => {
    (async () => {
      const resp = await (document as any).fetch("profile");
      setRow(resp.row);
    })();
  }, []);

  if (row === undefined) return <Loading />;

  const submit = async (e: any, page: string) => {
    e.preventDefault();

    const resp = await (document as any).fetch(`profile/${page}`, {
      method: "POST",
      target: e.target,
    });

    if (!resp.errors) {
      setErrors(undefined);
      setMessage("登録しました\n筋トレを楽しんでください！");
    } else {
      setErrors(resp.errors);
    }
  };

  return (
    <main>
      <div className="container">
        <div className="mb-3">
          {(message || defaultMessage)
            .split("\n")
            .map((txt: string, j: number) => (
              <React.Fragment key={j}>
                {txt}
                <br />
              </React.Fragment>
            ))}
        </div>
        {message ? (
          <a href="/" type="submit" className="btn btn-success">
            トップページへ
          </a>
        ) : (
          <form onSubmit={(e) => submit(e, "basic")}>
            <Name defaultValue={row!.name} errors={errors} />
            <div className="my-3">
              <Input
                label="インターバル(秒)"
                prefix={<i className="fa-regular fa-clock fa-fw" />}
                name="interval_second"
                type="number"
                min={1}
                defaultValue={row!.interval_second}
                errors={errors}
                autoComplete=""
                required={false}
              />
            </div>
            <Input
              label="セット数"
              prefix={<i className="fa-solid fa-layer-group fa-fw" />}
              name="sets"
              type="number"
              min={1}
              defaultValue={row!.sets}
              errors={errors}
              autoComplete=""
              required
            />
            <div className="text-center mt-3">
              <button type="submit" className="btn btn-success">
                保存する
              </button>
            </div>
          </form>
        )}
      </div>
    </main>
  );
}
