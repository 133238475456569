import { useEffect, useState } from "react";
import TABLE from "./TABLE";
import { Link } from "react-router-dom";
import Loading from "../../concern/Loading";

export default function Main() {
  let [resp, setResp] = useState<{ [key: string]: any }>();

  useEffect(() => {
    (async () => {
      let resp = await (
        await fetch(`/api/follows${document.location.search}`)
      ).json();

      setResp(resp);
    })();
  }, []);

  if (resp === undefined) return <Loading />;

  const types: { [key: string]: string } = {
    officials: "おすすめ",
    followers: "フォロー中",
    followees: "フォロワー",
  };

  return (
    <div className="container pt-4 pb-5">
      <div className="text-end">
        <Link to="/follows/search">
          もっと探す
          <i className="fa-solid fa-magnifying-glass ms-1" />
        </Link>
      </div>
      <TABLE resp={resp} types={types} />
    </div>
  );
}
