import "./App.sass";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import cookie from "react-cookies";
import qs from "qs";
import { useEffect, useState } from "react";

import Header from "./concern/Header";
import Footer from "./concern/Footer";
import Loading from "./concern/Loading";
import NotFound from "./concern/NotFound";
import GuestHome from "./guest/Main";
import Privacy from "./guest/Privacy";
import Terms from "./guest/Terms";
import Login from "./guest/Login/Main";
import MemberHome from "./member/Main";
import ExecIndex from "./guest/Exec/Index";
import ExecCounter from "./guest/Exec/Counter";
import ExecShow from "./guest/Exec/Show";
import Result from "./member/Result/Index";
import Progress from "./member/Result/Progress";
import Recovery from "./member/Result/Recovery";
import Follow from "./member/Follow/Index";
import Follows from "./member/Follow/Show";
import Name from "./member/Profile/Name";
import Profile from "./member/Profile/Edit";
import Gym from "./member/Gym/Index";
import GymUsers from "./member/Gym/Users";
import TrainingIndex from "./guest/Training/Index";
import TrainingEdit from "./guest/Training/Edit";
import MenuIndex from "./guest/Menu/Index";
import MenuEdit from "./guest/Menu/Edit";
import LoginPage from "./concern/LoginPage";
import Signup from "./guest/Signup";
import Confirm from "./guest/Confirm";
import Forgot from "./guest/Forgot";
import Reset from "./guest/Reset";
import How2Use from "./guest/How2Use";
import moment from "moment";

moment.locale("ja", {
  weekdays: [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
  ],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"],
});

const formDataToQueryString = (formData: any) => {
  const params = new URLSearchParams();
  for (const [key, value] of formData) {
    params.append(key, value as string);
  }
  return params.toString();
};

(document as any).loading = () => {
  (document as any).querySelector("body").classList.remove("loaded");
};

(document as any).loaded = () => {
  (document as any).querySelector("body").classList.add("loaded");
};

(document as any).fetch = async (path: string, args: any): Promise<any> => {
  (document as any).loading();

  args ||= {};
  if (args.target) {
    const formData = new FormData(args.target);
    const data = qs.parse(formDataToQueryString(formData));
    args.body = JSON.stringify(data);
    delete args.target;
  }

  args.headers ||= {};
  args.headers["Content-Type"] ||= "application/json";
  const resp = await (await fetch(`/api/${path}`, args)).json();

  (document as any).loaded();
  return resp;
};

export default function App() {
  const [result, setResult] = useState<{ [key: string]: any }>();

  useEffect(() => {
    const fetchFunction = async () => {
      cookie.save("origin", window.location.origin, { path: "/" });

      const user = await (document as any).fetch("login");
      user.id ??= 0;
      (document as any).user = user;
      (document as any).loaded();
      setResult(user);
    };
    fetchFunction();
  }, []);

  return (
    <BrowserRouter>
      <Header {...result} />
      {result === undefined ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/login/:provider" element={<Login />} />
          <Route path="/training" element={<ExecIndex />} />
          <Route path="/training/0" element={<ExecCounter />} />
          <Route path="/training/:id" element={<ExecShow />} />
          <Route path="/menus" element={<MenuIndex />} />
          <Route path="/trainings" element={<TrainingIndex />} />
          <Route path="/how2use" element={<How2Use />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          {((result || {}).id || 0) === 0 ? (
            <>
              <Route path="/" element={<GuestHome />} />
              <Route path="/results" element={<GuestHome />} />
              <Route path="/results/:date" element={<GuestHome />} />
              <Route path="/follows" element={<GuestHome />} />
              <Route path="/follows/:type" element={<GuestHome />} />
              <Route path="/profile" element={<GuestHome />} />
              <Route path="/menus/:id/edit" element={<GuestHome />} />
              <Route path="/trainings/:id/edit" element={<GuestHome />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/confirm/:token" element={<Confirm />} />
              <Route path="/reset/:token" element={<Reset />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<LoginPage />} />
            </>
          ) : (result || {}).name ? (
            <>
              <Route path="/" element={<MemberHome />} />
              <Route path="/results" element={<Result />} />
              <Route path="/results/:date" element={<Result />} />
              <Route path="/progress" element={<Progress />} />
              <Route path="/progress/:date" element={<Progress />} />
              <Route path="/recoveries" element={<Recovery />} />
              <Route path="/recoveries/:date" element={<Recovery />} />
              <Route path="/follows" element={<Follow />} />
              <Route path="/follows/:type" element={<Follows />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/menus/:id/edit" element={<MenuEdit />} />
              <Route path="/trainings/:id/edit" element={<TrainingEdit />} />
              <Route path="/gyms" element={<Gym />} />
              <Route path="/gyms/:id/users" element={<GymUsers />} />
              <Route path="/confirm/:token" element={<Confirm />} />
              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <>
              <Route path="*" element={<Name />} />
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
      <Footer {...result} />
    </BrowserRouter>
  );
}
