import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Input from "../concern/Input";
import Forgot from "../concern/Forgot";

export default function Signup() {
  let { token } = useParams();
  let [errors1, setErrors1] = useState<{ [key: string]: any }>();
  let [errors2, setErrors2] = useState<{ [key: string]: any }>();
  let [success, setSuccess] = useState<{ [key: string]: any }>();

  useEffect(() => {
    (async () => {
      let resp = await (
        await fetch(`/api/signup/reset/${token}`, { method: "POST" })
      ).json();
      setErrors1(resp.errors);
      setSuccess(resp.success);
    })();
  }, [token]);

  return (
    <div id="signup">
      <div className="login">
        <h1>パスワードリセット</h1>
        {errors1 === undefined ? (
          <div className="text-center py-4">
            <i className="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse me-2" />
            準備中...
          </div>
        ) : (success || {}).password ? (
          <>
            <form className="text-center py-4">{success!.password}</form>
            <div className="signup text-center p-3">
              <Link to="/login">ログインはこちら</Link>
            </div>
          </>
        ) : !(errors1 || {}).token ? (
          <>
            <form
              className="py-4 px-3"
              onSubmit={async (e: any) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const data = Object.fromEntries(formData.entries());

                const resp = await (
                  await fetch("/api/signup/reset", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                ).json();

                if (!resp.errors) {
                  setErrors2(undefined);
                  setSuccess(resp.success);
                } else {
                  setSuccess(undefined);
                  setErrors2(resp.errors);
                }
              }}
            >
              <Input
                label="パスワード"
                prefix={<i className="fa-solid fa-key fa-fw" />}
                type="password"
                name="password"
                errors={errors2}
                placeholder="大、小英字、数字、記号(@$!%*?&)を含む、8以上40文字以下"
                autoComplete=""
                required
              />
              <input type="hidden" name="token" value={token} />
              <div className="d-grid mt-2">
                <button className="btn btn-lg btn-primary">
                  パスワードを変更する
                </button>
              </div>
            </form>
            <div className="signup text-center p-3">
              <Link to="/login">ログインはこちら</Link>
            </div>
          </>
        ) : (
          <>
            <div className="text-danger text-center my-3">{errors1.token}</div>
            <Forgot />
            <div className="signup text-center p-3">
              <Link to="/login">ログインはこちら</Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
