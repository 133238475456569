import React from "react";
import Browser from "../concern/Browser";
import Login from "../concern/Login";

export default function Main() {
  const resp: { [key: string]: any } = (document as any).user || {};
  const rows: { [key: string]: any }[] = [
    {
      img: <img src="/imgs/super_recovery.jpg" alt="超回復" />,
      txt: "超回復状況の状況がひと目でわかります。\n本当に。",
    },
    // {
    //   img: <img src="/imgs/super_recovery.jpg" alt="超回復" />,
    //   txt: "超回復状況の状況がひと目でわかります。\n本当に。",
    // },
    // {
    //   img: <img src="/imgs/super_recovery.jpg" alt="超回復" />,
    //   txt: "超回復状況の状況がひと目でわかります。\n本当に。",
    // },
  ];
  return (
    <main className="pt-0">
      <div className="main mb-5">
        <Login {...resp} />
      </div>
      <div className="container text-center pt-5">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-sm lead mb-2">
            ノートとペンなしで
            <br />
            このアプリで記録と休憩時間を管理
          </div>
          <div className="col-sm mb-5">
            <div className="row">
              <div className="col-6">
                <img src="/imgs/training1.jpg" alt="トレーニング" />
              </div>
              <div className="col-6">
                <img src="/imgs/training2.jpg" alt="トレーニング" />
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-5" />
        <div className="row align-items-center">
          <div className="col-sm lead mb-2">
            Apple Watchのアプリを使用すれば
            <br />
            Apple Watchだけでトレーニングが可能!!
            <br />
            ダウンロードは
            <a
              className="mx-1"
              target="__blank"
              href="https://apps.apple.com/jp/app/pedrip%E7%AD%8B%E8%82%89%E3%82%A2%E3%83%97%E3%83%AA/id6547843923"
            >
              こちら
            </a>
            から
            <br />
            <br />
            <i className="text-danger">※Apple IDで連携する必要があります</i>
          </div>
          <div className="col-sm mb-5">
            <img src="/imgs/apple_watch.jpg" alt="Apple Watch App" />
          </div>
        </div>
        {rows.map((row, i) => (
          <React.Fragment key={i}>
            <hr className="mb-5" />
            <div
              className={`row align-items-center${
                i % 2 !== 1 ? " flex-row-reverse" : ""
              }`}
              key={i}
            >
              <div className="col-sm lead mb-2">
                {row.txt.split("\n").map((txt: string, j: number) => (
                  <React.Fragment key={j}>
                    {txt}
                    <br />
                  </React.Fragment>
                ))}
              </div>
              <div className="col-sm mb-5">
                <Browser>{row.img}</Browser>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </main>
  );
}
