import { useEffect, useState } from "react";
import Loading from "../../concern/Loading";
import Pagination from "../../concern/Pagination";
import { Link } from "react-router-dom";
import Empty from "../../concern/Empty";
import ThumbPath from "../../concern/ThumbPath";

export default function Index({ table = "menus" }) {
  let [rows, setRows] = useState<{ [key: string]: any }[]>();
  let [order, setOrder] = useState<string>("");
  let [sort, setSort] = useState<string>("");
  let [page, setPage] = useState<number>(1);
  let [count, setCount] = useState<number>(0);
  let [searches, setSearches] = useState<{ [key: string]: string }>({});

  const fetchFunction = async (
    order: string,
    sort: string,
    page: number,
    searches: { [key: string]: string }
  ) => {
    let resp = await (
      await fetch(
        `/api/${table}/with_result?order=${order}&sort=${sort}&page=${page}&searches=${JSON.stringify(
          searches
        )}`
      )
    ).json();

    setRows(resp.rows);
    setOrder(resp.order);
    setSort(resp.sort);
    setPage(resp.page);
    setCount(resp.count);
  };

  useEffect(() => {
    fetchFunction(order, sort, page, searches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (rows === undefined) return <Loading />;

  const columns: { [key: string]: string } = {
    name: "名前",
  };

  return (
    <main className="container">
      <div className="row align-items-center">
        <div className="col-sm-6 mb-3">
          <h2 className="header mb-0">トレーニングする</h2>
        </div>
        <div className="col-sm-6 mb-3">
          <form
            className="input-group input-group-sm"
            onSubmit={(e) => {
              e.preventDefault();
              fetchFunction(order, sort, page, searches);
            }}
          >
            <label htmlFor="form-name" className="input-group-text">
              名前
            </label>
            <input
              id="form-name"
              type="text"
              name="name"
              className="form-control"
              onChange={(e) => {
                setSearches(
                  Object.assign(searches, {
                    name: e.target.value.replace(/%/g, "%25"),
                  })
                );
              }}
            />
            <div className="input-group-text">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  fetchFunction(order, sort, page, searches);
                }}
              >
                <i className="fa-solid fa-magnifying-glass" />
              </a>
            </div>
          </form>
        </div>
      </div>
      {rows.length === 0 ? (
        <Empty />
      ) : (
        <>
          <table id="food-list" className="table">
            <thead>
              <tr>
                {Object.keys(columns).map((col) => (
                  <th scope="col" key={col}>
                    {col.match(/^_.+/) ? (
                      <span>{columns[col]}</span>
                    ) : (
                      <a
                        href="/"
                        className="d-block"
                        onClick={(e) => {
                          e.preventDefault();
                          fetchFunction(
                            col,
                            order === col && sort === "asc" ? "desc" : "asc",
                            page,
                            searches
                          );
                        }}
                      >
                        <span>{columns[col]}</span>
                        <span className="fa-stack">
                          <i
                            className={`fa-solid fa-sort-up fa-stack-1x opacity-${
                              order === col && sort === "asc" ? 100 : 25
                            }`}
                          />
                          <i
                            className={`fa-solid fa-sort-down fa-stack-1x opacity-${
                              order === col && sort === "desc" ? 100 : 25
                            }`}
                          />
                        </span>
                      </a>
                    )}
                  </th>
                ))}
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="d-flex">
                    <div className="icon-img" style={{ visibility: "hidden" }}>
                      <div className="me-2" />
                    </div>
                    <div>測定のみ</div>
                  </div>
                </td>
                <td className="text-end">
                  <Link
                    to="/training/0"
                    className="fa-regular fa-clock fa-fw"
                  />
                </td>
              </tr>
              {rows.map((row) => {
                return (
                  <tr key={row.id}>
                    <td>
                      <div className="d-flex">
                        <div className="icon-img">
                          <div
                            className="me-2"
                            style={{
                              backgroundImage: `url("${ThumbPath(row.User)}")`,
                            }}
                          />
                          {row.User.official && (
                            <span className="fa-stack">
                              <i className="fa-solid fa-certificate fa-stack-2x text-primary" />
                              <i className="fa-solid fa-check fa-stack-1x text-white" />
                            </span>
                          )}
                        </div>
                        <div>{row.name || "-"}</div>
                      </div>
                    </td>
                    <td className="text-end">
                      <Link
                        to={`/training/${row.id}`}
                        className="fa-solid fa-dumbbell fa-fw"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            count={count}
            page={page}
            onClick={(i: number) => {
              fetchFunction(order, sort, i, searches);
            }}
          />
        </>
      )}
    </main>
  );
}
