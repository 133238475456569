import { Link } from "react-router-dom";
import Forgot from "../concern/Forgot";

export default function Forget() {
  return (
    <div id="signup">
      <div className="login">
        <h1>パスワードを忘れた</h1>
        <Forgot />
        <div className="signup text-center p-3">
          <Link to="/login">ログインはこちら</Link>
        </div>
      </div>
    </div>
  );
}
