import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Input from "../concern/Input";

export default function Signup() {
  let { token } = useParams();
  let [errors1, setErrors1] = useState<{ [key: string]: any }>();
  let [errors2, setErrors2] = useState<{ [key: string]: any }>();
  let [success, setSuccess] = useState<{ [key: string]: any }>();

  useEffect(() => {
    (async () => {
      let resp = await (
        await fetch(`/api/signup/confirm/${token}`, { method: "POST" })
      ).json();
      setErrors1(resp.errors);
      setSuccess(resp.success);
    })();
  }, [token]);

  return (
    <div id="signup">
      <div className="login">
        <h1>メールアドレスの確認</h1>
        {errors1 === undefined ? (
          <div className="text-center py-4">
            <i className="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse me-2" />
            確認中...
          </div>
        ) : (success || {}).token ? (
          <>
            <form className="text-center py-4">{success!.token}</form>
            <div className="signup text-center p-3">
              {(document as any).user.id === 0 ? (
                <Link to="/login">ログインはこちら</Link>
              ) : (
                <Link to="/">マイページへ</Link>
              )}
            </div>
          </>
        ) : (
          <>
            {(success || {}).email ? (
              <div className="text-success text-center my-3">
                {success!.email.split("\n").map((txt: string, j: number) => (
                  <React.Fragment key={j}>
                    {txt}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div className="text-danger text-center my-3">
                {errors1.token}
              </div>
            )}
            <form
              className="py-4 px-3"
              onSubmit={async (e: any) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const data = Object.fromEntries(formData.entries());

                const resp = await (
                  await fetch("/api/signup/send", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                ).json();

                if (!resp.errors) {
                  setErrors2(undefined);
                  setSuccess({
                    email:
                      "メールアドレスにメッセージを送信しました。\nメッセージに従って登録を完了してください。",
                  });
                  // (window as any).location.reload();
                } else {
                  setSuccess(undefined);
                  setErrors2(resp.errors);
                }
              }}
            >
              <Input
                label="メールアドレス"
                prefix={<i className="fa-solid fa-envelope fa-fw" />}
                type="email"
                name="unconfirmed_email"
                errors={errors2}
                autoComplete=""
                required
              />
              <div className="d-grid mt-2">
                <button className="btn btn-lg btn-primary">
                  認証メールを送信する
                </button>
              </div>
            </form>
            <div className="signup text-center p-3">
              {(document as any).user.id === 0 ? (
                <Link to="/login">ログインはこちら</Link>
              ) : (
                <Link to="/">マイページへ</Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
