import { useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import ThumbPath from "../../concern/ThumbPath";

export default function Main(args: { [key: string]: any }) {
  const resp = args.resp;
  const types = args.types;
  let [targets, setTargets] = useState<number[]>([]);

  const updateFollow = async (e: any, id: number) => {
    setTargets(targets.concat([id]));
    const _resp = await (
      await fetch(`/api/follows/${id}/${e.target.checked ? 1 : 0}`, {
        method: "POST",
      })
    ).json();

    Object.keys(resp!).forEach((type) => {
      if (!Array.isArray(resp![type])) return;
      resp![type].forEach((row: { [key: string]: any }, i: number) => {
        if (row.id === id) {
          resp![type][i].Follower = _resp.status === "1" ? [true] : [];
        }
      });
    });
    setTargets(targets.filter((i) => i !== id));
  };

  return (
    <table
      className="table"
      style={{
        // width: Object.keys(types).length === 1 ? "100%" : "auto",
        width: "auto",
        margin: "0 auto",
      }}
    >
      {Object.keys(types).map((type) => (
        <React.Fragment key={type}>
          <thead>
            <tr>
              <th colSpan={3}>
                <h3 className="text-center">{types[type]}</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {resp![type].length === 0 ? (
              <tr>
                <td colSpan={3} className="text-center">
                  見つかりません
                </td>
              </tr>
            ) : (
              <>
                {resp![type].map((row: { [key: string]: any }) => (
                  <tr key={row.id}>
                    <td style={{ width: "1px" }}>
                      <div className="icon-img">
                        <div
                          style={{
                            backgroundImage: `url("${ThumbPath(row)}")`,
                          }}
                        />
                        {row.official && (
                          <span className="fa-stack">
                            <i className="fa-solid fa-certificate fa-stack-2x text-primary" />
                            <i className="fa-solid fa-check fa-stack-1x text-white" />
                          </span>
                        )}
                      </div>
                    </td>
                    <td style={{ minWidth: "150px" }}>
                      <Link to={`/follows/${row.name}`}>{row.name}</Link>
                    </td>
                    <td className="text-end">
                      {targets.includes(row.id) ? (
                        <div
                          className="btn btn-outline-secondary btn-sm text-center"
                          style={{ width: "120px" }}
                        >
                          <i className="fa-solid fa-spinner fa-spin-pulse" />
                        </div>
                      ) : (
                        <label className="check">
                          <input
                            type="checkbox"
                            onChange={(e) => updateFollow(e, row.id)}
                            defaultChecked={row.Follower.length !== 0}
                          />
                          <div
                            className="btn btn-danger btn-sm"
                            style={{ width: "120px" }}
                          >
                            フォロー解除
                          </div>
                          <div
                            className="btn btn-primary btn-sm"
                            style={{ width: "120px" }}
                          >
                            フォローする
                          </div>
                        </label>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
            {types[type] && (
              <tr>
                <td colSpan={3} className="border-bottom-0">
                  <div className="text-center mb-4 py-2">
                    <Link to={`/follows/${type}`}>もっと見る</Link>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </React.Fragment>
      ))}
    </table>
  );
}
