import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

let intervalID: NodeJS.Timeout | null = null;

export default function Edit() {
  let [second, setSecond] = useState<number>(0);
  let [isActive, setIsActive] = useState<boolean>(true);
  let [sets, setSets] = useState<number>();
  let [interval, setInterval] = useState<number>();
  let [count, setCount] = useState<number>(0);

  if (interval === undefined) {
    const user = (document as any).user || {};
    sets = user.sets;
    interval = user.interval_second;
    sets ||= 3;
    interval ||= 180;
    setSets(sets);
    setInterval(interval);
  }

  useEffect(() => {
    if (second > 0) {
      if (!isActive) return;

      intervalID = setTimeout(() => {
        setSecond((seconds) => seconds - 1);
      }, 1000);
    } else {
      if (sets! <= count) setCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second, isActive]);

  const displayMin = (sec: number) => {
    if (sec < 0) sec = 0;
    return `${Math.floor(sec / 60)}:${("0" + (sec % 60)).slice(-2)}`;
  };

  return (
    <main className="container">
      <div className="row my-3">
        <div className="col-sm-6">
          <h3 className="mb-0">カウンター</h3>
        </div>
        <div className="col-sm-6 text-end">
          <Link to="/training" className="btn btn-outline-secondary">
            やめる
          </Link>
        </div>
      </div>
      <div
        className="text-center"
        style={{ maxWidth: "280px", margin: "0 auto" }}
      >
        <div className="input-group mb-3">
          <label htmlFor="form-interval" className="input-group-text">
            休憩
          </label>
          <input
            id="form-interval"
            type="number"
            className="form-control text-end"
            defaultValue={interval}
            onChange={(e) => {
              setInterval(parseInt(e.target.value || "0"));
            }}
          />
          <label htmlFor="form-interval" className="input-group-text">
            秒
          </label>
        </div>
        <div className="input-group mb-3">
          <label htmlFor="form-sets" className="input-group-text">
            回数
          </label>
          <input
            id="form-count"
            type="number"
            className="form-control text-end"
            key={`count-${count}`}
            defaultValue={count}
            onChange={(e) => {
              setCount(parseInt(e.target.value || "0"));
            }}
          />
          <label htmlFor="form-sets" className="input-group-text">
            /
          </label>
          <input
            id="form-sets"
            type="number"
            className="form-control text-end"
            defaultValue={sets}
            onChange={(e) => {
              setSets(parseInt(e.target.value || "0"));
            }}
          />
          <label htmlFor="form-sets" className="input-group-text">
            回
          </label>
        </div>
        <h4 className="mt-4 mb-0">
          {second <= 0 ? (
            <>
              <i className="fa-solid fa-dumbbell me-2" />
              運動中
            </>
          ) : (
            <>
              <i className="fa-solid fa-bed me-2" />
              休憩中
            </>
          )}
          …
        </h4>
        <div id="timer">{displayMin(second)}</div>
        {second <= 0 ? (
          <div>
            <button
              type="button"
              className="btn btn-warning me-3"
              onClick={(e) => {
                setCount(0);
              }}
              disabled={count === 0}
            >
              リセット
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                setCount((count) => count + 1);
                setSecond(interval!);
              }}
            >
              休憩する
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              className="btn btn-warning me-3"
              onClick={(e) => {
                if (isActive) clearTimeout(intervalID as NodeJS.Timeout);
                setIsActive((isActive) => !isActive);
              }}
            >
              {isActive ? "一時停止" : "再開する"}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={(e) => {
                setSecond(0);
              }}
            >
              休憩完了
            </button>
          </div>
        )}
      </div>
    </main>
  );
}
