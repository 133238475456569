import React, { useEffect, useState } from "react";
import Loading from "../../concern/Loading";
import Body from "../../concern/Body";
import Empty from "../../concern/Empty";
import { Link } from "react-router-dom";
import Pagination from "../../concern/Pagination";

export default function Index({ table = "trainings" }) {
  let [rows, setRows] = useState<{ [key: string]: any }[]>();
  let [order, setOrder] = useState<string>("");
  let [sort, setSort] = useState<string>("");
  let [page, setPage] = useState<number>(1);
  let [count, setCount] = useState<number>(0);
  let [searches, setSearches] = useState<{ [key: string]: string }>({});

  const fetchFunction = async (
    order: string,
    sort: string,
    page: number,
    searches: { [key: string]: string }
  ) => {
    let resp = await (
      await fetch(
        `/api/${table}?order=${order}&sort=${sort}&page=${page}&searches=${JSON.stringify(
          searches
        )}`
      )
    ).json();

    setRows(resp.rows);
    setOrder(resp.order);
    setSort(resp.sort);
    setPage(resp.page);
    setCount(resp.count);
  };

  useEffect(() => {
    fetchFunction(order, sort, page, searches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (rows === undefined) return <Loading />;

  const columns: { [key: string]: string } = {
    name: "名前",
  };

  return (
    <main className="container">
      <div className="row align-items-center">
        <div className="col-sm-6 mb-3">
          <h2 className="header mb-0">トレーニング</h2>
        </div>
        <div className="col-sm-6 mb-3">
          <form
            className="input-group input-group-sm"
            onSubmit={(e) => {
              e.preventDefault();
              fetchFunction(order, sort, page, searches);
            }}
          >
            <label htmlFor="form-name" className="input-group-text">
              名前
            </label>
            <input
              id="form-name"
              type="text"
              name="name"
              className="form-control"
              onChange={(e) => {
                setSearches(
                  Object.assign(searches, {
                    name: e.target.value.replace(/%/g, "%25"),
                  })
                );
              }}
            />
            <div className="input-group-text">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  fetchFunction(order, sort, page, searches);
                }}
              >
                <i className="fa-solid fa-magnifying-glass" />
              </a>
            </div>
          </form>
        </div>
      </div>
      {rows.length === 0 ? (
        <Empty />
      ) : (
        <>
          <table id="food-list" className="table border-top">
            <thead>
              <tr>
                <th />
                {Object.keys(columns).map((col) => (
                  <th scope="col" key={col}>
                    {col.match(/^_.+/) ? (
                      <span>{columns[col]}</span>
                    ) : (
                      <a
                        href="/"
                        className="d-block"
                        onClick={(e) => {
                          e.preventDefault();
                          fetchFunction(
                            col,
                            order === col && sort === "asc" ? "desc" : "asc",
                            page,
                            searches
                          );
                        }}
                      >
                        <span>{columns[col]}</span>
                        <span className="fa-stack">
                          <i
                            className={`fa-solid fa-sort-up fa-stack-1x opacity-${
                              order === col && sort === "asc" ? 100 : 25
                            }`}
                          />
                          <i
                            className={`fa-solid fa-sort-down fa-stack-1x opacity-${
                              order === col && sort === "desc" ? 100 : 25
                            }`}
                          />
                        </span>
                      </a>
                    )}
                  </th>
                ))}
                <th className="text-end">
                  <Link
                    className="btn btn-primary btn-sm text-nowrap"
                    to={`/${table}/0/edit`}
                  >
                    新規
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => {
                return (
                  <tr key={row.id}>
                    <td style={{ width: "200px" }} className="pe-5">
                      <Body trainings={row.TrainingParts} labelHidden={true} />
                    </td>
                    {Object.keys(columns).map((col) => (
                      <td key={col}>
                        {row[col] || "-"}
                        {row.User && (
                          <>
                            <br />
                            {row.User.name}
                          </>
                        )}
                      </td>
                    ))}
                    <td className="text-end">
                      <a
                        href="/"
                        className="text-danger me-3"
                        onClick={async (e) => {
                          e.preventDefault();
                          let resp = await (
                            await fetch(
                              `/api/${table}/${row.id}?order=${order}&sort=${sort}`,
                              {
                                method: "DELETE",
                              }
                            )
                          ).json();

                          setRows(resp.rows);
                          setOrder(resp.order);
                          setSort(resp.sort);
                        }}
                      >
                        <i className="fa-regular fa-trash-can" />
                      </a>
                      <Link
                        to={`/${table}/${row.id}/edit`}
                        className="fa-regular fa-pen-to-square text-success"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            count={count}
            page={page}
            onClick={(i: number) => {
              fetchFunction(order, sort, i, searches);
            }}
          />
        </>
      )}
    </main>
  );
}
