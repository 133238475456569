import BodySVG from "./BodySVG";
import { useState } from "react";

export default function Index({
  trainings,
  isEdit,
  labelHidden,
}: {
  [key: string]: any;
}) {
  const _parts: { [key: string]: number } = {};
  (trainings || []).forEach((training: { [key: string]: any }) => {
    _parts[training.part_id] = training.impact;
  });
  let [parts, setParts] = useState<{ [key: string]: number }>(_parts);

  const click = (id: number) => {
    if (!isEdit) return;

    const _parts = JSON.parse(JSON.stringify(parts));
    _parts[id] ||= 3;
    _parts[id] -= 1;
    if (_parts[id] < 0) _parts[id] = 0;
    setParts(_parts);
  };

  function Group(args: any) {
    return (
      <g
        className={`part${args.id} active${parts[args.id] || 0}`}
        onClick={() => click(args.id)}
      >
        {args.children}
      </g>
    );
  }

  return (
    <>
      <svg
        className={`${isEdit ? "editable" : "readonly"} ${
          labelHidden ? "labelHidden" : ""
        }`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox={labelHidden ? "81 93 427 367" : "27 93 563 367"}
      >
        <BodySVG />
        <g className="imgs">
          <Group id={1}>
            <path d="M155.3,150.5c0,0,0.1,1.9,0.8,4.1c0.4,1.2,3.4,6.6,4.9,8c1.9,1.7,3.5,2.6,4.7,3c0-1,0.1-2.3-0.3-4.2c-0.4-2.4-0.9-4.5-1.6-6.4c-0.8-1.9-1.9-5.7-2.4-8.8c-1.2-0.8-4.2-3.9-4.2-3.9s0.3,3.2-0.5,6.5c-0.1,0.5-0.4,0.8-0.8,1.3L155.3,150.5" />
            <path d="M181.6,150.5c0,0-0.1,1.9-0.8,4.1c-0.4,1.2-3.4,6.6-4.9,8c-1.9,1.7-3.5,2.6-4.7,3c0-1-0.1-2.3,0.3-4.2c0.4-2.4,0.9-4.5,1.6-6.4c0.8-1.9,1.9-5.7,2.4-8.8c1.2-0.8,4.2-3.9,4.2-3.9s-0.3,3.2,0.5,6.5c0.1,0.5,0.4,0.8,0.8,1.3L181.6,150.5" />
            <text transform="matrix(1 0 0 1 34.0872 158.5787)">胸鎖乳突筋</text>
            <line x1="161.3" y1="156.1" x2="85.8" y2="156.1" />
            <circle cx="160.4" cy="156.1" r="0.9" />
          </Group>
          <Group id={2}>
            <path d="M138.8,156.3c1.4-0.3,3.9-0.9,5-1.3c3.6-1.6,9-2.6,11.5-4.5c0,0,0.2,3.4,1.7,5.8c1.5,2.4,2.1,3.7,2.1,3.7s-1.2,0.5-3.8,0.8c-1.4,0.1-3.5-0.1-6.9-1.7c-2-0.9-3.4,0.3-6.3-0.5C140,157.9,138.8,156.3,138.8,156.3z" />
            <path d="M198.2,156.3c-1.4-0.3-3.9-0.9-5-1.3c-3.6-1.6-9-2.6-11.5-4.5c0,0-0.2,3.4-1.7,5.8c-1.5,2.4-2.1,3.7-2.1,3.7s1.2,0.5,3.8,0.8c1.4,0.1,3.5-0.1,6.9-1.7c2-0.9,3.4,0.3,6.3-0.5C196.9,157.9,198.2,156.3,198.2,156.3z" />
            <path d="M417.3,135.8c1.4-2.1,2.1-3.4,2.7-2c1.4,4.5-3.1,20-3.7,25.1c-0.6,5.1,1.7,11.2,2.1,14.1c0.7,4.9,0.7,24.1,0.7,37.4c-4.8-3.1-14.2-15.3-15.1-23.4c-0.6-5.6-6.5-19.3-8-22.4c-1-1.9-5.2-8.4-5.2-8.4c1.4-0.3,3.9-0.9,5-1.3c3.6-1.6,9-2.6,11.5-4.5l0.5-0.4c0.4-0.5,3.9-6.2,5.7-8.6C415.5,139,417.3,135.8,417.3,135.8z" />
            <path d="M423.9,135.8c-1.4-2.1-2.1-3.4-2.7-2c-1.4,4.5,3.1,20,3.7,25.1c0.6,5.1-1.7,11.2-2.1,14.1c-0.7,4.9-0.7,24.1-0.7,37.4c4.8-3.1,14.2-15.3,15.1-23.4c0.6-5.6,6.5-19.3,8-22.4c1-1.9,5.2-8.4,5.2-8.4c-1.4-0.3-3.9-0.9-5-1.3c-3.6-1.6-9-2.6-11.5-4.5l-0.5-0.4c-0.4-0.5-3.9-6.2-5.7-8.6C425.7,139,423.9,135.8,423.9,135.8z" />
            <text transform="matrix(1 0 0 1 280.7117 158.7305)">僧帽筋</text>
            <line x1="187.3" y1="156.1" x2="265.8" y2="156.1" />
            <circle cx="188.2" cy="156.1" r="0.9" />
            <line x1="408.7" y1="156.1" x2="323.2" y2="156.1" />
            <circle cx="407.8" cy="156.1" r="0.9" />
          </Group>
          <Group id={3}>
            <path d="M138.8,156.3c-4.4,0.8-9.5,1.3-13.3,3.9c-6.3,4.3-8.4,23.1-8,26.2c0,0,5.1-0.9,8.6-3.1c4.4-2.7,7.9-5.7,12.4-12.4c1.3-2,3.5-6.9,3.6-9.1c0.1-1.8,0-3.3,0-3.3s-1.4-0.5-1.8-0.8C139.3,157.1,138.8,156.3,138.8,156.3z" />
            <path d="M198.2,156.3c4.4,0.8,9.5,1.3,13.3,3.9c6.3,4.3,8.4,23.1,8,26.2c0,0-5.1-0.9-8.6-3.1c-4.4-2.7-7.9-5.7-12.4-12.4c-1.3-2-3.5-6.9-3.6-9.1c-0.1-1.8,0-3.3,0-3.3s1.4-0.5,1.8-0.8C197.6,157.1,198.2,156.3,198.2,156.3z" />
            <path d="M369.5,186.4c-0.4-3.1,1.7-21.9,8-26.2c3.4-2.4,8-3,12.1-3.7c0.4-0.1,1.7-0.2,2.1-0.3l7.2,11.5c0.6,5.1-0.9,6.5-2.2,8.5c-0.5,0.9-3,3.2-6.2,5.1c-1.2,0.7-3.5,2-7.2,2.9C377.3,185.9,374.8,185.5,369.5,186.4z" />
            <path d="M471.6,186.4c0.4-3.1-1.7-21.9-8-26.2c-3.4-2.4-8-3-12.1-3.7c-0.4-0.1-1.7-0.2-2.1-0.3l-7.2,11.5c-0.6,5.1,0.9,6.5,2.2,8.5c0.5,0.9,3,3.2,6.2,5.1c1.2,0.7,3.5,2,7.2,2.9C463.8,185.9,466.4,185.5,471.6,186.4z" />
            <text transform="matrix(1 0 0 1 280.7117 175.3015)">三角筋</text>
            <line x1="209" y1="172.7" x2="265.8" y2="172.7" />
            <circle cx="209.9" cy="172.7" r="0.9" />
            <line x1="383.1" y1="172.7" x2="323.2" y2="172.7" />
            <circle cx="382.1" cy="172.7" r="0.9" />
          </Group>
          <Group id={4}>
            <path d="M131.9,181.2c0,0,5.2,7.4,6.6,9.2c2.1,2.8,6.1,9.8,12.8,8.5c8.3-1.5,14.2-4.8,14.5-7.7c0.3-2.4,1-5.6,1.1-11.4c0.1-5.8,0.8-10.8-1.3-12s-6.4-2.8-9.2-3.9c-4.5-1.7-7.9-3.6-9-2.6c-1.9,1.7-5.5,4.7-7.4,6.6c-1.6,2.9-3,5-4.6,7c-1.3,1.9-4.5,4.8-4.5,4.8S131.4,180.3,131.9,181.2z" />
            <path d="M205.1,181.2c0,0-5.2,7.4-6.6,9.2c-2.1,2.8-6.1,9.8-12.8,8.5c-8.3-1.5-14.2-4.8-14.5-7.7c-0.3-2.4-1-5.6-1.1-11.4s-0.8-10.8,1.3-12c2.1-1.1,6.4-2.8,9.2-3.9c4.5-1.7,7.9-3.6,9-2.6c1.9,1.7,5.5,4.7,7.4,6.6c1.6,2.9,3,5,4.6,7c1.3,1.9,4.5,4.8,4.5,4.8S205.6,180.3,205.1,181.2z" />
            <text transform="matrix(1 0 0 1 52.5125 178.2973)">大胸筋</text>
            <line x1="152.3" y1="175.7" x2="85.8" y2="175.7" />
            <circle cx="151.4" cy="175.7" r="0.9" />
          </Group>
          <Group id={6}>
            <path d="M128.3,217c2.8-6.1,7.3-17.6,7-23.8c-0.1-2.6,0-6.6-0.2-7.5c-1.8-2.6-4-5.9-4-5.9s-3.4,2.7-4.9,3.5c-2.1,3.7-6.4,13.3-7.4,16.5c-0.9,3.2-3.2,11.7-1,14.9c2.2,3.2,4.1,4.3,6.4,4.2C127,218.9,128.3,217,128.3,217z" />
            <path d="M208.7,217c-2.8-6.1-7.3-17.6-7-23.8c0.1-2.6,0-6.6,0.2-7.5c1.8-2.6,4-5.9,4-5.9s3.4,2.7,4.9,3.5c2.1,3.7,6.4,13.3,7.4,16.5c0.9,3.2,3.2,11.7,1,14.9c-2.2,3.2-4.1,4.3-6.4,4.2C209.9,218.9,208.7,217,208.7,217z" />
            <text transform="matrix(1 0 0 1 34.0872 195.76)">上腕二頭筋</text>
            <line x1="130" y1="193.1" x2="85.8" y2="193.1" />
            <circle cx="129" cy="193.1" r="0.9" />
          </Group>
          <Group id={7}>
            <path d="M363.8,203.8c0,0.8,2.5,12.4,6.4,13.2c1.3,0.3,9.4-0.5,12.4-5c0.2-0.3,0.3-0.7,0.4-1.1c1.5-3.8,3-8.2,3.8-12c0.3-1.3,0.5-2.6,0.6-3.8c0-0.4,0.1-0.8,0-1.2c0-1.7,0.6-7.2,0.6-10.2c0-0.4,0-0.7-0.1-1l-0.7,0.3c-1.1,0.5-2.4,0.9-4,1.3c-5.7,1.5-8.3,1.2-13.1,2l-0.5,0.1c-1.7,6.8-4.6,10.9-5.5,16c-0.1,0.7-0.2,1.5-0.3,2.2" />
            <path d="M477.3,203.8c0,0.8-2.5,12.4-6.4,13.2c-1.3,0.3-9.4-0.5-12.4-5c-0.2-0.3-0.3-0.7-0.4-1.1c-1.5-3.8-3-8.2-3.8-12c-0.3-1.3-0.5-2.6-0.6-3.8c0-0.4-0.1-0.8,0-1.2c0-1.7-0.6-7.2-0.6-10.2c0-0.4,0-0.7,0.1-1l0.7,0.3c1.1,0.5,2.4,0.9,4,1.3c5.7,1.5,8.3,1.2,13.1,2l0.5,0.1c1.7,6.8,4.6,10.9,5.5,16c0.1,0.7,0.2,1.5,0.3,2.2" />
            <text transform="matrix(1 0 0 1 513.1736 205.0782)">
              上腕三頭筋
            </text>
            <line x1="465.6" y1="202.4" x2="508.7" y2="202.4" />
            <circle cx="466.5" cy="202.4" r="0.9" />
          </Group>
          <Group id={8}>
            <path d="M135.9,213.5c0,0-1.3-17.7-1.2-19.3c0.1-1.6,0.6-9.8,0.6-9.8l4,5l0.2,19.2l1.1,7.2l-0.7,8.7l-1.7,3.3c0,0-1.2-6.2-1.5-8.5C136.1,217,136,215.1,135.9,213.5z" />
            <path d="M201.3,213.5c0,0,1.3-17.7,1.2-19.3c-0.1-1.6-0.6-9.8-0.6-9.8l-4,5l-0.2,19.2l-1.1,7.2l0.7,8.7l1.7,3.3c0,0,1.2-6.2,1.5-8.5C201,217,201.2,215.1,201.3,213.5z" />
            <path d="M392.2,244.2c-0.4-3.6-1.8-11.5-1.8-11.5c-1.2-7.8-2.7-17.1-3.3-26.2c-0.2-4.1-0.3-8.2,0-12c0-0.2,0.1-0.9,0.1-1c0-0.1,0-0.2,0-0.3c0-0.4,0.5-5.1,0.5-5.8c0-0.8,0.1-3.8,0-4.7c-0.1-0.9,1.4-2.4,2.4-2.2s1.4,6.8,4.2,8.2c2.8,1.4,4.7,1.2,6.7,0.8c2.3-0.4,3.1-1.6,3.1-1.6s0.3-0.4,0.5,0.5c0.4,1.8,2.4,6.8,4.3,9.9c2.2,3.6,6.2,8.2,9,10.8c0.3,0.3,0.8,1.1,1,1.3c0.1,0.8-0.2,7.1-0.2,7.6s-0.4,6.9-0.8,9.1c-0.4,2.3-1,6.1-2.3,9c-1.3,2.9-4.1,8.7-7.5,10.3c-2,0.9-6.2,2.4-9.7,2.3C395.1,248.8,392.5,246.8,392.2,244.2z" />
            <path d="M448.9,244.2c0.4-3.6,1.8-11.5,1.8-11.5c1.2-7.8,2.7-17.1,3.3-26.2c0.2-4.1,0.3-8.2,0-12c0-0.2-0.1-0.9-0.1-1c0-0.1,0-0.2,0-0.3c0-0.4-0.5-5.1-0.5-5.8s-0.1-3.8,0-4.7c0.1-0.9-1.4-2.4-2.4-2.2s-1.4,6.8-4.2,8.2c-2.8,1.4-4.7,1.2-6.7,0.8c-2.3-0.4-3.1-1.6-3.1-1.6s-0.3-0.4-0.5,0.5c-0.4,1.8-2.4,6.8-4.3,9.9c-2.2,3.6-6.2,8.2-9,10.8c-0.3,0.3-0.8,1.1-1,1.3c-0.1,0.8,0.2,7.1,0.2,7.6s0.4,6.9,0.8,9.1c0.4,2.3,1,6.1,2.3,9c1.3,2.9,4.1,8.7,7.5,10.3c2,0.9,6.2,2.4,9.7,2.3C446,248.8,448.6,246.8,448.9,244.2z" />
            <text transform="matrix(1 0 0 1 280.7117 205.0782)">広背筋</text>
            <line x1="200.3" y1="202.4" x2="265.8" y2="202.4" />
            <circle cx="201.2" cy="202.4" r="0.9" />
            <line x1="398.7" y1="202.4" x2="323.2" y2="202.4" />
            <circle cx="397.8" cy="202.4" r="0.9" />
          </Group>
          <Group id={5}>
            <path d="M398.5,170.1c0,0-0.2,4-2,6.4s-6,4.9-6,4.9s0.5,6.3,3.7,8c3.1,1.7,8.4,1.5,11-1.4c-0.3-4.6-2.4-10.5-3.9-13.7C399.6,171.1,398.5,170.1,398.5,170.1z" />
            <path d="M442.7,170.1c0,0,0.2,4,2,6.4s6,4.9,6,4.9s-0.5,6.3-3.7,8c-3.1,1.7-8.4,1.5-11-1.4c0.3-4.6,2.4-10.5,3.9-13.7C441.6,171.1,442.7,170.1,442.7,170.1z" />
            <text transform="matrix(1 0 0 1 513.1736 186.2986)">棘下筋</text>
            <line x1="441.9" y1="183.7" x2="508.7" y2="183.7" />
            <circle cx="442.8" cy="183.7" r="0.9" />
          </Group>
          <Group id={13}>
            <path d="M106.4,259.7c1.9-3.7,5.5-12.8,6.5-16.2c1.7-5.8,1.8-7.7,1.7-14.9c-0.1-7.2-5-11.5-5-11.5c-1.9,1.9-3.8,6.2-5.4,15.9c-1.1,6.9-3.6,23.9-4.9,32.9c-0.1,0.6-0.3,1.6,0,2.1c0.6,0.9,1.5,0.3,2-0.1C101.9,267.3,104,264.3,106.4,259.7z" />
            <path d="M230.6,259.7c-1.9-3.7-5.5-12.8-6.5-16.2c-1.7-5.8-1.8-7.7-1.7-14.9c0.1-7.2,5-11.5,5-11.5c1.9,1.9,3.8,6.2,5.4,15.9c1.1,6.9,3.6,23.9,4.9,32.9c0.1,0.6,0.3,1.6,0,2.1c-0.6,0.9-1.5,0.3-2-0.1C235.1,267.3,233,264.3,230.6,259.7z" />
            <path d="M351.3,267.8c-0.3-0.5-0.1-1.4,0-2.1c1.3-9,3.8-25.9,4.9-32.9c1.5-9.7,3.4-13.9,5.4-15.9c0,0,4.7,6.1,5,11.5c0.4,7.2-0.5,9-1.7,14.9c-0.9,4.4-3,14.1-4,19.7c-0.6,3.6-1.6,8.7-1.6,8.7s-2.6,1.4-4.4,0.6C352.2,271.2,351.3,267.8,351.3,267.8z" />
            <path d="M489.8,267.8c0.3-0.5,0.1-1.4,0-2.1c-1.3-9-3.8-25.9-4.9-32.9c-1.5-9.7-3.4-13.9-5.4-15.9c0,0-4.7,6.1-5,11.5c-0.4,7.2,0.5,9,1.7,14.9c0.9,4.4,3,14.1,4,19.7c0.6,3.6,1.6,8.7,1.6,8.7s2.6,1.4,4.4,0.6C489,271.2,489.8,267.8,489.8,267.8z" />
            <text transform="matrix(1 0 0 1 271.4991 242.6511)">
              前腕伸筋群
            </text>
            <line x1="229" y1="240.2" x2="265.8" y2="240.2" />
            <circle cx="229.9" cy="240.2" r="0.9" />
            <line x1="360.1" y1="240.1" x2="323.2" y2="240.1" />
            <circle cx="359.1" cy="240.1" r="0.9" />
          </Group>
          <Group id={14}>
            <path d="M108.3,272.5c0.1-0.1,0.3-0.4,0.4-0.7c2.2-3.8,9.9-18.1,13.9-30.2c2.5-7.6,2.6-17.2,3.4-20.1c0,0-1.7-0.1-4.4,1.6c-2.7,1.7-6.8,4.7-7.3,5.6c0.1,3.2,0.2,7.7-0.6,11c-0.8,3.7-3.4,10.9-4.5,13.4c-1.1,2.5-4.8,10.6-6,12.1c-1.9,2.5-2.8,2.9-2.8,2.9c0.5,0.4,1.5,5.1,5.8,5.1C107.4,273.2,107.5,273.3,108.3,272.5z" />
            <path d="M228.7,272.5c-0.1-0.1-0.3-0.4-0.4-0.7c-2.2-3.8-9.9-18.1-13.9-30.2c-2.5-7.6-2.6-17.2-3.4-20.1c0,0,1.7-0.1,4.4,1.6s6.8,4.7,7.3,5.6c-0.1,3.2-0.2,7.7,0.6,11c0.8,3.7,3.4,10.9,4.5,13.4c1.1,2.5,4.8,10.6,6,12.1c1.9,2.5,2.8,2.9,2.8,2.9c-0.5,0.4-1.5,5.1-5.8,5.1C229.6,273.2,229.5,273.3,228.7,272.5z" />
            <path d="M366.7,230.3c0.6-0.8,2.9-2.9,6.6-5.6c2.5-1.9,4.7-3.1,4.7-3.1c-0.8,2.9-0.9,12.5-3.4,20.1c-4,12.1-11.6,26.3-13.9,30.2c-0.2,0.3-0.3,0.6-0.4,0.7c-0.8,0.7-1.2-1.5-1.3-3.5c0,0,0.7-5.1,1.3-8.6c0.6-3.4,1-7.4,2.7-14.7C364.7,238.4,366.7,230.3,366.7,230.3" />
            <path d="M474.4,230.3c-0.6-0.8-2.9-2.9-6.6-5.6c-2.5-1.9-4.7-3.1-4.7-3.1c0.8,2.9,0.9,12.5,3.4,20.1c4,12.1,11.6,26.3,13.9,30.2c0.2,0.3,0.3,0.6,0.4,0.7c0.8,0.7,1.2-1.5,1.3-3.5c0,0-0.7-5.1-1.3-8.6c-0.6-3.4-1-7.4-2.7-14.7C476.4,238.4,474.4,230.3,474.4,230.3" />
            <text transform="matrix(1 0 0 1 269.7277 261.931)">前腕屈筋群</text>
            <line x1="225" y1="259.2" x2="265.8" y2="259.2" />
            <circle cx="225.9" cy="259.2" r="0.9" />
            <line x1="365.1" y1="259.2" x2="323.2" y2="259.2" />
            <circle cx="364.1" cy="259.2" r="0.9" />
          </Group>
          <Group id={16}>
            <path d="M135.1,329.3c-0.3-1-0.6-3-1-5c-1.5-8.2-3.5-20.7-2.9-31.6c0.2-4,1.2-10,2.3-15.9c0.1-0.6,0-1.3,0.4-1.9c4.1-7.4,10.9-16.2,14.3-15.1c0.9,2.1,1.9,3.9,1.9,3.9s-6.2,6.6-7.3,16.1s-1.6,13.7,0,26.3c1.9,14.5,6.8,29.7,6.8,29.7s0.6,3.2-2.1,4.7C144.7,342.1,136.4,333.7,135.1,329.3z" />
            <path d="M154.8,274.7c1,4.3,2.3,14.6,1.5,22.9c-0.8,8.3-2.6,25.9-3,29.3c-0.2,1.4-0.3,3.1-0.7,4.7c-0.5,2.2-1.8,3.9-3.3,4.3c4.5,8.3,6.9,9.9,10.1,12c-0.2-1.9,0.2-4.4,0.7-6.9s1.2-4.8,1.5-6.5c0.5-3,1.8-14.3,1.7-21.2l-0.1-3.1c0,0-1.7-8.3-3.6-15.4C158,289,156,276.6,154.8,274.7" />
            <path d="M142.8,274.9c-1.7,5.7-3,20.7-1.5,30.4c2.3,15,4.9,31,8.1,30.6c3.2-0.4,3.6-5.6,3.9-9c0.4-3.4,2.3-21,3-29.3c0.8-8.3-0.5-18.6-1.5-22.9c-0.9-4.1-4.3-9.8-4.9-10.9C147.2,265.9,144.3,269.8,142.8,274.9z" />
            <path d="M201.9,329.3c0.3-1,0.6-3,1-5c1.5-8.2,3.5-20.7,2.9-31.6c-0.2-4-1.2-10-2.3-15.9c-0.1-0.6,0-1.3-0.4-1.9c-4.1-7.4-10.9-16.2-14.3-15.1c-0.9,2.1-1.9,3.9-1.9,3.9s6.2,6.6,7.3,16.1s1.6,13.7,0,26.3c-1.9,14.5-6.8,29.7-6.8,29.7s-0.6,3.2,2.1,4.7C192.3,342.1,200.6,333.7,201.9,329.3z" />
            <path d="M182.1,274.7c-1,4.3-2.3,14.6-1.5,22.9c0.8,8.3,2.6,25.9,3,29.3c0.2,1.4,0.3,3.1,0.7,4.7c0.5,2.2,1.8,3.9,3.3,4.3c-4.5,8.3-6.9,9.9-10.1,12c0.2-1.9-0.2-4.4-0.7-6.9c-0.5-2.4-1.2-4.8-1.5-6.5c-0.5-3-1.8-14.3-1.7-21.2l0.1-3.1c0,0,1.7-8.3,3.6-15.4C179,289,181,276.6,182.1,274.7" />
            <path d="M194.1,274.9c1.7,5.7,3,20.7,1.5,30.4c-2.3,15-4.9,31-8.1,30.6c-3.2-0.4-3.6-5.6-3.9-9c-0.4-3.4-2.3-21-3-29.3c-0.8-8.3,0.5-18.6,1.5-22.9c0.9-4.1,4.3-9.8,4.9-10.9C189.8,265.9,192.6,269.8,194.1,274.9z" />
            <text transform="matrix(1 0 0 1 34.8027 320.7827)">大腿四頭筋</text>
            <line x1="148.8" y1="318.1" x2="85.8" y2="318.1" />
            <circle cx="147.9" cy="318.1" r="0.9" />
          </Group>
          <Group id={17}>
            <path d="M383.2,299.6c0-2.3-0.1-4.7,0.1-6.9c0.3-5.3,1.9-14.1,3.3-21.4c0.1-0.6,0-1.2,0.3-1.7c1.2-1.9,8.6-10.6,15.9-13.6c8.1-3.4,11.4-2,13-0.8c0,0,5,3.6,4.9,7.5c0,0-0.3,10.5-0.4,11.4c-0.2,3.3-1.8,8.2-2.1,8.6s-6.9,5.4-13.9,6.3c-6.9,0.8-13.8,2.7-16,6.4c-2.3,3.7-4.6,10.3-4.7,11.1C383.4,303.2,383.3,302.4,383.2,299.6z" />
            <path d="M457.9,299.6c0-2.3,0.1-4.7-0.1-6.9c-0.3-5.3-1.9-14.1-3.3-21.4c-0.1-0.6,0-1.2-0.3-1.7c-1.2-1.9-8.6-10.6-15.9-13.6c-8.1-3.4-11.4-2-13-0.8c0,0-5,3.6-4.9,7.5c0,0,0.3,10.5,0.4,11.4c0.2,3.3,1.8,8.2,2.1,8.6s6.9,5.4,13.9,6.3c6.9,0.8,13.8,2.7,16,6.4c2.3,3.7,4.6,10.3,4.7,11.1C457.7,303.2,457.9,302.4,457.9,299.6z" />
            <text transform="matrix(1 0 0 1 513.1737 282.7809)">大臀筋</text>
            <line x1="441.9" y1="280.1" x2="508.7" y2="280.1" />
            <circle cx="442.8" cy="280.1" r="0.9" />
          </Group>
          <Group id={18}>
            <path d="M411.5,346.1c0,0-0.2,1.5-4-2.6c-3.8-4.1-8.6-11.8-7.4-17c1.2-5.2,2.5-13.5,2.9-17.3c0.5-3.8,1.5-18.1,1.2-21.2c-0.3-3.1,5.8-5.8,7.2-0.2c1.4,5.7,0.7,18.9,0.2,23.9c-0.5,5.1-1,14-1.1,19C410.4,335.8,411.5,346.1,411.5,346.1z" />
            <path d="M390.8,345.3c0,0,6.1-7.8,8.7-16.3c2.6-8.4,3.2-15.8,3.5-19.9c0.3-4.1,1.8-19.4,1.4-21.1c-2.4,0.1-5.8,0.3-7.7,1.2c-1.8,0.9-5.4,11-6.6,18.6c-0.4,2.4-1.1,13.6-0.9,18.6C389.4,331.4,390.8,345.3,390.8,345.3z" />
            <path d="M429.7,346.1c0,0,0.2,1.5,4-2.6c3.8-4.1,8.6-11.8,7.4-17c-1.2-5.2-2.5-13.5-2.9-17.3c-0.5-3.8-1.5-18.1-1.2-21.2c0.3-3.1-5.8-5.8-7.2-0.2c-1.4,5.7-0.7,18.9-0.2,23.9c0.5,5.1,1,14,1.1,19C430.7,335.8,429.7,346.1,429.7,346.1z" />
            <path d="M450.4,345.3c0,0-6.1-7.8-8.7-16.3c-2.6-8.4-3.2-15.8-3.5-19.9c-0.3-4.1-1.8-19.4-1.4-21.1c2.4,0.1,5.8,0.3,7.7,1.2s5.4,11,6.6,18.6c0.4,2.4,1.1,13.6,0.9,18.6C451.7,331.4,450.4,345.3,450.4,345.3z" />
            <text transform="matrix(1 0 0 1 513.1737 325.63)">
              ハムストリングス
            </text>
            <line x1="445.7" y1="323" x2="509.5" y2="323" />
            <circle cx="446.6" cy="323" r="0.9" />
          </Group>
          <Group id={19}>
            <path d="M160,352.1c1.1,8.2,3.1,25.1,0.7,37.9c-2.5,13.5-4.4,24.5-4.9,29.7c-0.1,0.9-0.1,1.7-0.1,2.2c0,0,0.2-16.3,0.2-31.1c0-16-3-24-0.8-29.1C158.6,353.9,160,352.1,160,352.1z" />
            <path d="M177,352.1c-1.1,8.2-3.1,25.1-0.7,37.9c2.5,13.5,4.4,24.5,4.9,29.7c0.1,0.9,0.1,1.7,0.1,2.2c0,0-0.2-16.3-0.2-31.1c0-16,3-24,0.8-29.1C178.3,353.9,177,352.1,177,352.1z" />
            <path d="M400.8,366.8c0.7-12,8.7-16.6,10.7-18.9c0.1,0.9,0.4,2.7,0.7,5.1c0.7,5.3,1.7,13.6,1.7,22.1c0,3.7-0.1,7.5-0.5,11c-0.1,0.5-0.1,1-0.2,1.6c-0.6,3.5-3.1,8.6-6.2,18.7c-2.9,9.4-3.9,15.5-4.4,17.7c-0.4,2.3-3.1,10.3-4.8,0.1c-0.4-2.2-2.8-14.3-6.4-25.9c-1.1-3.4-2.1-8.6-2.4-10.3c-0.6-3-1.3-8.9-1.1-11.4c0.2-2.7,0.4-5.6,0.6-8.5c0.3-4.3,0.7-8.7,1.1-12.3c0.3-2.7,0.8-6,1.1-7.7c0.5-2.6,0-2.9,0.2-2.8c0.5,0.3,6.7,2.2,9,10.2c2.2,7.5,0.8,9.4,0.9,12.1" />
            <path d="M440.3,366.8c-0.7-12-8.7-16.6-10.7-18.9c-0.1,0.9-0.4,2.7-0.7,5.1c-0.7,5.3-1.7,13.6-1.7,22.1c0,3.7,0.1,7.5,0.5,11c0.1,0.5,0.1,1,0.2,1.6c0.6,3.5,3.1,8.6,6.2,18.7c2.9,9.4,3.9,15.5,4.4,17.7c0.4,2.3,3.1,10.3,4.8,0.1c0.4-2.2,2.8-14.3,6.4-25.9c1.1-3.4,2.1-8.6,2.4-10.3c0.6-3,1.3-8.9,1.1-11.4c-0.2-2.7-0.4-5.6-0.6-8.5c-0.3-4.3-0.7-8.7-1.1-12.3c-0.3-2.7-0.8-6-1.1-7.7c-0.5-2.6,0-2.9-0.2-2.8c-0.5,0.3-6.7,2.2-9,10.2c-2.2,7.5-0.8,9.4-0.9,12.1" />
            <text transform="matrix(1 0 0 1 269.7274 382.8163)">
              下腿三頭筋
            </text>
            <line x1="177.6" y1="378.5" x2="251.8" y2="378.5" />
            <circle cx="178.6" cy="378.5" r="0.9" />
            <line x1="396.9" y1="378.5" x2="337.3" y2="378.5" />
            <circle cx="396" cy="378.5" r="0.9" />
          </Group>
          <Group id={20}>
            <path d="M146.4,369.7c1.2,11.1,1.5,29.4,1.6,37.4c0.2,7.1-0.3,16.3-0.7,16.8c-1.8-0.2-7.1-20.8-8.4-31c-1.8-14.6-0.2-35.9,1.8-39.8C142.4,350.4,145.5,361.1,146.4,369.7z" />
            <path d="M190.5,369.7c-1.2,11.1-1.5,29.4-1.6,37.4c-0.2,7.1,0.3,16.3,0.7,16.8c1.8-0.2,7.1-20.8,8.4-31c1.8-14.6,0.2-35.9-1.8-39.8C194.6,350.4,191.5,361.1,190.5,369.7z" />
            <text transform="matrix(1 0 0 1 44.0147 381.1586)">前脛骨筋</text>
            <line x1="143" y1="378.5" x2="85.8" y2="378.5" />
            <circle cx="143" cy="378.5" r="0.9" />
          </Group>
          <Group id={10}>
            <path d="M150.1,199.2c0,0,1.8,9.7,2.4,17.8c0.6,8,0,14.1,0.1,17.3c0.1,5.5,1.1,20.2,2.3,23.5c-3.7-3-8.6-8.2-10.3-10.7c-1.7-2.4-4.8-5.4-5.2-7c-0.3-1.6-1.6-12.3-1.6-12.3s1.3-2.1,1.8-8.2c0.4-4.6-0.3-12.4-0.6-15.5c-0.2-2.1,0-5.3-0.5-13.6c2.7,4,5.7,6.2,7.2,6.8C147.9,198.3,150.1,199.2,150.1,199.2z" />
            <path d="M186.9,199.2c0,0-1.8,9.7-2.4,17.8c-0.6,8,0,14.1-0.1,17.3c-0.1,5.5-1.1,20.2-2.3,23.5c3.7-3,8.6-8.2,10.3-10.7c1.7-2.4,4.8-5.4,5.2-7c0.3-1.6,1.6-12.3,1.6-12.3s-1.3-2.1-1.8-8.2c-0.4-4.6,0.3-12.4,0.6-15.5c0.2-2.1,0-5.3,0.5-13.6c-2.7,4-5.7,6.2-7.2,6.8C189.1,198.3,186.9,199.2,186.9,199.2z" />
            <text transform="matrix(1 0 0 1 52.5125 230.799)">腹斜筋</text>
            <line x1="143.8" y1="228.2" x2="85.8" y2="228.2" />
            <circle cx="142.9" cy="228.2" r="0.9" />
          </Group>
          <Group id={11}>
            <path d="M167.5,198.2c-0.2-1.1-0.7-1.4-1.7-0.8c-6.2,4.9-12,6-12,6s0.2,2.4,0.3,3.3c0.4,2.8,0.5,8.4,1,8.1c1.3-0.7,8-2.8,11.6-3.3C168.3,211.4,167.8,199.7,167.5,198.2z" />
            <path d="M156.6,239.3c1.4,0.6,2.7,1,4.9,1.2c3,0.2,5.3-0.6,5.3,0.2c0.1,0.8,0.1,20,0.1,21.2c0,3.7-0.2,8.1-0.8,7.8c-0.6-0.3-4-5.9-5.2-9.2c-0.8-2.2-3.7-9.9-4.3-13.3C156,242.8,155.5,238.8,156.6,239.3z" />
            <path d="M155.5,232.6c0.1,1.6,0.4,4.8,2.2,5.3c1.9,0.4,8.6,1,8.8,0.2c0.5-2.4,0.8-5.8,0.7-7.2c-0.1-1.5-0.1-2.6-0.7-2.6c-0.6,0-3.2,0.4-5.9,0.3c-3.5-0.2-4.3-0.9-4.7-0.3C155.5,228.9,155.4,230.2,155.5,232.6z" />
            <path d="M166.9,226c0,0-3.3,0.5-5.6,0.4c-2.2-0.1-5.2-0.7-5.5-1.2c-0.4-2-1.4-6.3,0.1-7.3c1.6-1,3.5-1.7,5.4-2.4c2.8-1,5.4-1.9,5.9-1.1c0.4,0.6,0.5,4.1,0.5,5.6C167.7,221.5,167.5,224.9,166.9,226z" />
            <path d="M169.5,198.2c0.2-1.1,0.7-1.4,1.7-0.8c6.2,4.9,12,6,12,6s-0.2,2.4-0.3,3.3c-0.4,2.8-0.5,8.4-1,8.1c-1.3-0.7-8-2.8-11.6-3.3C168.7,211.4,169.2,199.7,169.5,198.2z" />
            <path d="M180.4,239.3c-1.4,0.6-2.7,1-4.9,1.2c-3,0.2-5.3-0.6-5.3,0.2c-0.1,0.8-0.1,20-0.1,21.2c0,3.7,0.2,8.1,0.8,7.8c0.6-0.3,4-5.9,5.2-9.2c0.8-2.2,3.7-9.9,4.3-13.3C181,242.8,181.5,238.8,180.4,239.3z" />
            <path d="M181.5,232.6c-0.1,1.6-0.4,4.8-2.2,5.3c-1.9,0.4-8.6,1-8.8,0.2c-0.5-2.4-0.8-5.8-0.7-7.2c0.1-1.5,0.1-2.6,0.7-2.6s3.2,0.4,5.9,0.3c3.5-0.2,4.3-0.9,4.7-0.3C181.5,228.9,181.6,230.2,181.5,232.6z" />
            <path d="M170,226c0,0,3.3,0.5,5.6,0.4c2.2-0.1,5.2-0.7,5.5-1.2c0.4-2,1.4-6.3-0.1-7.3c-1.6-1-3.5-1.7-5.4-2.4c-2.8-1-5.4-1.9-5.9-1.1c-0.4,0.6-0.5,4.1-0.5,5.6C169.2,221.5,169.5,224.9,170,226z" />
            <text transform="matrix(1 0 0 1 53.228 253.1328)">腹直筋</text>
            <line x1="162.4" y1="233.6" x2="85.8" y2="248.4" />
            <circle cx="161.5" cy="233.6" r="0.9" />
          </Group>
          <Group id={12}>
            <path d="M419.6,257.6V233c0,0-0.2-1.5-0.8-0.4c-0.7,1.1-2.6,3.4-4.2,6c-1.6,2.6-3.8,4.8-3.8,5.8c0,1,1,5.6,3.4,8.7C417.2,257.2,419.7,259,419.6,257.6z" />
            <path d="M421.5,257.6V233c0,0,0.2-1.5,0.8-0.4c0.7,1.1,2.6,3.4,4.2,6c1.6,2.6,3.8,4.8,3.8,5.8c0,1-1,5.6-3.4,8.7C424,257.2,421.4,259,421.5,257.6z" />
            <text transform="matrix(1 0 0 1 513.1736 251.4312)">
              脊柱起立筋
            </text>
            <line x1="424" y1="248.8" x2="508.7" y2="248.8" />
            <circle cx="424.9" cy="248.8" r="0.9" />
          </Group>
          <Group id={15}>
            <path d="M166.5,278c0,0.4,0,0.8,0,1.3c-0.2,8.6-1,27.7-3.3,31.9c0-4.7-5.3-22.3-6.1-25.7c-1.4-6.5-2-14.1-6.1-19.7c-0.7-0.9-5.6-11.1-3.6-10c1.4,0.8,4,4.3,7.3,7.8c3.3,3.6,7.3,8.5,8.2,9.5C163.9,274.3,166.5,277.4,166.5,278z" />
            <path d="M170.5,278c0,0.4,0,0.8,0,1.3c0.2,8.6,1,27.7,3.3,31.9c0-4.7,5.3-22.3,6.1-25.7c1.4-6.5,2-14.1,6.1-19.7c0.7-0.9,5.7-11,3.6-10c-1.5,0.7-4,4.3-7.3,7.8s-7.3,8.5-8.2,9.5C173.1,274.3,170.5,277.4,170.5,278z" />
            <text transform="matrix(1 0 0 1 30.3588 287.5743)">内転筋群</text>
            <line x1="162.7" y1="284.9" x2="70.5" y2="284.9" />
            <circle cx="161.8" cy="284.9" r="0.9" />
          </Group>
          <Group id={9}>
            <path d="M138.5,191.1c0,0,2.7,6.2,10.1,15.2c-2.7-0.3-6.1-1.1-7.5-2.7C139.8,202.1,137.4,197.3,138.5,191.1z" />
            <path d="M138.4,196.8c0,0,0.4,9.1,2.3,11c2,2.1,7,3.8,5.7,2.3c-1.5-1.7-2.3-3.4-3-5C140,203.9,138.9,199.9,138.4,196.8z" />
            <path d="M138.6,200.2c0,0-2.3,7.5,2.7,12.3c2.4,2.3,6,3.5,5.2,2.6c-0.7-0.9-1.9-4.3-2.1-5c-2.4-1-3.8-2.2-4.3-3.3C139,204.6,138.6,200.2,138.6,200.2z" />
            <path d="M138.9,209.2c0,0-0.5,4.6,1.9,7.2c2.3,2.6,6.9,4.3,5.6,2.8c-1.5-1.7-2.3-3.4-3-5C141.8,213.3,140.5,212.1,138.9,209.2z" />
            <path d="M198.5,191.1c0,0-2.7,6.2-10.1,15.2c2.7-0.3,6.1-1.1,7.5-2.7C197.2,202.1,199.6,197.3,198.5,191.1z" />
            <path d="M198.6,196.8c0,0-0.4,9.1-2.3,11c-2,2.1-7,3.8-5.7,2.3c1.5-1.7,2.3-3.4,3-5C196.9,203.9,198,199.9,198.6,196.8z" />
            <path d="M198.1,209.2c0,0,0.5,4.6-1.9,7.2c-2.3,2.6-6.9,4.3-5.6,2.8c1.5-1.7,2.3-3.4,3-5C195.1,213.3,196.5,212.1,198.1,209.2z" />
            <path d="M198.4,200.2c0,0,2.3,7.5-2.7,12.3c-2.4,2.3-6,3.5-5.2,2.6s1.9-4.3,2.1-5c2.4-1,3.8-2.2,4.3-3.3C198,204.6,198.4,200.2,198.4,200.2z" />
            <text transform="matrix(1 0 0 1 52.5125 213.6477)">前鋸筋</text>
            <line x1="143.8" y1="211.2" x2="85.8" y2="211.2" />
            <circle cx="142.9" cy="211.2" r="0.9" />
          </Group>
        </g>
      </svg>
      {isEdit &&
        Object.keys(parts).map((id) =>
          parts[id] > 0 ? (
            <input
              type="hidden"
              key={`${id}-${parts[id]}`}
              name={`parts[ ${id}]`}
              defaultValue={parts[id]}
            />
          ) : (
            <></>
          )
        )}
    </>
  );
}
