import React from "react";
import { NavLink } from "react-router-dom";

export default function Main() {
  const iframe = (id: string) => {
    return (
      <iframe
        width="100%"
        height="500"
        src={`https://www.youtube.com/embed/${id}?cc_load_policy=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    );
  };

  const youtubes: { [key: string]: string } = {
    fsskXaqVDik: "トレーニングの追加",
    "7lWt48HQjCA": "メニューの追加",
    iq5h43apUEA: "トレーニングの方法",
  };

  const qa: { [key: string]: any } = {
    "休憩時間やセット数の変更するには？": (
      <>
        休憩時間やセット数は
        <NavLink className="mx-1" to="/profile">
          設定
        </NavLink>
        で変更できます
      </>
    ),
    "フォローしたらどうなるの？": (
      <>
        フォローをすると、そのユーザの作成したトレーニングやメニューを使用することができます。
      </>
    ),
  };

  return (
    <main className="container pt-5">
      <h1 className="pb-2 border-bottom mb-5">このアプリの使い方</h1>
      <h3>初めての方</h3>
      <div className="accordion" id="accordionHow2Use">
        {Object.keys(youtubes).map((youtube: string, i: number) => (
          <div className="accordion-item" key={youtube}>
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse2${i}`}
                aria-expanded="false"
                aria-controls={`#collapse2${i}`}
              >
                Step{i + 1}. {youtubes[youtube]}
              </button>
            </h2>
            <div
              id={`collapse2${i}`}
              className="accordion-collapse collapse"
              data-bs-parent="#accordionHow2Use"
            >
              <div className="accordion-body">{iframe(youtube)}</div>
            </div>
          </div>
        ))}
      </div>
      <h3 className="mt-5">Q&amp;A</h3>
      <div className="accordion" id="accordionQA">
        {Object.keys(qa).map((title: string, i: number) => (
          <div className="accordion-item" key={i}>
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${i}`}
                aria-expanded="false"
                aria-controls={`#collapse${i}`}
              >
                {title}
              </button>
            </h2>
            <div
              id={`collapse${i}`}
              className="accordion-collapse collapse"
              data-bs-parent="#accordionQA"
            >
              <div className="accordion-body">{qa[title]}</div>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}
