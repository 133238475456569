import { Link } from "react-router-dom";
// import cookie from "react-cookies";

const NavLink = (args: { [key: string]: any }) => {
  return (
    <Link
      className={args.className}
      to={args.to}
      onClick={(e) => {
        const navBtn: any = document.querySelector(
          "#root nav.navbar .navbar-toggler:not(.collapsed)"
        );
        if (navBtn) navBtn.click();
      }}
    >
      {args.children}
    </Link>
  );
};

export default function Header(result: { [key: string]: any }) {
  return (
    <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img src="/imgs/logow.png" alt="Pedrip" />
          Pedrip
        </NavLink>
        {Object.keys(result).length === 0 ? (
          <></>
        ) : (
          <>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarColor01"
              aria-controls="navbarColor01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarColor01">
              <ul className="navbar-nav me-auto">
                {result.id ? (
                  <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/training">
                      <i className="fa-solid fa-dumbbell" />
                      トレーニング
                    </NavLink>
                  </li>
                  {(result.trainers || []).length > 0 && (
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/gyms">
                        <i className="fa-solid fa-chalkboard-user" />
                        <span>コーチ</span>
                      </NavLink>
                    </li>
                  )}
                  </>
                ) : (
                  <></>
                )}
              </ul>
              <ul className="navbar-nav ms-auto">
                {result.id ? (
                  <>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        data-bs-toggle="dropdown"
                        href="/"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {result.thumb && (
                          <img
                            className="rounded-circle me-1"
                            src={result.thumb}
                            alt=""
                            style={{
                              height: "20px",
                              verticalAlign: "text-top",
                            }}
                          />
                        )}
                        {result.name || "名前未設定"}
                      </a>
                      <div className="dropdown-menu dropdown-menu-end">
                        <NavLink className="dropdown-item" to="/menus/0/edit">
                          新しいメニューを追加する
                        </NavLink>
                        <NavLink className="dropdown-item" to="/menus">
                          今まで作ったメニュー
                        </NavLink>
                        <hr className="dropdown-divider" />
                        <NavLink
                          className="dropdown-item"
                          to="/trainings/0/edit"
                        >
                          新しいトレーニングを追加する
                        </NavLink>
                        <NavLink className="dropdown-item" to="/trainings">
                          今まで作ったトレーニング
                        </NavLink>
                        <hr className="dropdown-divider" />
                        <NavLink className="dropdown-item" to="/results">
                          履歴
                        </NavLink>
                        <NavLink className="dropdown-item" to="/follows">
                          フォロー
                        </NavLink>
                        <NavLink className="dropdown-item" to="/profile">
                          設定
                        </NavLink>
                        {result.name && (
                          <NavLink
                            className="dropdown-item"
                            to={`/follows/${result.name}`}
                          >
                            自分のページ
                          </NavLink>
                        )}
                        <NavLink className="dropdown-item" to="/how2use">
                          使い方
                        </NavLink>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          href="/"
                          onClick={async (e) => {
                            e.preventDefault();
                            (document as any).loading();
                            await (document as any).fetch("login", {
                              method: "DELETE",
                            });
                            window.location.href = "/";
                          }}
                        >
                          ログアウト
                        </a>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/login">
                        ログイン
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
}
