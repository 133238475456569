import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Email, Name, Password } from "../concern/Input";

export default function Signup() {
  let [errors, setErrors] = useState<{ [key: string]: any }>();
  let [success, setSuccess] = useState<string>();

  return (
    <div id="signup">
      <div className="login">
        <h1>メールアドレスで会員登録</h1>
        <form
          className="py-4 px-3"
          onSubmit={async (e: any) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const data = Object.fromEntries(formData.entries());

            const resp = await (document as any).fetch("signup", {
              method: "POST",
              body: JSON.stringify(data),
            });

            if (!resp.errors) {
              setErrors(undefined);
              setSuccess(
                "メールアドレスにメッセージを送信しました。\nメッセージに従って登録を完了してください。"
              );
              (document as any).querySelector('[name="password"]').value = "";
            } else {
              setSuccess(undefined);
              setErrors(resp.errors);
            }
          }}
        >
          <Name errors={errors} />
          <div className="my-3">
            <Email errors={errors} />
          </div>
          <Password
            errors={errors}
            onChange={(e: any) => {
              setSuccess(undefined);
              setErrors(undefined);
            }}
          />
          {success && (
            <div className="text-success text-center mt-3">
              {success.split("\n").map((txt: string, j: number) => (
                <React.Fragment key={j}>
                  {txt}
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}
          <div className="d-grid mt-4">
            <button className="btn btn-lg btn-primary">会員登録</button>
          </div>
        </form>
        <div className="signup text-center p-3">
          <Link to="/login">ログインはこちら</Link>
        </div>
      </div>
    </div>
  );
}
