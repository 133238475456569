import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TABLE from "./TABLE";
import Loading from "../../concern/Loading";
import Add from "./Add";

const types: { [key: string]: string } = {
  officials: "おすすめ",
  followers: "フォロワー",
  followees: "フォロイー",
  search: "検索",
};

export default function Main() {
  const { type } = useParams();
  let [resp, setResp] = useState<{ [key: string]: any }>();

  useEffect(() => {
    (async () => {
      if (types[type!]) {
        let _resp: { [key: string]: any } = { rows: [] };
        if (type !== "search") {
          _resp = await (await fetch(`/api/follows/${type}`)).json();
        }
        _resp.keyword = null;
        setResp(_resp);
      }
    })();
  }, [type]);

  if (!types[type!]) return <Add token={type!} />;
  if (resp === undefined) return <Loading />;

  return (
    <div className="container pt-4 pb-5">
      <div className="row">
        <div className="col-6">
          <h3>
            <Link to="/follows" className="me-3">
              <i className="fa-solid fa-angle-left"></i>
            </Link>
            {types[type!]}
          </h3>
        </div>
        <div className="col-6">
          {type === "search" && (
            <form
              className="input-group mb-3"
              onSubmit={async (e: any) => {
                e.preventDefault();
                const keyword = e.target.querySelector("input").value;
                const rows = await (
                  await fetch(`/api/follows/${type}/${keyword}`)
                ).json();
                rows.keyword = keyword;
                setResp(rows);
              }}
            >
              <span className="input-group-text">
                <i className="fa-solid fa-magnifying-glass" />
              </span>
              <input type="text" className="form-control" minLength={3} />
              <button type="submit" className="input-group-text">
                検索
              </button>
            </form>
          )}
        </div>
      </div>
      {resp && resp!.rows.length === 0 ? (
        <div className="alert alert-dismissible alert-info">
          {resp.keyword
            ? "見つかりませんでした"
            : type === "search"
            ? "キーワードを入力してください"
            : "空です"}
        </div>
      ) : (
        <TABLE resp={resp} types={{ rows: "" }} />
      )}
    </div>
  );
}
