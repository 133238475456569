import { Link, NavLink } from "react-router-dom";
import Modal from "./Modal";
import CustomLink from "./CustomLink";

function MyNavLink({
  className,
  to,
  children,
}: {
  className: string;
  to: string;
  children: any;
}) {
  return (
    <Link
      className={className}
      to={to}
      onClick={() => {
        const modal = (document as any).querySelector(".modal.show");
        if (!modal) return;

        modal.querySelector(".btn-close").click();
        window.scroll({ top: 0 });
      }}
    >
      {children}
    </Link>
  );
}

export default function Footer(result: { [key: string]: any }) {
  return (
    <>
      <footer className="">
        <nav className="container py-3">
          Copyright &copy; {new Date().getFullYear()} Devenue Inc. All rights
          reserved.
          <br />
          <Link to="/privacy">Privacy</Link>
          {" / "}
          <Link to="/terms">Terms</Link>
        </nav>
      </footer>
      {Object.keys(result).length > 0 && (
        <nav>
          {result.id ? (
            result.name ? (
              <>
                <NavLink to="/">
                  <i className="fa-solid fa-house" />
                  <span>ホーム</span>
                </NavLink>
                <NavLink to="/training">
                  <i className="fa-solid fa-dumbbell" />
                  <span>筋トレ</span>
                </NavLink>
                <CustomLink
                  to="/"
                  paths={["/menus", "/trainings"]}
                  data-bs-toggle="modal"
                  data-bs-target="#createModal"
                >
                  <i className="fa-solid fa-pen-ruler" />
                  <span>作成</span>
                </CustomLink>
                <NavLink to="/results">
                  <i className="fa-regular fa-calendar-days" />
                  <span>履歴</span>
                </NavLink>
                <CustomLink
                  to="/"
                  paths={["/profile", "/follows", "/how2use"]}
                  data-bs-toggle="modal"
                  data-bs-target="#settingModal"
                >
                  <i className="fa-solid fa-gear" />
                  <span>設定</span>
                </CustomLink>
                {(result.trainers || []).length > 0 && (
                  <NavLink to="/gyms">
                    <i className="fa-solid fa-chalkboard-user" />
                    <span>コーチ</span>
                  </NavLink>
                )}
              </>
            ) : (
              <>
                <a
                  href="/"
                  onClick={async (e) => {
                    e.preventDefault();
                    (document as any).loading();
                    await (document as any).fetch("login", {
                      method: "DELETE",
                    });
                    window.location.href = "/";
                  }}
                >
                  <i className="fa-solid fa-right-from-bracket" />
                  <span>ログアウト</span>
                </a>
              </>
            )
          ) : (
            <>
              <Link to="/">
                <i className="fa-solid fa-house" />
                <span>ホーム</span>
              </Link>
              <Link to="/login">
                <i className="fa-solid fa-right-to-bracket" />
                <span>ログイン</span>
              </Link>
              <a href="/" data-bs-toggle="modal" data-bs-target="#signupModal">
                <i className="fa-solid fa-user-pen" />
                <span>新規登録</span>
              </a>
            </>
          )}
        </nav>
      )}
      {result.id ? (
        <>
          <Modal
            id="create"
            title={
              <>
                <i className="fa-solid fa-pen-ruler me-1" />
                作成
              </>
            }
            body={
              <>
                <h6>
                  <i className="fa-solid fa-clipboard-list me-1" />
                  メニュー
                </h6>
                <div className="row mb-4">
                  <div className="col">
                    <MyNavLink
                      className="btn btn-primary d-block"
                      to="/menus/0/edit"
                    >
                      <i className="fa-solid fa-plus me-1" />
                      新しく作る
                    </MyNavLink>
                  </div>
                  <div className="col">
                    <MyNavLink
                      className="btn btn-outline-secondary d-block"
                      to="/menus"
                    >
                      <i className="fa-solid fa-table-list me-1" />
                      作ったやつ
                    </MyNavLink>
                  </div>
                </div>
                <h6>
                  <i className="fa-solid fa-dumbbell me-1" />
                  トレーニング
                </h6>
                <div className="row">
                  <div className="col">
                    <MyNavLink
                      className="btn btn-primary d-block"
                      to="/trainings/0/edit"
                    >
                      <i className="fa-solid fa-plus me-1" />
                      新しく作る
                    </MyNavLink>
                  </div>
                  <div className="col">
                    <MyNavLink
                      className="btn btn-outline-secondary d-block"
                      to="/trainings"
                    >
                      <i className="fa-solid fa-table-list me-1" />
                      作ったやつ
                    </MyNavLink>
                  </div>
                </div>
              </>
            }
            footer={<></>}
          />
          <Modal
            id="setting"
            title={
              <>
                <i className="fa-solid fa-gear me-1" />
                設定
              </>
            }
            body={
              <>
                <div className="row mb-4">
                  <div className="col-6 mb-3">
                    <MyNavLink
                      className="btn btn-outline-secondary d-block"
                      to="/profile"
                    >
                      設定
                    </MyNavLink>
                  </div>
                  {result.name && (
                    <div className="col-6 mb-3">
                      <MyNavLink
                        className="btn btn-outline-secondary d-block"
                        to={`/follows/${result.name}`}
                      >
                        自分のページ
                      </MyNavLink>
                    </div>
                  )}
                  <div className="col-6 mb-3">
                    <MyNavLink
                      className="btn btn-outline-secondary d-block"
                      to="/follows"
                    >
                      フォロー
                    </MyNavLink>
                  </div>
                  <div className="col-6 mb-3">
                    <MyNavLink
                      className="btn btn-outline-secondary d-block"
                      to="/how2use"
                    >
                      使い方
                    </MyNavLink>
                  </div>
                  <div className="col-6 mb-3">
                    <a
                      className="btn btn-danger d-block"
                      href="/"
                      onClick={async (e) => {
                        e.preventDefault();
                        (document as any).loading();
                        await (document as any).fetch("login", {
                          method: "DELETE",
                        });
                        window.location.href = "/";
                      }}
                    >
                      ログアウト
                    </a>
                  </div>
                </div>
              </>
            }
            footer={<></>}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
