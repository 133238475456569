import { Link } from "react-router-dom";
import NotFound from "../../concern/NotFound";

export default function Index() {
  const rows = (document as any).user.trainers || {};
  if (rows.length === 0) return <NotFound />;

  return (
    <main className="container">
      <div className="row align-items-center">
        <div className="col-sm-6 mb-3">
          <h2 className="header mb-0">Gym</h2>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">
              <span>名前</span>
            </th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {rows.map((row: any) => {
            return (
              <tr key={row.id}>
                <td>{row.name || "-"}</td>
                <td className="text-end">
                  <Link
                    to={`/gyms/${row.id}/users`}
                    className="fa-solid fa-users text-primary"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </main>
  );
}
