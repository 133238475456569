import { useEffect, useState } from "react";
import Body from "../concern/Body";
import Loading from "../concern/Loading";
import moment from "moment";
import { Chart } from "react-google-charts";
import { Link, NavLink } from "react-router-dom";
import ThumbPath from "../concern/ThumbPath";

export default function Main() {
  const types: { [key: string]: string } = {
    total: "重量×回数",
    weight: "最大重量",
  };
  let [curType, setCurType] = useState<string>(Object.keys(types)[0]);
  let [resp, setResp] = useState<{ [key: string]: any }>();

  const fetchFunction = async () => {
    let resp = await (await fetch("/api/dashboard")).json();
    setResp(resp);
  };

  useEffect(() => {
    fetchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (resp === undefined) return <Loading />;

  let data: any = [];
  if ((resp.weight || []).length !== 0) {
    const names: { [key: number]: string } = {};
    const weights: { [key: string]: any } = {};
    resp.weight.forEach((row: any) => {
      names[row.id] = row.name;
      const timestamp = moment(row.trained_date).unix();
      weights[timestamp] ||= {};
      weights[timestamp][row.id] ||= row[curType];
    });

    const cur = moment.unix(
      Math.min.apply(
        null,
        Object.keys(weights).map((a) => parseInt(a))
      )
    );
    const ended = moment.unix(
      Math.max.apply(
        null,
        Object.keys(weights).map((a) => parseInt(a))
      )
    );

    const dates = [];
    for (let i = 0; i < 100; i++) {
      dates.push(moment(cur));
      cur.add(1, "d");
      if (cur.isAfter(ended)) break;
    }

    const prev: { [key: number]: number } = {};
    data = [["日付"].concat(Object.values(names))];
    dates.forEach((date) => {
      const row: any[] = [date.format("MM/DD")];
      Object.keys(names).forEach((_id: string) => {
        const id = parseInt(_id);
        // const val = (weights[date.unix()] || {})[id] || prev[id] || null;
        const val = (weights[date.unix()] || {})[id] || prev[id] || 0;
        row.push(val);
        prev[id] = val;
      });
      data.push(row);
    });
  }

  const options = {
    axes: {
      y: {
        all: {
          label: types[curType],
          range: {
            // max: 30000,
            min: 0,
          },
        },
      },
    },
    // legend: {
    //   position: "bottom",
    //   textStyle: { fontSize: 12 },
    // },
  };

  return (
    <main className="container pt-4">
      {data.length === 0 && (
        <>
          <h2 className="header">ようこそ！</h2>
          <div className="alert alert-warning show mt-3 mb-5">
            まずは
            <NavLink className="mx-1" to="/how2use">
              使い方
            </NavLink>
            を見てみましょう
          </div>
        </>
      )}
      <h2 className="header">超回復</h2>
      <div className="row">
        <div className="col-sm-6 mb-5">
          <Body trainings={resp.recovery} />
        </div>
        {(resp.recovery || []).length !== 0 && (
          <div className="col-sm-6 mb-5">
            <div className="row recovery-table">
              {resp.recovery.map((row: any, i: number) => (
                <div className="col-6 mb-2" key={i}>
                  <table className="w-100">
                    <thead>
                      <tr>
                        <th colSpan={2} className="border-bottom">
                          <h3>
                            {row.Part.ja}({row.Part.recovery}時間)
                          </h3>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>回復</th>
                        <td>
                          <span className={`active${row.impact}`}>
                            {row.Part.hour_to_recovery
                              .replace(/:[0-9]+$/, "")
                              .replace(/:/, "時間")}
                            分
                          </span>
                          後<br />
                          {moment(row.Part.recovered_at).format(
                            "MM/DD(ddd) hh:mm"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>最終</th>
                        <td>
                          {moment(row.Part.trained_at).format(
                            "MM/DD(ddd) hh:mm"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div>
        <h2 className="header">他の人</h2>
        <ul className="others mt-3 mb-4">
          {(resp.others || []).map((row: any, i: number) => (
            <li key={i}>
              <Link
                to={`/follows/${row.name}`}
                className="other card text-white bg-secondary"
              >
                <div className="card-header">
                  <img src={ThumbPath(row)} className="rounded-circle" alt="" />
                  {row.name}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col-7 text-start pb-2"
                      style={{ minHeight: "90px" }}
                    >
                      {row.training_names
                        .slice(0, 3)
                        .map((training_name: string, i: number) => (
                          <p key={i}>{training_name}</p>
                        ))}
                      {row.training_names.length > 3 && (
                        <div>
                          他
                          <b className="mx-1">
                            {row.training_names.length - 3}
                          </b>
                          種目
                        </div>
                      )}
                    </div>
                    <div className="col-5 text-end">
                      <div className="repeat">
                        <i className="fa-solid fa-repeat" />
                        <b className="mx-1">{row.reps}</b>
                        Reps
                      </div>
                      <div className="date">
                        <i className="fa-regular fa-calendar-days me-1" />
                        {moment(row.trained_at).format("YYYY/MM/DD(ddd)")}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {(resp.weight || []).length !== 0 && (
        <main>
          <h2 className="header">重量履歴</h2>
          <ul className="nav nav-tabs my-3">
            {Object.keys(types).map((type: string) => (
              <li className="nav-item" key={type}>
                <a
                  className={`nav-link ${type === curType ? "active" : ""}`}
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurType(type);
                  }}
                >
                  {types[type]}
                </a>
              </li>
            ))}
          </ul>
          {data.length === 0 ? (
            <div className="alert alert-warning show">データがありません</div>
          ) : (
            <Chart
              chartType="Line"
              width="100%"
              height="70vh"
              data={data}
              options={options}
            />
          )}
        </main>
      )}
    </main>
  );
}
