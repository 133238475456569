export default function Index() {
  return (
    <g className="body">
      <path
        d="M499.9,283.3c-0.9-0.7-1.5-1.8-2.1-3c-0.9-1.6-1.9-3.5-3.9-4.8c-3.1-2-3-3.9-3-4c0-0.3-4.3-29.1-5.8-38.7
c-1.4-8.6-3.1-13.7-5.4-16c-1.5-1.5-1.6-4.6-1.8-8.9c0-1-0.1-2.1-0.2-3.3c-0.2-3.6-1.4-6.4-2.8-9.7c-1.1-2.5-2.2-5.2-3-8.5
c0.4-3.5-1.9-22.2-8.1-26.5c-3.3-2.3-7.6-3-11.5-3.6c-2.6-0.4-5-0.8-6.9-1.7c-1.5-0.7-3.3-1.2-5-1.8c-3.6-1.1-7.3-2.3-7.7-4.2
c-0.8-3.2-0.5-6-0.5-6.1l0-0.3l-0.6-0.1l0,0.3c0,0.1-0.3,3,0.5,6.3c0.5,2.2,4.2,3.4,8.2,4.7c1.7,0.5,3.5,1.1,4.9,1.7
c1.9,0.8,4.4,1.3,7,1.7c3.8,0.6,8,1.3,11.2,3.5c6.1,4.2,8.3,22.7,7.9,25.9c0,0,0,0.1,0,0.1c0.8,3.4,2,6.2,3,8.6
c1.4,3.3,2.5,6.1,2.8,9.5c0.1,1.2,0.1,2.3,0.2,3.3c0.2,4.4,0.4,7.7,2,9.3c2.2,2.2,3.9,7.3,5.3,15.7c1.5,9.6,5.8,38.4,5.8,38.6
c0,0.1-0.2,2.3,3.2,4.6c1.8,1.2,2.8,2.9,3.7,4.5c0.7,1.2,1.3,2.4,2.3,3.2c2.5,2.1,5.4,5.5,5,6.3c-0.2,0.4-0.4,0.6-0.8,0.7
c-0.7,0.2-1.7-0.2-2.9-1.1c-0.9-0.6-2.1-1.9-3-2.8c-1.6-1.7-2-2-2.4-1.8c-0.3,0.2-0.2,0.6-0.1,0.8c0.4,1.1,1.2,4.3,1.9,7.2
c0.4,1.7,0.8,3.2,1,4c0.1,0.5,0.4,1.1,0.6,1.8c0.6,1.5,1.3,3.4,0.8,4.2c-0.1,0.2-0.3,0.3-0.5,0.4c-1.4,0.4-2.5-2.6-3.4-4.9
c-0.1-0.4-0.3-0.8-0.4-1.1l-0.2-0.4c-2-5.2-2.3-5.6-2.8-5.5c-0.3,0.1-0.3,0.5-0.3,0.6c0,1.6,2,10.9,2.3,12.3
c0.2,0.8,0.2,1.5-0.1,1.9c-0.1,0.2-0.4,0.3-0.7,0.4c-0.5,0.1-1.2-0.1-1.8-1.8c-0.7-2-1.4-4.8-1.8-6.5c-0.1-0.3-0.2-0.8-0.3-1.2
c-1.2-5.6-1.5-5.9-1.9-5.8c-0.4,0-0.4,0.5-0.4,0.7c-0.1,0.6,0.1,2,0.2,3.7c0.1,1.2,0.2,2.5,0.3,3.9c0,0.5,0.1,1.1,0.1,1.6
c0.2,2.3,0.3,4.4-0.4,5.2c-0.2,0.2-0.4,0.3-0.7,0.3c-0.7,0-1.2-0.8-1.4-2.5c-0.2-1.6-0.3-2.8-0.4-4.3c0-0.5-0.1-1-0.1-1.6
c-0.7-7.3-1.2-7.5-1.4-7.5c-0.1,0-0.3,0-0.4,0.2c-0.2,0.3-0.4,1.2-0.7,2.6c-0.6,2.6-1.5,6.9-2.6,7.6c-0.1,0.1-0.2,0.1-0.3,0
c-0.5-0.2,0.2-4,0.6-6c0.5-2.6,0.9-5,0.6-6c-0.6-2.2-0.7-8.3,0.2-11c0.2-0.5,0.3-0.9,0.5-1.3c0.7-1.8,0.9-2.5-0.3-4.4
c-1.5-2.3-10-17.9-14.3-30.8c-1.6-4.9-2.2-10.6-2.7-14.7c-0.3-3.3-0.6-5.4-1.1-6.1c-1-1.3-7.1-14.4-8.6-23.3
c-0.1-1.4-0.2-2.8-0.3-4.2c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3c-0.1,1.2,0,2.6,0.3,4.2c0.4,10.7-1.5,23.1-3.1,33.4
l-0.3,1.9c0,0.1-1.3,7.9-1.8,11.5c-0.5,3.4,1,8.5,2.3,13c0.5,1.7,1,3.3,1.2,4.6c1.1,5.1,4.9,22.4,5.3,30.9
c0.7,12-1.8,25.6-3.3,33.8c-0.5,3-0.9,5.2-1,6.3c-0.1,1.8-1,4.8-1.8,7.3c-1.1,3.5-1.7,5.7-1.4,6.6c0.6,1.5,3.6,30.8,3.2,41.3
c-0.2,6-2.2,16.3-3.8,24.7c-1.1,5.6-1.9,9.9-1.8,11.2c0.1,1.2,0.3,2.3,0.6,3.3c0.3,1.2,0.5,2.3,0.3,3c-0.1,0.4-0.3,1-0.5,1.6
l-0.1,0c0,0,0,0,0,0.1c-0.1,0.2-0.1,0.3-0.2,0.5c-0.6,1.5-1.2,3.1-1.2,4.1v0.3h0.6v-0.3c0-0.9,0.6-2.5,1.1-3.9c0,0,0,0,0,0
c0.3,0.4,0.8,1,1.4,1.8c1.9,2.4,2.3,3.4,1.7,4.9c-0.6,1.7-0.9,4.1-1.1,6.1c-0.5-2.5-1.2-4.6-1.2-4.7l-0.6,0.2c0,0,1.7,5,1.7,9.2
c0,4.2-3.5,6.5-9.7,6.5c-6.4,0-6.8-4-7.2-7.3c-0.1-1.2,0-3,0.1-4.8c0.1-1.5,0.2-2.8,0.1-3.7c-0.1-0.9-0.2-2.1-0.4-3.3
c-0.2-1.8-0.5-3.9-0.6-5.7c-0.1-1.6,0.2-3.1,0.5-4.8c0.3-1.6,0.6-3.2,0.6-5.1c0.1-3.2-1.5-13.6-5-31.9c-2.6-13.8,0-32.6,1-39.6
c0.1-1.1,0.3-1.9,0.3-2.4c0.3-2.9-0.7-6.9-1.4-10.2c-0.3-1.2-0.6-2.4-0.7-3.3c-0.6-3.2-1.8-14.5-1.6-21.3c0-0.6-0.3-2.3-1-5.4
c-2-9.4-2.4-25.6-2.4-25.8c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1l-2.3,0l-1.4,0c-0.1,0-0.2,0-0.2,0.1
c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.2-0.3,16.6-2.4,26c-0.3,1.2-0.9,4.6-0.9,5.5c0.1,6.8-1.1,17.9-1.7,21c-0.2,0.9-0.4,2.1-0.7,3.3
c-0.8,3.3-1.7,7.3-1.4,10.2c0.1,0.5,0.2,1.3,0.3,2.4c1,7.1,3.5,25.8,1,39.6c-3.4,18.3-5.1,28.7-5,31.9c0,1.8,0.3,3.5,0.6,5.1
c0.3,1.7,0.6,3.2,0.5,4.8c-0.1,1.8-0.4,3.9-0.6,5.7c-0.2,1.2-0.3,2.4-0.4,3.3c-0.1,0.9,0,2.3,0.1,3.7c0.1,1.7,0.2,3.5,0.1,4.8
c-0.4,3.3-0.8,7.3-7.2,7.3c-6.3,0-9.7-2.3-9.7-6.5c0-4.2,1.7-9.1,1.7-9.2l-0.6-0.2c0,0.1-0.7,2.1-1.2,4.7c-0.1-2.1-0.5-4.4-1.1-6.1
c-0.6-1.5-0.2-2.6,1.7-4.9c0.6-0.7,1.1-1.3,1.4-1.8c0,0,0,0,0,0c0.5,1.4,1.1,3,1.1,3.9v0.3h0.6v-0.3c0-1-0.6-2.6-1.2-4.1
c-0.1-0.2-0.1-0.3-0.2-0.5c0,0,0-0.1,0-0.1l-0.1,0c-0.2-0.6-0.4-1.2-0.5-1.6c-0.2-0.8,0.1-1.8,0.3-3c0.2-1,0.5-2.1,0.6-3.3
c0.1-1.2-0.8-5.6-1.8-11.2c-1.6-8.3-3.5-18.7-3.8-24.7c-0.4-10.5,2.6-39.8,3.2-41.3c0.4-0.9-0.3-3.1-1.4-6.6
c-0.8-2.6-1.7-5.5-1.8-7.3c0-1.1-0.4-3.3-1-6.3c-1.5-8.2-4-21.8-3.3-33.8c0.5-8.5,4.2-25.8,5.3-30.9c0.3-1.3,0.8-2.9,1.2-4.6
c1.3-4.5,2.8-9.6,2.3-13c-0.5-3.6-1.8-11.4-1.8-11.5l-0.3-2c-1.6-10.2-3.5-22.6-3.1-33.4c0.3-1.5,0.4-3,0.3-4.2
c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3c-0.1,1.4-0.2,2.8-0.3,4.2c-1.5,8.9-7.6,22-8.6,23.3c-0.5,0.7-0.8,2.9-1.1,6.1
c-0.4,4.2-1,9.9-2.7,14.7c-4.3,13-12.8,28.5-14.3,30.8c-1.2,1.9-1,2.6-0.3,4.4c0.1,0.4,0.3,0.8,0.5,1.3c0.9,2.7,0.8,8.9,0.2,11
c-0.3,1,0.2,3.4,0.6,6c0.4,2,1,5.7,0.6,6c-0.1,0-0.2,0-0.3,0c-1.1-0.7-2.1-5-2.6-7.6c-0.3-1.5-0.5-2.3-0.7-2.6
c-0.1-0.2-0.3-0.3-0.4-0.2c-0.2,0-0.8,0.1-1.4,7.5c-0.1,0.6-0.1,1.1-0.1,1.6c-0.1,1.5-0.2,2.7-0.4,4.3c-0.2,1.7-0.7,2.5-1.4,2.5
c-0.3,0-0.5-0.1-0.7-0.3c-0.7-0.7-0.5-2.9-0.4-5.2c0-0.5,0.1-1,0.1-1.6c0.1-1.3,0.2-2.7,0.3-3.9c0.1-1.7,0.3-3.1,0.2-3.7
c0-0.2-0.1-0.7-0.4-0.7c-0.4,0-0.7,0.2-1.9,5.8c-0.1,0.5-0.2,0.9-0.3,1.2c-0.4,1.8-1.1,4.5-1.8,6.5c-0.6,1.7-1.3,1.9-1.8,1.8
c-0.3,0-0.5-0.1-0.7-0.4c-0.3-0.4-0.3-1-0.1-1.9c0.4-1.4,2.3-10.7,2.3-12.3c0-0.1,0-0.6-0.3-0.6c-0.5-0.1-0.8,0.3-2.8,5.5l-0.2,0.4
c-0.1,0.3-0.3,0.7-0.4,1.1c-0.8,2.2-1.9,5.3-3.4,4.9c-0.3-0.1-0.4-0.2-0.5-0.4c-0.4-0.8,0.3-2.7,0.8-4.2c0.2-0.6,0.5-1.2,0.6-1.8
c0.2-0.8,0.6-2.3,1-4c0.7-2.9,1.5-6.1,1.9-7.2c0.1-0.2,0.2-0.6-0.1-0.8c-0.4-0.2-0.8,0.2-2.4,1.8c-0.9,1-2.1,2.2-3,2.8
c-1.2,0.9-2.2,1.2-2.9,1.1c-0.3-0.1-0.6-0.3-0.8-0.7c-0.4-0.8,2.5-4.2,5-6.3c1-0.8,1.6-1.9,2.3-3.2c0.9-1.7,1.9-3.4,3.7-4.5
c3.4-2.2,3.2-4.5,3.2-4.5c0-0.3,4.3-29.1,5.8-38.7c1.3-8.4,3.1-13.5,5.3-15.7c1.7-1.7,1.8-4.9,2-9.3c0-1,0.1-2.1,0.2-3.3
c0.2-3.4,1.4-6.3,2.8-9.5c1-2.4,2.2-5.2,3-8.6c0,0,0-0.1,0-0.1c-0.4-3.3,1.7-21.7,7.9-25.9c3.2-2.2,7.4-2.9,11.2-3.5
c2.6-0.4,5.1-0.9,7-1.7c1.4-0.6,3.2-1.2,4.9-1.7c3.9-1.2,7.6-2.4,8.2-4.7c0.8-3.3,0.5-6.2,0.5-6.3l0-0.3l-0.6,0.1l0,0.3
c0,0,0.3,2.9-0.5,6.1c-0.5,1.9-4.2,3.1-7.7,4.2c-1.7,0.5-3.5,1.1-5,1.8c-1.8,0.8-4.3,1.2-6.9,1.7c-3.8,0.6-8.2,1.4-11.5,3.6
c-6.3,4.3-8.5,23-8.1,26.5c-0.8,3.3-1.9,5.9-3,8.5c-1.4,3.3-2.6,6.2-2.8,9.7c-0.1,1.2-0.1,2.3-0.2,3.3c-0.2,4.3-0.3,7.4-1.8,8.9
c-2.3,2.3-4.1,7.4-5.4,16c-1.5,9.6-5.8,38.4-5.8,38.8c0,0,0.1,2-3,4c-2,1.3-3,3.1-3.9,4.8c-0.7,1.2-1.3,2.3-2.1,3
c-1.6,1.3-5.9,5.5-5.1,7.1c0.3,0.5,0.7,0.9,1.2,1c0.9,0.2,2.1-0.2,3.4-1.2c0.9-0.7,2.1-1.9,3.1-2.9c0.4-0.5,0.9-1,1.3-1.3
c-0.4,1.4-1.1,4.3-1.8,6.9c-0.4,1.7-0.8,3.2-1,4c-0.1,0.5-0.4,1.1-0.6,1.7c-0.6,1.7-1.4,3.6-0.8,4.7c0.2,0.4,0.5,0.6,0.9,0.7
c2,0.6,3.2-2.7,4.1-5.3c0.1-0.4,0.3-0.8,0.4-1.1l0.2-0.4c0.5-1.2,1.3-3.3,1.8-4.5c-0.3,2.6-1.9,10-2.3,11.4c-0.3,1-0.2,1.9,0.2,2.4
c0.3,0.4,0.6,0.6,1.1,0.6c1.1,0.1,1.9-0.6,2.5-2.2c0.7-2,1.4-4.8,1.8-6.6c0.1-0.3,0.2-0.8,0.3-1.2c0.3-1.2,0.7-3.3,1.1-4.5
c0,0.7-0.1,1.7-0.2,2.9c-0.1,1.2-0.2,2.5-0.3,3.9c0,0.5-0.1,1.1-0.1,1.6c-0.2,2.5-0.3,4.7,0.5,5.6c0.3,0.3,0.7,0.5,1.2,0.5
c1.6,0,1.9-2.2,2-3.1c0.2-1.6,0.3-2.8,0.5-4.3c0-0.5,0.1-1,0.1-1.6c0.3-3.2,0.6-5.3,0.9-6.4c0.1,0.5,0.3,1.1,0.4,1.8
c0.8,3.8,1.7,7.3,2.9,8c0.3,0.2,0.6,0.2,0.8,0.1c1-0.3,0.6-2.6-0.1-6.7c-0.4-2.4-0.9-4.8-0.6-5.7c0.6-2.2,0.8-8.5-0.2-11.4
c-0.2-0.5-0.3-0.9-0.5-1.3c-0.7-1.8-0.8-2.2,0.2-3.8c1.5-2.4,10-18,14.4-31c1.6-4.9,2.2-10.7,2.7-14.9c0.3-2.8,0.5-5.2,1-5.8
c0.9-1.2,5.8-11.7,8-20.4c0,10,1.7,20.9,3.1,30.1l0.3,2c0,0.1,1.3,7.9,1.8,11.5c0.4,3.2-1.1,8.5-2.3,12.7c-0.5,1.7-1,3.3-1.3,4.6
c-1.1,5.1-4.9,22.5-5.4,31c-0.7,12,1.8,25.7,3.3,33.9c0.5,3,0.9,5.2,1,6.2c0.1,1.9,1,4.7,1.8,7.5c0.8,2.7,1.7,5.4,1.4,6.2
c-0.7,1.9-3.6,31.7-3.2,41.6c0.3,6.1,2.2,16.4,3.8,24.7c1,5.3,1.9,9.9,1.8,11c-0.1,1.1-0.3,2.2-0.5,3.2c-0.3,1.3-0.6,2.4-0.3,3.3
c0.1,0.4,0.3,0.9,0.5,1.5c-0.2,0.3-0.6,0.9-1.6,2.1c-2.1,2.6-2.5,3.7-1.8,5.5c1.4,3.8,1.2,11,1.2,11.1l0,0c0.3,4.2,4,6.7,10.4,6.7
c6.9,0,7.5-4.5,7.8-7.9c0.1-1.3,0-3.1-0.1-4.9c-0.1-1.4-0.2-2.8-0.1-3.6c0.1-0.9,0.2-2.1,0.4-3.3c0.2-1.8,0.5-3.9,0.6-5.8
c0.1-1.6-0.2-3.2-0.5-4.9c-0.3-1.6-0.5-3.2-0.6-5c-0.1-3.1,1.6-13.8,5-31.8c2.6-13.9,0-32.7-1-39.8c-0.1-1.1-0.3-1.9-0.3-2.4
c-0.3-2.8,0.7-6.8,1.4-10c0.3-1.2,0.6-2.4,0.7-3.3c0.6-3.1,1.8-14.3,1.7-21.1c0-0.8,0.6-3.9,0.9-5.4c1.9-8.7,2.4-23.4,2.4-25.8
l1.1,0l0-0.3v0.3l2,0c0.1,2.3,0.5,16.8,2.4,25.6c0.8,3.7,1,4.9,1,5.3c-0.1,6.8,1.1,18.2,1.7,21.4c0.2,0.9,0.4,2.1,0.7,3.3
c0.8,3.2,1.7,7.2,1.4,10c-0.1,0.5-0.2,1.3-0.3,2.4c-1,7.1-3.6,25.9-1,39.8c3.4,18,5,28.7,5,31.8c0,1.8-0.3,3.4-0.6,5
c-0.3,1.7-0.6,3.3-0.5,4.9c0.1,1.9,0.4,3.9,0.6,5.8c0.2,1.2,0.3,2.4,0.4,3.3c0.1,0.9,0,2.2-0.1,3.6c-0.1,1.8-0.3,3.6-0.1,4.9
c0.4,3.3,0.9,7.9,7.8,7.9c6.4,0,10.1-2.4,10.4-6.7l0,0c0-0.1-0.3-7.2,1.2-11.1c0.7-1.8,0.3-3-1.8-5.5c-1-1.3-1.4-1.9-1.6-2.1
c0.2-0.6,0.4-1.1,0.5-1.5c0.2-0.9,0-2-0.3-3.3c-0.2-1-0.5-2.1-0.5-3.2c-0.1-1.1,0.8-5.7,1.8-11c1.6-8.3,3.5-18.7,3.8-24.7
c0.4-9.9-2.5-39.7-3.2-41.6c-0.3-0.8,0.6-3.5,1.4-6.2c0.9-2.7,1.7-5.6,1.8-7.5c0-1,0.4-3.2,1-6.2c1.5-8.2,4-21.9,3.3-33.9
c-0.5-8.5-4.2-25.9-5.4-31c-0.3-1.3-0.8-2.9-1.3-4.6c-1.2-4.2-2.7-9.5-2.3-12.7c0.5-3.6,1.8-11.4,1.8-11.5l0.3-1.9
c1.4-9.2,3.1-20.2,3.1-30.1c2.2,8.7,7.1,19.2,8,20.4c0.4,0.6,0.7,3,1,5.8c0.4,4.2,1,9.9,2.7,14.9c4.3,13,12.9,28.6,14.4,31
c1,1.6,0.9,2.1,0.2,3.8c-0.1,0.4-0.3,0.8-0.5,1.3c-1,2.9-0.8,9.2-0.2,11.4c0.2,0.9-0.2,3.3-0.6,5.7c-0.7,4.1-1.1,6.4-0.1,6.7
c0.3,0.1,0.6,0.1,0.8-0.1c1.3-0.7,2.1-4.2,2.9-8c0.1-0.7,0.3-1.3,0.4-1.8c0.2,1,0.6,3.2,0.9,6.4c0.1,0.6,0.1,1.1,0.1,1.6
c0.1,1.5,0.2,2.7,0.4,4.3c0.1,0.9,0.4,3.1,2,3.1c0.5,0,0.9-0.2,1.2-0.5c0.9-0.9,0.7-3.1,0.5-5.6c0-0.5-0.1-1-0.1-1.6
c-0.1-1.3-0.2-2.7-0.3-3.9c-0.1-1.1-0.2-2.2-0.2-2.9c0.3,1.2,0.8,3.3,1.1,4.5c0.1,0.5,0.2,0.9,0.3,1.2c0.4,1.8,1.2,4.6,1.8,6.6
c0.6,1.6,1.3,2.3,2.5,2.2c0.5,0,0.9-0.3,1.1-0.6c0.4-0.5,0.4-1.4,0.2-2.4c-0.4-1.5-1.9-8.9-2.3-11.4c0.5,1.1,1.4,3.3,1.8,4.5
l0.2,0.4c0.1,0.3,0.3,0.7,0.4,1.1c0.9,2.6,2.1,5.9,4.1,5.3c0.4-0.1,0.7-0.4,0.9-0.7c0.5-1.1-0.2-3-0.8-4.7
c-0.2-0.6-0.5-1.2-0.6-1.7c-0.2-0.8-0.6-2.3-1-4c-0.6-2.6-1.4-5.5-1.8-6.9c0.4,0.3,0.9,0.9,1.3,1.3c1,1,2.2,2.2,3.1,2.9
c1.4,1,2.5,1.4,3.4,1.2c0.5-0.1,0.9-0.5,1.2-1C505.8,288.9,501.6,284.7,499.9,283.3z"
      />
      <path d="M369.4,224c-0.1,0-0.2,0-0.2,0c-1.2-0.2-3.7-3-3.9-3.3l0.5-0.4c1,1.1,2.8,3,3.6,3.1c0.8,0.1,3.5-1,5.1-1.7l0.3,0.6C374.2,222.5,370.8,224,369.4,224z" />
      <path d="M471.7,224c-1.4,0-4.8-1.5-5.2-1.7l0.3-0.6c1.6,0.7,4.3,1.8,5.1,1.7c0.7-0.1,2.6-2,3.6-3.1l0.5,0.4c-0.3,0.3-2.7,3.1-3.9,3.3C471.9,224,471.8,224,471.7,224z" />
      <path
        d="M441.7,121.4c-0.1-0.5-0.4-0.8-0.7-1c-0.6-0.3-1.2-0.4-1.8-0.3c0.2-1.4,0.4-3.3,0.5-5.5
c0.2-5.8-4.5-13.1-8.7-15.6c-4.3-2.5-7.7-2.9-10.4-2.9c-2.8,0-6,0.3-10.2,2.9c-4.2,2.5-8.9,9.8-8.7,15.6c0.1,2.3,0.3,4.1,0.5,5.5
c-0.6-0.1-1.2-0.1-1.8,0.3c-0.3,0.2-0.6,0.5-0.7,1c-0.4,1.4-0.1,4.2,1.3,6.7c0.6,1.1,1.1,2.2,1.4,3.1c0.6,1.5,1.1,2.6,1.9,2.6
c0.3,0,0.5,0,0.6-0.1c0.4,2.3,1.5,6.4,5.6,10.3l0.4-0.5c-4.8-4.6-5.4-9.6-5.5-11.3c-0.2-1.6-1.9-8.7-2-9c0,0-0.9-3.1-1.1-8.6
c-0.2-5.5,4.4-12.7,8.4-15c4.1-2.4,7.2-2.8,9.9-2.8c2.7,0,6,0.3,10.1,2.8c3.9,2.3,8.6,9.5,8.4,15c-0.2,5.5-1.1,8.6-1.1,8.7
c-0.1,0.3-1.8,7.3-2,9c-0.2,1.6-0.7,6.6-5.5,11.2l0.4,0.5c4-3.9,5.2-8,5.6-10.3c0.2,0.1,0.4,0.1,0.6,0.1c0.9,0,1.3-1,1.9-2.6
c0.4-0.9,0.8-1.9,1.4-3.1C441.7,125.6,442.1,122.8,441.7,121.4z M404.6,132.3c0,0.2,0,0.4,0.1,0.7c-0.1,0.1-0.3,0.1-0.6,0.1
c-0.5,0-0.8-1-1.4-2.2c-0.4-0.9-0.8-2-1.5-3.1c-1.3-2.2-1.6-4.9-1.3-6.2c0.1-0.3,0.2-0.5,0.4-0.6c0.5-0.3,1.1-0.3,1.6-0.1
c0,0,0,0,0.1,0c0.3,1.6,0.5,2.5,0.5,2.6C402.7,123.5,404.5,130.7,404.6,132.3z M439.8,127.8c-0.6,1.1-1.1,2.2-1.5,3.1
c-0.5,1.2-0.9,2.2-1.4,2.2c-0.2,0-0.4,0-0.6-0.1c0-0.3,0.1-0.5,0.1-0.7c0.2-1.6,2-8.8,2-8.9c0-0.1,0.3-1,0.5-2.6c0,0,0,0,0,0
c0.5-0.2,1-0.1,1.6,0.1c0.2,0.1,0.3,0.3,0.4,0.6C441.4,122.9,441,125.5,439.8,127.8z"
      />
      <path
        d="M247.8,283.3c-0.9-0.7-1.5-1.8-2.1-3c-0.9-1.6-1.9-3.5-3.9-4.8c-3.1-2-3-3.9-3-4c0-0.3-4.3-29.1-5.8-38.7
c-1.4-8.6-3.1-13.7-5.4-16c-1.5-1.5-1.6-4.6-1.8-8.9c0-1-0.1-2.1-0.2-3.3c-0.2-3.6-1.4-6.4-2.8-9.7c-1.1-2.5-2.2-5.2-3-8.5
c0.4-3.5-1.9-22.2-8.1-26.5c-3.3-2.3-7.6-3-11.5-3.6c-2.6-0.4-5-0.8-6.9-1.7c-1.5-0.7-3.3-1.2-5-1.8c-3.6-1.1-7.3-2.3-7.7-4.2
c-0.7-2.9-0.5-5.8-0.5-6.3c3.7-4.3,4.1-8.6,4.3-10.1c0.2-1.6,2-8.8,2-8.9c0-0.1,0.9-3.2,1.1-8.8c0.2-5.8-4.5-13.1-8.7-15.6
c-4.3-2.5-7.7-2.9-10.4-2.9c-2.8,0-6,0.3-10.2,2.9c-4.2,2.5-8.9,9.8-8.7,15.6c0.2,5.6,1.1,8.7,1.1,8.8c0,0.1,1.8,7.3,2,8.9
c0.2,1.5,0.7,5.8,4.3,10.1c0,0.5,0.2,3.4-0.5,6.3c-0.5,1.9-4.2,3.1-7.7,4.2c-1.7,0.5-3.5,1.1-5,1.8c-1.8,0.8-4.3,1.2-6.9,1.7
c-3.8,0.6-8.2,1.4-11.5,3.6c-6.3,4.3-8.5,23-8.1,26.5c-0.8,3.3-1.9,5.9-3,8.5c-1.4,3.3-2.6,6.2-2.8,9.7c-0.1,1.2-0.1,2.3-0.2,3.3
c-0.2,4.3-0.3,7.4-1.8,8.9c-2.3,2.3-4.1,7.4-5.4,16c-1.5,9.6-5.8,38.4-5.8,38.8c0,0,0.1,2-3,4c-2,1.3-3,3.1-3.9,4.8
c-0.7,1.2-1.3,2.3-2.1,3c-1.6,1.3-5.9,5.5-5.1,7.1c0.3,0.5,0.7,0.9,1.2,1c0.9,0.2,2.1-0.2,3.4-1.2c0.9-0.7,2.1-1.9,3.1-2.9
c0.4-0.5,0.9-1,1.3-1.3c-0.4,1.4-1.1,4.3-1.8,6.9c-0.4,1.7-0.8,3.2-1,4c-0.1,0.5-0.4,1.1-0.6,1.7c-0.6,1.7-1.4,3.6-0.8,4.7
c0.2,0.4,0.5,0.6,0.9,0.7c2,0.6,3.2-2.7,4.1-5.3c0.1-0.4,0.3-0.8,0.4-1.1l0.2-0.4c0.5-1.2,1.3-3.3,1.8-4.5
c-0.3,2.6-1.9,10-2.3,11.4c-0.3,1-0.2,1.9,0.2,2.4c0.3,0.4,0.6,0.6,1.1,0.6c1.1,0.1,1.9-0.6,2.5-2.2c0.7-2,1.4-4.9,1.8-6.6
c0.1-0.3,0.2-0.8,0.3-1.2c0.3-1.2,0.7-3.3,1.1-4.5c0,0.7-0.1,1.8-0.2,2.9c-0.1,1.2-0.2,2.5-0.3,3.9c0,0.5-0.1,1.1-0.1,1.6
c-0.2,2.5-0.3,4.7,0.5,5.6c0.3,0.3,0.7,0.5,1.2,0.5c1.4,0,1.8-1.6,2-3.1c0.2-1.6,0.3-2.8,0.4-4.3c0-0.5,0.1-1,0.1-1.6
c0.3-3.2,0.6-5.3,0.9-6.4c0.1,0.5,0.3,1.1,0.4,1.8c0.8,3.8,1.7,7.3,2.9,8c0.3,0.2,0.6,0.2,0.8,0.1c1-0.3,0.6-2.6-0.1-6.7
c-0.4-2.4-0.9-4.8-0.6-5.7c0.6-2.2,0.8-8.5-0.2-11.4c-0.2-0.5-0.3-0.9-0.5-1.3c-0.7-1.8-0.8-2.2,0.2-3.8c1.5-2.4,10-17.9,14.4-31
c1.6-4.9,2.2-10.7,2.7-14.9c0.3-2.8,0.5-5.2,1-5.8c0.9-1.2,5.8-11.7,8-20.4c0,10,1.7,20.9,3.1,30.1l0.3,2c0,0.1,1.3,7.9,1.8,11.5
c0.4,3.2-1.1,8.5-2.3,12.7c-0.5,1.7-1,3.3-1.3,4.6c-1.1,5.1-4.9,22.5-5.4,31c-0.7,12,1.8,25.7,3.3,33.9c0.5,3,0.9,5.2,1,6.2
c0.1,1.9,1,4.7,1.8,7.5c0.8,2.7,1.7,5.4,1.4,6.2c-0.7,1.9-3.6,31.7-3.2,41.6c0.3,6.4,2.4,17.8,4,26.1c0.9,4.8,1.6,8.6,1.6,9.7
c0,0.6,0,1.2,0,1.8c0.1,2.3,0.1,4.7-0.5,6.4c-1.1,2.9-3.7,7.1-5.4,8.7c-0.3,0.3-0.7,0.7-1.1,1.2c-0.7,0.9-1.6,2-2.5,2.6
c-0.2,0.1-0.4,0.2-0.6,0.4c-1.4,0.9-3.3,2.1-3.3,3.3c0,0.3,0.1,0.7,0.5,1c0.7,0.6,2,0.5,3.1,0c-0.2,0.3-0.2,0.7-0.2,1
c0.1,0.4,0.5,0.8,1.2,1.1c0.5,0.2,1.2,0.2,1.9-0.2c0.2-0.1,0.3-0.2,0.5-0.2c-0.2,0.5-0.3,0.8-0.1,1.2c0.1,0.4,0.5,0.6,1.1,0.9
c0.7,0.3,1.4,0.1,2.1-0.3c0,0.1,0,0.2,0,0.4c0,0.5,0.3,0.8,0.8,1.1c1.2,0.7,2.3,0.4,3.1-0.2c0,0.3,0,0.6,0.1,1
c0.2,0.7,0.9,1.3,1.9,1.6c0.5,0.2,1,0.2,1.4,0.2c1.7,0,2.9-1,3.9-1.8c0.5-0.4,1-0.8,1.4-0.9c1.7-0.4,3.1-4.4,3.3-7
c0.4-3.4,0.9-6.8,2.7-8.2c2-1.6,3-6.4,3.1-9.3c0.1-1.6-0.7-2.6-1.4-3.8c-0.8-1.1-1.6-2.3-1.6-4.1c-0.1-3.1,1.6-13.8,5-31.8
c2.6-13.9,0-32.7-1-39.8c-0.1-1.1-0.3-1.9-0.3-2.4c-0.3-2.8,0.7-6.8,1.4-10c0.3-1.2,0.6-2.4,0.7-3.3c0.6-3.2,2.1-16.9,1.6-23.4
c2.9-5.3,3.4-32.4,3.4-35.9l0.7,0v0l0.3,0l0.1,0l0,0l2,0c0.1,3.5,0.5,30.5,3.4,35.9c-0.5,6.5,1,20.2,1.6,23.4
c0.2,0.9,0.4,2.1,0.7,3.3c0.8,3.2,1.7,7.2,1.4,10c-0.1,0.5-0.2,1.3-0.3,2.4c-1,7.1-3.6,25.9-1,39.8c3.4,18,5,28.7,5,31.8
c0,1.9-0.9,3-1.6,4.1c-0.8,1.1-1.5,2.2-1.4,3.8c0.1,2.9,1.1,7.7,3.1,9.3c1.7,1.3,2.3,4.7,2.7,8.2c0.3,2.6,1.6,6.6,3.3,7
c0.4,0.1,0.8,0.5,1.4,0.9c1,0.8,2.2,1.8,3.9,1.8c0.5,0,0.9-0.1,1.4-0.2c1-0.3,1.6-0.9,1.9-1.6c0.1-0.3,0.1-0.7,0.1-1
c0.8,0.5,1.9,0.8,3.1,0.2c0.5-0.3,0.8-0.7,0.8-1.1c0-0.1,0-0.2,0-0.4c0.6,0.3,1.4,0.5,2.1,0.3c0.6-0.2,0.9-0.5,1.1-0.9
c0.1-0.4,0.1-0.7-0.1-1.2c0.2,0.1,0.3,0.2,0.5,0.2c0.7,0.3,1.4,0.4,1.9,0.2c0.7-0.3,1.1-0.7,1.2-1.1c0.1-0.3,0-0.6-0.2-1
c1.1,0.4,2.4,0.5,3.1,0c0.4-0.4,0.5-0.8,0.5-1c-0.1-1.2-2-2.4-3.3-3.3c-0.2-0.1-0.4-0.3-0.6-0.4c-0.9-0.6-1.8-1.7-2.5-2.6
c-0.4-0.5-0.8-1-1.1-1.2c-1.8-1.6-4.4-5.7-5.4-8.7c-0.6-1.7-0.5-4.1-0.5-6.4c0-0.6,0-1.2,0-1.8c0-1,0.7-4.9,1.6-9.7
c1.6-8.3,3.7-19.7,4-26.1c0.4-9.9-2.5-39.7-3.2-41.6c-0.3-0.8,0.6-3.5,1.4-6.2c0.9-2.7,1.7-5.6,1.8-7.5c0-1,0.4-3.2,1-6.2
c1.5-8.2,4-21.9,3.3-33.9c-0.5-8.5-4.2-25.9-5.4-31c-0.3-1.3-0.8-2.9-1.3-4.6c-1.2-4.2-2.7-9.5-2.3-12.7
c0.5-3.6,1.8-11.4,1.8-11.5l0.3-2c1.4-9.2,3.1-20.2,3.1-30.1c2.2,8.7,7.1,19.2,8,20.4c0.4,0.6,0.7,3,1,5.8c0.4,4.2,1,9.9,2.7,14.9
c4.3,13,12.9,28.6,14.4,31c1,1.6,0.9,2.1,0.2,3.8c-0.1,0.4-0.3,0.8-0.5,1.3c-1,2.9-0.8,9.2-0.2,11.4c0.2,0.9-0.2,3.3-0.6,5.7
c-0.7,4.1-1.1,6.4-0.1,6.7c0.3,0.1,0.6,0.1,0.8-0.1c1.3-0.7,2.1-4.2,2.9-8c0.1-0.7,0.3-1.3,0.4-1.8c0.2,1,0.6,3.2,0.9,6.4
c0.1,0.6,0.1,1.1,0.1,1.6c0.1,1.5,0.2,2.7,0.4,4.3c0.2,1.4,0.6,3.1,2,3.1c0.5,0,0.9-0.2,1.2-0.5c0.9-0.9,0.7-3.1,0.5-5.6
c0-0.5-0.1-1-0.1-1.6c-0.1-1.3-0.2-2.7-0.3-3.9c-0.1-1.2-0.2-2.2-0.2-2.9c0.4,1.2,0.8,3.3,1.1,4.5c0.1,0.5,0.2,0.9,0.3,1.2
c0.4,1.8,1.2,4.6,1.8,6.6c0.6,1.6,1.4,2.3,2.5,2.2c0.5,0,0.9-0.3,1.1-0.6c0.4-0.5,0.4-1.4,0.2-2.4c-0.4-1.5-1.9-8.9-2.3-11.4
c0.5,1.1,1.4,3.3,1.8,4.5l0.2,0.4c0.1,0.3,0.3,0.7,0.4,1.1c0.9,2.6,2.1,5.9,4.1,5.3c0.4-0.1,0.7-0.4,0.9-0.7
c0.5-1.1-0.2-3-0.8-4.7c-0.2-0.6-0.5-1.2-0.6-1.7c-0.2-0.8-0.6-2.3-1-4c-0.6-2.6-1.4-5.5-1.8-6.9c0.4,0.3,0.9,0.9,1.3,1.3
c1,1,2.2,2.2,3.1,2.9c1.3,1,2.5,1.4,3.4,1.2c0.5-0.1,0.9-0.5,1.2-1C253.8,288.9,249.5,284.7,247.8,283.3z M151.2,123.2
c0,0-0.9-3.1-1.1-8.6c-0.2-5.5,4.4-12.7,8.4-15c4.1-2.4,7.2-2.8,9.9-2.8c2.7,0,6,0.3,10.1,2.8c3.9,2.3,8.6,9.5,8.4,15
c-0.2,5.5-1.1,8.6-1.1,8.7c-0.1,0.3-1.8,7.3-2,9c-0.2,1.6-0.7,6.6-5.5,11.2c-4.6,4.5-6.7,4.5-8.2,4.5l-3,0l-0.2,0
c-1.4,0-3.5,0-8.2-4.5c-4.8-4.6-5.4-9.6-5.5-11.3C153,130.6,151.3,123.5,151.2,123.2z M252.4,290.1c-0.2,0.4-0.4,0.6-0.8,0.7
c-0.7,0.2-1.7-0.2-2.9-1.1c-0.9-0.6-2.1-1.9-3-2.8c-1.6-1.7-2-2-2.4-1.8c-0.3,0.2-0.2,0.6-0.1,0.8c0.4,1.1,1.2,4.3,1.9,7.2
c0.4,1.7,0.8,3.2,1,4c0.1,0.5,0.4,1.1,0.6,1.8c0.6,1.5,1.3,3.4,0.8,4.2c-0.1,0.2-0.3,0.3-0.5,0.4c-1.4,0.4-2.5-2.6-3.4-4.9
c-0.1-0.4-0.3-0.8-0.4-1.1l-0.2-0.4c-2-5.2-2.3-5.6-2.8-5.5c-0.3,0.1-0.3,0.5-0.3,0.6c0,1.6,2,10.9,2.3,12.3
c0.2,0.8,0.2,1.5-0.1,1.9c-0.1,0.2-0.4,0.3-0.7,0.4c-0.5,0.1-1.2-0.1-1.8-1.8c-0.7-2-1.4-4.8-1.8-6.5c-0.1-0.3-0.2-0.8-0.3-1.2
c-1.2-5.6-1.4-5.9-1.9-5.8c-0.4,0-0.4,0.5-0.4,0.7c-0.1,0.6,0.1,2,0.2,3.7c0.1,1.2,0.2,2.5,0.3,3.9c0,0.5,0.1,1.1,0.1,1.6
c0.2,2.3,0.3,4.4-0.4,5.2c-0.2,0.2-0.4,0.3-0.7,0.3c-0.7,0-1.2-0.8-1.4-2.5c-0.2-1.6-0.3-2.8-0.4-4.3c0-0.5-0.1-1-0.1-1.6
c-0.7-7.3-1.2-7.5-1.4-7.5c-0.1,0-0.3,0-0.4,0.2c-0.2,0.3-0.4,1.2-0.7,2.6c-0.6,2.6-1.5,7-2.6,7.6c-0.1,0.1-0.2,0.1-0.3,0
c-0.5-0.2,0.2-4,0.6-6c0.5-2.6,0.9-5,0.6-6c-0.6-2.2-0.7-8.3,0.2-11c0.2-0.5,0.3-0.9,0.5-1.3c0.7-1.8,0.9-2.5-0.3-4.4
c-1.5-2.3-10-17.9-14.3-30.8c-1.6-4.9-2.2-10.6-2.7-14.7c-0.3-3.3-0.6-5.4-1.1-6.1c-1-1.3-7.1-14.4-8.6-23.3
c-0.1-1.4-0.2-2.8-0.3-4.2c0-0.2-0.2-0.3-0.3-0.3c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.3,0.3c-0.1,1.2,0,2.6,0.3,4.2
c0.4,10.7-1.5,23.1-3.1,33.3l-0.3,2c0,0.1-1.3,7.9-1.8,11.5c-0.5,3.4,1,8.5,2.3,13c0.5,1.7,1,3.3,1.2,4.6
c1.1,5.1,4.9,22.4,5.3,30.9c0.7,12-1.8,25.6-3.3,33.8c-0.5,3-0.9,5.2-1,6.3c-0.1,1.8-1,4.8-1.8,7.3c-1.1,3.5-1.7,5.7-1.4,6.6
c0.6,1.5,3.6,30.8,3.2,41.3c-0.3,6.3-2.4,17.7-4,26c-1,5.1-1.6,8.7-1.7,9.8c0,0.6,0,1.2,0,1.7c-0.1,2.4-0.1,4.8,0.5,6.7
c1.1,3.1,3.7,7.2,5.6,8.9c0.3,0.2,0.6,0.7,1,1.2c0.8,0.9,1.7,2.1,2.6,2.7c0.2,0.1,0.4,0.2,0.6,0.4c1.1,0.7,3,1.9,3,2.8
c0,0.1,0,0.3-0.3,0.5c-0.7,0.6-2.7,0.1-3.7-0.8c-1-0.9-1.9-1.8-1.9-1.8l-0.5,0.4c0,0,0.9,0.9,1.9,1.8c0.6,0.7,1.1,1.3,1,1.7
c-0.1,0.2-0.3,0.5-0.8,0.7c-1,0.4-2.5-0.8-2.8-1.1c-1-1.7-2.6-2.8-2.6-2.8l-0.4,0.5c0,0,1.4,1,2.3,2.5c0,0,0,0,0,0h0l0.2,0.3
c0.4,0.8,0.7,1.4,0.6,1.7c-0.1,0.2-0.3,0.3-0.7,0.5c-0.9,0.4-2-0.5-2.6-0.9c-0.2-0.2-0.3-0.3-0.4-0.3c-1.1-1.2-3.5-3.3-3.6-3.4
l-0.4,0.5c0,0,2.5,2.2,3.5,3.4l0,0c0,0,0.1,0.2,0.4,0.3c0.3,0.3,0.6,0.8,0.6,1.2c0,0.2-0.2,0.4-0.5,0.6c-1.7,1-3.2-0.8-3.2-0.9
c0,0,0,0,0,0l0,0c0,0-0.9-1.1-1.8-2.1c-0.9-1-1.9-2.1-1.9-2.1l-0.5,0.4c0,0,1,1.1,1.9,2.1c0.9,1,1.7,2,1.8,2.1
c0,0,0.6,0.9,0.3,1.7c-0.2,0.5-0.7,0.9-1.5,1.2c-2.1,0.7-3.5-0.5-4.7-1.4c-0.6-0.5-1.1-0.9-1.6-1c-1.1-0.3-2.5-3.4-2.8-6.4
c-0.4-3.3-0.9-7-2.9-8.6c-1.7-1.3-2.7-5.5-2.9-8.8c-0.1-1.4,0.6-2.3,1.3-3.4c0.8-1.1,1.7-2.4,1.8-4.5c0.1-3.2-1.5-13.6-5-31.9
c-2.6-13.8,0-32.6,1-39.6c0.1-1.1,0.3-1.9,0.3-2.4c0.3-2.9-0.7-6.9-1.4-10.2c-0.3-1.2-0.6-2.4-0.7-3.3c-0.6-3.2-2.1-16.9-1.6-23.3
c0-0.1,0-0.1,0-0.2c-3-5.2-3.4-35.7-3.4-36c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.1-0.1-0.2-0.1l-2.5,0l-1.3,0c-0.1,0-0.2,0-0.2,0.1
c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.3-0.4,30.8-3.4,36c0,0.1,0,0.1,0,0.2c0.5,6.4-1,20.2-1.6,23.3c-0.2,0.9-0.4,2-0.7,3.3
c-0.8,3.3-1.7,7.3-1.4,10.2c0.1,0.5,0.2,1.3,0.3,2.4c1,7.1,3.5,25.8,1,39.6c-3.4,18.3-5.1,28.7-5,31.9c0.1,2.1,1,3.4,1.8,4.5
c0.7,1.1,1.4,2,1.3,3.4c-0.2,3.3-1.2,7.5-2.9,8.8c-2,1.6-2.6,5.3-2.9,8.6c-0.3,3-1.7,6.2-2.8,6.4c-0.5,0.1-1,0.5-1.6,1
c-1.2,0.9-2.7,2.1-4.7,1.4c-0.8-0.3-1.3-0.7-1.5-1.2c-0.3-0.8,0.3-1.6,0.3-1.7c0.1-0.1,0.9-1.1,1.8-2.1c0.9-1,1.9-2.1,1.9-2.1
l-0.5-0.4c0,0-1,1.1-1.9,2.1c-0.9,1-1.8,2.1-1.8,2.1l0,0c0,0,0,0,0,0c-0.1,0.1-1.5,1.9-3.2,0.9c-0.3-0.2-0.5-0.4-0.5-0.6
c0-0.4,0.3-0.8,0.6-1.2c0.2-0.2,0.3-0.3,0.4-0.3l0,0c1.1-1.2,3.5-3.4,3.5-3.4l-0.4-0.5c-0.1,0.1-2.5,2.2-3.6,3.4
c-0.1,0-0.2,0.2-0.4,0.3c-0.5,0.5-1.6,1.3-2.6,0.9c-0.4-0.2-0.6-0.3-0.7-0.5c-0.1-0.3,0.2-0.9,0.6-1.7l0.2-0.3h0c0,0,0,0,0,0
c0.9-1.5,2.3-2.5,2.3-2.5l-0.4-0.5c-0.1,0-1.6,1.1-2.6,2.8c-0.3,0.3-1.8,1.6-2.8,1.2c-0.5-0.2-0.8-0.4-0.8-0.7
c-0.1-0.4,0.4-1.1,1-1.7c1-0.9,1.9-1.8,1.9-1.8L134,446c0,0-0.9,0.9-1.9,1.8c-1,0.9-3,1.4-3.7,0.8c-0.3-0.2-0.3-0.4-0.3-0.5
c0-0.9,1.9-2.1,3-2.8c0.2-0.1,0.4-0.3,0.6-0.4c0.9-0.6,1.9-1.8,2.6-2.7c0.4-0.5,0.8-0.9,1-1.2c1.9-1.7,4.5-5.8,5.6-8.9
c0.7-1.8,0.6-4.3,0.5-6.7c0-0.6,0-1.2,0-1.7c0-1.1-0.7-4.8-1.7-9.8c-1.6-8.3-3.7-19.6-4-26c-0.4-10.5,2.6-39.8,3.2-41.3
c0.4-0.9-0.3-3.1-1.4-6.6c-0.8-2.6-1.7-5.5-1.8-7.3c0-1.1-0.4-3.3-1-6.3c-1.5-8.2-4-21.8-3.3-33.8c0.5-8.5,4.2-25.8,5.3-30.9
c0.3-1.3,0.8-2.9,1.2-4.6c1.3-4.5,2.8-9.6,2.3-13c-0.5-3.6-1.8-11.4-1.8-11.5l-0.3-2c-1.6-10.2-3.5-22.6-3.1-33.3
c0.3-1.5,0.4-3,0.3-4.2c0-0.2-0.1-0.3-0.3-0.3c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.3,0.3c-0.1,1.4-0.2,2.8-0.3,4.2
c-1.5,8.9-7.6,22-8.6,23.3c-0.5,0.7-0.8,2.9-1.1,6.1c-0.4,4.2-1,9.9-2.7,14.7c-4.3,13-12.8,28.5-14.3,30.8c-1.2,1.9-1,2.6-0.3,4.4
c0.1,0.4,0.3,0.8,0.5,1.3c0.9,2.7,0.8,8.9,0.2,11c-0.3,1,0.2,3.4,0.6,6c0.4,2,1,5.7,0.6,6c-0.1,0-0.2,0-0.3,0
c-1.1-0.7-2.1-5-2.6-7.6c-0.3-1.5-0.5-2.3-0.7-2.6c-0.1-0.2-0.3-0.3-0.4-0.2c-0.2,0-0.8,0.1-1.4,7.5c-0.1,0.6-0.1,1.1-0.1,1.6
c-0.1,1.5-0.2,2.7-0.4,4.3c-0.2,1.7-0.7,2.5-1.4,2.5c-0.3,0-0.5-0.1-0.7-0.3c-0.7-0.7-0.5-2.9-0.4-5.2c0-0.5,0.1-1,0.1-1.6
c0.1-1.3,0.2-2.7,0.3-3.9c0.1-1.7,0.3-3.1,0.2-3.7c0-0.2-0.1-0.7-0.4-0.7c-0.4,0-0.7,0.2-1.9,5.8c-0.1,0.5-0.2,0.9-0.3,1.2
c-0.4,1.7-1.1,4.5-1.8,6.5c-0.6,1.7-1.3,1.9-1.8,1.8c-0.3,0-0.5-0.1-0.7-0.4c-0.3-0.4-0.3-1-0.1-1.9C95,303,97,293.7,97,292.1
c0-0.1,0-0.6-0.3-0.6c-0.4-0.1-0.8,0.3-2.8,5.5l-0.2,0.4c-0.1,0.3-0.3,0.7-0.4,1.1c-0.8,2.2-1.9,5.3-3.4,4.9
c-0.3-0.1-0.4-0.2-0.5-0.4c-0.4-0.8,0.3-2.7,0.8-4.2c0.2-0.6,0.5-1.2,0.6-1.8c0.2-0.8,0.6-2.3,1-4c0.7-2.9,1.5-6.1,1.9-7.2
c0.1-0.2,0.2-0.6-0.1-0.8c-0.4-0.2-0.8,0.2-2.4,1.8c-0.9,1-2.1,2.2-3,2.8c-1.2,0.9-2.2,1.2-2.9,1.1c-0.3-0.1-0.6-0.3-0.8-0.7
c-0.4-0.8,2.5-4.2,5-6.3c1-0.8,1.6-1.9,2.3-3.2c0.9-1.7,1.9-3.4,3.7-4.5c3.4-2.2,3.3-4.5,3.2-4.5c0-0.3,4.3-29.1,5.8-38.7
c1.3-8.4,3.1-13.5,5.3-15.7c1.7-1.7,1.8-4.9,2-9.3c0-1,0.1-2.1,0.2-3.3c0.2-3.4,1.4-6.3,2.8-9.5c1-2.4,2.2-5.2,3-8.6
c0,0,0-0.1,0-0.1c-0.4-3.3,1.7-21.7,7.9-25.9c3.2-2.2,7.4-2.9,11.2-3.5c2.6-0.4,5.1-0.9,7-1.7c1.4-0.6,3.2-1.2,4.9-1.7
c3.9-1.2,7.6-2.4,8.2-4.7c0.5-2.3,0.6-4.5,0.5-5.7c0.2,0.3,0.5,0.5,0.7,0.8c4.8,4.7,7.1,4.6,8.6,4.6l0.2,0l3,0c0.1,0,0.1,0,0.2,0
c1.5,0,3.8-0.2,8.4-4.6c0.3-0.2,0.5-0.5,0.7-0.8c0,1.2,0,3.4,0.5,5.7c0.5,2.2,4.2,3.4,8.2,4.7c1.7,0.5,3.5,1.1,4.9,1.7
c1.9,0.9,4.4,1.3,7,1.7c3.8,0.6,8,1.3,11.2,3.5c6.1,4.2,8.3,22.7,7.9,25.9c0,0,0,0.1,0,0.1c0.8,3.4,2,6.2,3,8.6
c1.4,3.3,2.5,6.1,2.8,9.5c0.1,1.2,0.1,2.2,0.2,3.3c0.2,4.5,0.4,7.7,2,9.3c2.2,2.2,3.9,7.3,5.3,15.7c1.5,9.6,5.8,38.4,5.8,38.6
c0,0.1-0.2,2.3,3.2,4.6c1.8,1.2,2.8,2.9,3.7,4.5c0.7,1.2,1.3,2.4,2.3,3.2C249.9,285.9,252.8,289.3,252.4,290.1z"
      />
      <path
        className="st19"
        d="M184.3,123.6c0,0,1.6-4.4,4.4-2.9c1.1,0.6,0.9,4.4-0.7,7.3c-1.6,2.9-2,5.5-3.1,5.5c-1.1,0-1.3-0.5-1.8-2.2"
      />
      <path
        className="st19"
        d="M152.6,123.6c0,0-1.6-4.4-4.4-2.9c-1.1,0.6-0.9,4.4,0.7,7.3s2,5.5,3.1,5.5s1.3-0.5,1.8-2.2"
      />
    </g>
  );
}
