export default function Browser(args: any) {
  return (
    <div className="browser">
      <div className="head">
        <div className="btns">
          <i className="fa-solid fa-circle close" />
          <i className="fa-solid fa-circle away" />
          <i className="fa-solid fa-circle full" />
        </div>
        <i className="fa-solid fa-ellipsis-vertical" />
      </div>
      <div className="body">{args.children}</div>
    </div>
  );
}
