import { useEffect, useState } from "react";
import Body from "../../concern/Body";
import Loading from "../../concern/Loading";
import { Link, useParams } from "react-router-dom";
import React from "react";
import NotFound from "../../concern/NotFound";
import Modal from "../../concern/Modal";
import ThumbPath from "../../concern/ThumbPath";

export default function Edit({ table = "menus" }) {
  const { id } = useParams();
  let [resp, setResp] = useState<{ [key: string]: any }>();
  let [trainings, setTrainings] = useState<{ [key: string]: any }>();
  let [second, setSecond] = useState<number>(0);
  let [results, setResults] = useState<{
    [key: string]: { [key: string]: number }[];
  }>();
  let [trainingId, setTrainingId] = useState<number>();
  let [weight, setWeight] = useState<number>(0);
  let [reps, setReps] = useState<number>(0);
  let [step, setStep] = useState<number>(1);

  useEffect(() => {
    (async () => {
      try {
        const resp = await (
          await fetch(`/api/${table}/with_result/${id}`)
        ).json();
        const _trainings: { [key: string]: any } = {};
        resp.trainings.forEach((training: { [key: string]: any }) => {
          _trainings[training.id] = {};
          training.TrainingParts.forEach(
            (trainingParts: { [key: string]: any }) => {
              _trainings[training.id][trainingParts.part_id] =
                trainingParts.impact;
            }
          );
        });
        const _results = { ...results };
        let _trainingId;
        resp.row.MenuTrainings.forEach(
          (menuTraining: { [key: string]: any }) => {
            if (menuTraining.Training.Results.length < resp.sets) {
              _trainingId ||= menuTraining.training_id;
            }
            _results[menuTraining.training_id] = menuTraining.Training.Results;
          }
        );

        setResp(resp);
        setTrainings(_trainings);
        setResults(_results);
        setTrainingId(_trainingId);
      } catch (error) {
        console.error(error);
        setResp({});
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, table]);

  useEffect(() => {
    if (second > 0) {
      setTimeout(() => {
        setSecond((seconds) => seconds - 1);
      }, 1000);
    }
  }, [second]);

  if (resp === undefined) return <Loading />;
  if (Object.keys(resp).length === 0) return <NotFound />;
  if (!resp.row || (resp.row.MenuTrainings || []).length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center text-center bg-primary">
        <div>
          <h1 className="fw-bold text-white mb-5">
            トレーニングがセットされていません
          </h1>
          <Link to="/training" className="text-white">
            一覧へ
          </Link>
        </div>
      </div>
    );
  }

  const _setTrainingId = (_trainingId: number) => {
    setWeight(0);
    setReps(0);
    setTrainingId(_trainingId);
  };

  const onSubmit = async (callback: any = null) => {
    setStep(0);
    const _results: { [key: string]: number }[] = [];
    Object.keys(results!).forEach((id: string) => {
      results![id].forEach((row: any) => {
        if (row.id) return;
        _results.push({
          id: parseInt(id),
          weight: row.weight,
          reps: row.reps,
        });
      });
    });
    const resp = await (
      await fetch(`/api/${table}/with_result/${id}`, {
        method: "POST",
        body: JSON.stringify(_results),
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    setStep(resp.ok ? 2 : 3);
    if (resp.ok && callback) callback();
    // setTimeout(() => {
    //   setStep(3);
    //   if (false && callback) callback();
    // }, 2000);
  };

  const displayMin = (sec: number) => {
    return `${Math.floor(sec / 60)}:${("0" + (sec % 60)).slice(-2)}`;
  };

  const getNextTrainingID = (): number | null => {
    for (let menuTraining of resp!.row.MenuTrainings) {
      if (results![menuTraining.training_id].length >= resp!.sets) continue;
      return parseInt(menuTraining.training_id);
    }

    return null;
  };

  const getClassName = (menuTraining: { [key: string]: any }, i: number) => {
    if (menuTraining.training_id === trainingId) return "current";
    if (results![menuTraining.training_id].length >= resp!.sets) {
      return "done";
    }
    return "";
  };

  const prev = resp.result[`${trainingId}`] || {};
  if (weight === 0) weight = prev.weight || 0;
  if (reps === 0) reps = prev.reps || 0;

  const exists: number[] = [];
  let _trainingParts: { [key: string]: { [key: string]: number } } = {};
  resp.row.MenuTrainings.forEach((menuTrainings: { [key: string]: any }) => {
    exists.push(menuTrainings.Training.id);
    const training = (trainings || {})[menuTrainings.Training.id];
    Object.keys(training).forEach((part_id: string) => {
      _trainingParts[part_id] ||= {
        part_id: parseInt(part_id),
        impact: 0,
      };
      _trainingParts[part_id].impact += training[part_id];
    });
  });

  const max = 4;
  let trainingParts: { [key: string]: number }[] = Object.values(
    _trainingParts
  ).map((trainingPart: { [key: string]: number }) => {
    if (trainingPart.impact > max) {
      trainingPart.impact = max;
    }
    return trainingPart;
  });

  const width: number = 60;

  return (
    <>
      <main id="training">
        <div className="container border-bottom py-3 mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mb-0">{resp!.row.name}</h3>
            <div>
              {!trainingId || step === 2 ? (
                <Link to="/training" className="btn btn-outline-secondary">
                  戻る
                </Link>
              ) : (
                step !== 0 &&
                (step === 3 ? (
                  <Link to="/training" className="btn btn-danger">
                    保存せず終了
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#endModal"
                  >
                    終了
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <table className="table table-bordered">
                <thead className="text-center">
                  <tr>
                    {Array(resp!.sets)
                      .fill("")
                      .map((_, i) => (
                        <th colSpan={2} key={i}>
                          {i + 1}
                        </th>
                      ))}
                  </tr>
                  <tr>
                    {Array(resp!.sets)
                      .fill("")
                      .map((_, i) => (
                        <React.Fragment key={i}>
                          <th style={{ width: `${width}px` }}>
                            <i className="fa-solid fa-dumbbell fa-fw" />
                          </th>
                          <th style={{ width: `${width}px` }}>
                            <i className="fa-solid fa-repeat fa-fw" />
                          </th>
                        </React.Fragment>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {resp.row.MenuTrainings.map(
                    (menuTraining: { [key: string]: any }, i: number) => (
                      <React.Fragment key={i}>
                        <tr className={getClassName(menuTraining, i)}>
                          <th
                            style={{ whiteSpace: "nowrap" }}
                            colSpan={resp!.sets * 2}
                          >
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                _setTrainingId(menuTraining.training_id);
                              }}
                            >
                              <img
                                src={ThumbPath(menuTraining.Training.User)}
                                className="rounded-circle me-2"
                                alt={menuTraining.Training.User.name}
                              />
                              {menuTraining.Training.name}
                            </a>
                          </th>
                        </tr>
                        <tr className={getClassName(menuTraining, i)}>
                          {Array(resp!.sets)
                            .fill("")
                            .map((_, j) => {
                              return (
                                <React.Fragment key={j}>
                                  {results![menuTraining.training_id][j] ? (
                                    <>
                                      <td>
                                        {results![menuTraining.training_id][j]
                                          .weight || "-"}
                                      </td>
                                      <td>
                                        {
                                          results![menuTraining.training_id][j]
                                            .reps
                                        }
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        <div className="invisible">0</div>
                                      </td>
                                      <td>
                                        <div className="invisible">0</div>
                                      </td>
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </tr>
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
              {trainingId && (
                <div className="text-center mb-3">
                  {step === 0 ? (
                    <>
                      保存中
                      <i className="fa-solid fa-spinner fa-pulse fa-fw ms-1" />
                    </>
                  ) : step === 1 ? (
                    <>
                      {second > 0 ? (
                        <>
                          <div className="text-center">休憩中…</div>
                          <div id="timer">{displayMin(second)}</div>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={(e) => {
                              setSecond(0);
                            }}
                          >
                            再開する
                          </button>
                        </>
                      ) : results![`${trainingId}`].length < resp!.sets ? (
                        <>
                          <form
                            className="input-group"
                            onSubmit={(e) => {
                              e.preventDefault();
                              if (!getNextTrainingID()) return;

                              results![`${trainingId}`].push({
                                weight: weight,
                                reps: reps,
                              });
                              setResults({ ...results });
                              setSecond(resp!.interval_second);
                            }}
                          >
                            <label
                              className="input-group-text"
                              htmlFor="form-category"
                            >
                              <i className="fa-solid fa-dumbbell fa-fw" />
                            </label>
                            <input
                              key={`weight-${trainingId}`}
                              id="form-weight"
                              type="number"
                              className="form-control"
                              defaultValue={weight === 0 ? "" : weight}
                              placeholder="重量"
                              min={0}
                              max={999}
                              onChange={(e) => {
                                setWeight(parseInt(e.target.value) || 0);
                              }}
                            />
                            <label
                              htmlFor="form-name"
                              className="input-group-text"
                            >
                              <i className="fa-solid fa-repeat fa-fw" />
                            </label>
                            <input
                              key={`reps-${trainingId}`}
                              id="form-reps"
                              type="number"
                              className="form-control"
                              defaultValue={reps === 0 ? "" : reps}
                              placeholder="回数"
                              min={1}
                              max={999}
                              required
                              onChange={(e) => {
                                setReps(parseInt(e.target.value) || 0);
                              }}
                            />
                            <button type="submit" className="btn btn-primary">
                              記録
                            </button>
                          </form>
                        </>
                      ) : getNextTrainingID() ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            _setTrainingId(getNextTrainingID()!);
                          }}
                        >
                          次の種目
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => {
                            onSubmit();
                          }}
                        >
                          保存する
                        </button>
                      )}
                    </>
                  ) : step === 2 ? (
                    <>
                      保存しました
                      <br />
                      <Link to="/training">戻る</Link>
                    </>
                  ) : (
                    <>
                      <i className="fa-solid fa-triangle-exclamation me-1 text-warning" />
                      <span className="text-danger">保存に失敗しました</span>
                      <br />
                      <a
                        href="/"
                        className="btn btn-success mt-2"
                        onClick={(e) => {
                          e.preventDefault();
                          onSubmit();
                        }}
                      >
                        再度保存する
                      </a>
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="col-md-6">
              <Body
                key={resp.row.MenuTrainings.length}
                trainings={trainingParts}
              />
            </div>
          </div>
        </div>
      </main>
      <Modal
        id="end"
        title="終了の確認"
        body={<p>終了します。保存しますか？</p>}
        footer={
          <>
            <Link to="/training" className="btn btn-danger">
              終了
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                (document as any)
                  .querySelector("#endModal .btn-outline-secondary")
                  .click();
                onSubmit(() => {
                  window.location.href = "/training";
                });
              }}
            >
              保存して終了
            </button>
          </>
        }
      />
    </>
  );
}
