import { Link } from "react-router-dom";

export default function NotFound(args: { [key: string]: any }) {
  return (
    <div className="d-flex align-items-center justify-content-center text-center bg-primary">
      <div>
        <h1 className="display-1 fw-bold text-white">{args.text || "404"}</h1>
        <Link to="/" className="text-white">
          Return to top
        </Link>
      </div>
    </div>
  );
}
