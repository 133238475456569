// import { Link, useLocation, matchPath, useMatch } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

const CustomLink = ({
  children,
  to,
  paths,
  ...props
}: {
  children: any;
  to: string;
  paths: string[];
}) => {
  const location = useLocation();
  // const match = useMatch(to);

  const _props: { [key: string]: any } = JSON.parse(JSON.stringify(props));
  _props.className ||= "";
  for (let path of paths) {
    const re = new RegExp(`^${path}.*`);
    if (!location.pathname.match(re)) continue;
    _props.className += " active";
  }
  return (
    <Link to={to} {..._props}>
      {children}
    </Link>
  );
};

export default CustomLink;
