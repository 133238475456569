import React, { useEffect, useState } from "react";
import Loading from "../../concern/Loading";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Calendar from "../../concern/Calendar";
import Body from "../../concern/Body";
import Nav from "./Nav";

export default function Main(recoveries: boolean) {
  const navigate = useNavigate();
  let { date } = useParams();
  date ||= moment().format("YYYY-MM-DD");

  let [resp, setResp] = useState<{ [key: string]: any }>();
  let [isParts, setIsParts] = useState<boolean>(true);
  let [showText, setShowText] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      let resp = await (
        await fetch(`/api/results/progress/${date}${window.location.search}`)
      ).json();
      setResp(resp);
    })();
  }, [date]);

  if (resp === undefined) return <Loading />;

  const onChange = (date: string) => {
    navigate(
      `/${recoveries ? "recoveries" : "progress"}/${date}${
        window.location.search
      }`
    );
  };

  const moveDate = (e: any, i: number) => {
    e.preventDefault();
    date = moment(date).add(i, "days").format("YYYY-MM-DD");
    onChange(date);
  };

  let parts: { [key: string]: string } = {};
  let trainings: { [key: string]: number[] } = {};
  let initValues: { [key: string]: number } = {};
  (resp.rows || []).forEach((row: any) => {
    parts[`${row.id}`] = row.name;
    initValues[`${row.id}`] = 0;
    const ymd = moment(row.trained_at).format("YYYY-MM-DD");
    trainings[ymd] ||= [];
    trainings[ymd].push(row.id);
  });

  const _rows: { [key: string]: { [key: string]: number } } = {};
  for (
    var m = moment(date);
    m.isAfter(moment(date).subtract(8, "days"));
    m.subtract(1, "days")
  ) {
    _rows[m.format("YYYY-MM-DD")] = { ...initValues };
  }
  (resp.rows || []).forEach((row: any) => {
    const recoveryDays: number = row.recovery / 24;
    if (recoveries) {
      let i = 1;
      for (
        var m = moment(moment(row.trained_at));
        m.isBefore(moment(row.recovered_at));
        m.add(1, "days")
      ) {
        if (!_rows[m.format("YYYY-MM-DD")]) continue;
        _rows[m.format("YYYY-MM-DD")][`${row.id}`] += i;
        i -= 1 / recoveryDays;
      }
    } else {
      if (!_rows[row.trained_at]) return;
      _rows[row.trained_at][`${row.id}`] = 1;
    }
  });

  const rows: { [key: string]: any[] } = {};
  Object.keys(_rows).forEach((date) => {
    rows[date] = Object.keys(_rows[date]).map((id: string) => {
      let impact = _rows[date][id];
      if (impact >= 1.000001) {
        impact = 9;
      } else if (impact >= 1) {
        impact = 2;
      } else if (impact > 0) {
        impact = 1;
      } else if (recoveries) {
        const prev_date = moment(date).subtract(1, "days").format("YYYY-MM-DD");
        let prev_impact = (_rows[prev_date] || {})[id] || 0;
        if (prev_impact > 0) {
          impact = -1;
        }
      }
      // if (id === "16") {
      //   console.log(impact);
      // }
      return {
        impact: impact,
        part_id: id,
      };
    });
  });

  return (
    <main key={date}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6 mb-3">
            <h2 className="header mb-0">{recoveries ? "回復" : "実績"}</h2>
          </div>
          <div className="col-sm-6 mb-3">
            <div className="d-flex justify-content-around align-items-center">
              <a href="/" onClick={(e) => moveDate(e, -1)}>
                <i className="fa-solid fa-angle-left" />
              </a>
              <Calendar
                key={date}
                defaultValue={date}
                fetch={async (start: string, end: string) => {
                  const resp = await (document as any).fetch(
                    `results/${start}/${end}${window.location.search}`
                  );
                  return resp.results;
                }}
                onChange={async (date: string) => {
                  onChange(date);
                }}
              />
              <a href="/" onClick={(e) => moveDate(e, 1)}>
                <i className="fa-solid fa-angle-right" />
              </a>
            </div>
          </div>
        </div>
        <Nav current={recoveries ? "recoveries" : "progress"} date={date} />
        <div className="d-flex align-items-center mb-3">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              defaultChecked={showText}
              onChange={(e) => {
                setShowText(e.target.checked);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              Textの表示
            </label>
          </div>
          {recoveries && (
            <>
              <div className="sample ms-2 me-1"></div>
              <div>回復済</div>
              <div className="sample active1 ms-2 me-1"></div>
              <div>疲労小</div>
              <div className="sample active2 ms-2 me-1"></div>
              <div>疲労大</div>
              <div className="sample active9 ms-2 me-1"></div>
              <div>オーバー</div>
              <div className="sample active-1 ms-2 me-1"></div>
              <div>回復当日</div>
            </>
          )}
        </div>
        {Object.keys(parts).length === 0 ? (
          <>Not Found</>
        ) : (
          <div className="row flex-row-reverse">
            {Object.keys(rows).map((date: string) => (
              <div key={date} className={`col-${showText ? "md-6" : "lg-3"}`}>
                <h6 className="mb-2 label label-success">
                  {moment(date).format("YYYY/MM/DD(ddd)")}
                </h6>
                <div className="row mb-3">
                  <div className={`col-${showText ? 5 : 12}`}>
                    <Body trainings={rows[date]} labelHidden={true} />
                  </div>
                  {showText && (
                    <div
                      className="col-7"
                      style={{
                        overflowX: "hidden",
                        overflowY: "scroll",
                        position: "relative",
                      }}
                    >
                      <ul
                        onClick={(e) => {
                          e.preventDefault();
                          setIsParts(!isParts);
                        }}
                        style={{ position: "absolute", fontSize: "12px" }}
                      >
                        {isParts
                          ? (trainings[date] || []).map((id: number) => (
                              <li key={id}>{parts[id]}</li>
                            ))
                          : ((resp || {}).trainings[date] || []).map(
                              (row: any) => <li key={row.id}>{row.name}</li>
                            )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </main>
  );
}
