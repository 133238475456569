import React, { useEffect, useState } from "react";
import Loading from "../../concern/Loading";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import qs from "qs";
import Alert from "../../concern/Alert";
import Calendar from "../../concern/Calendar";
import Nav from "./Nav";

const formDataToQueryString = (formData: any) => {
  const params = new URLSearchParams();
  for (const [key, value] of formData) {
    params.append(key, value as string);
  }
  return params.toString();
};

let intervalID: NodeJS.Timeout | null = null;
const COL_SIZE = "lg";
const COL_LEN = 4;

export default function Main() {
  const navigate = useNavigate();
  let { date } = useParams();
  date ||= moment().format("YYYY-MM-DD");

  let [resp, setResp] = useState<{ [key: string]: any }>();
  let [alert, setAlert] = useState<[string, string]>(["", ""]);

  useEffect(() => {
    (async () => {
      let resp = await (
        await fetch(`/api/results/${date}${window.location.search}`)
      ).json();
      setResp(resp);
    })();
  }, [date]);

  useEffect(() => {
    if (!alert[0]) return;
    intervalID = setTimeout(() => {
      setAlert(["", ""]);
    }, 5000);
  }, [alert]);

  if (resp === undefined) return <Loading />;

  const onChange = (date: string) => {
    navigate(`/results/${date}${window.location.search}`);
  };

  const setMessage = (type: string, message: string) => {
    clearTimeout(intervalID as NodeJS.Timeout);
    setAlert([type, message]);
  };

  const moveDate = (e: any, i: number) => {
    e.preventDefault();
    date = moment(date).add(i, "days").format("YYYY-MM-DD");
    onChange(date);
  };

  const inputRow = (result: any, i: number, onclick: any, onchange: any) => {
    return (
      <div className="input-group input-group-sm">
        <span className="input-group-text bg-white">{i + 1}</span>
        <span className="input-group-text">
          <i className="fa-solid fa-dumbbell fa-fw" />
        </span>
        <input
          key={`weights-${result.updatedAt}`}
          name={`weights[${result.id}]`}
          className="form-control"
          type="number"
          defaultValue={result.weight}
          onChange={(e) => {
            onchange(e, "weight");
          }}
        />
        <span className="input-group-text">
          <i className="fa-solid fa-repeat fa-fw" />
        </span>
        <input
          key={`reps-${result.updatedAt}`}
          name={`reps[${result.id}]`}
          className="form-control"
          type="number"
          defaultValue={result.reps}
          onChange={(e) => {
            onchange(e, "reps");
          }}
        />
        {false && (
          <span className="input-group-text">
            <a href="/" onClick={onclick}>
              <i className="fa-regular fa-trash-can fa-fw text-danger" />
            </a>
            <i className="fa-solid fa-spinner fa-pulse fa-fw" />
          </span>
        )}
      </div>
    );
  };

  let max = 0;
  resp.results.forEach((training: any) => {
    if (training.Results.length > max) max = training.Results.length;
  });
  let counts = [];
  for (let i = 0; i < max; i++) {
    counts.push(i);
  }

  const ymdhis = moment().format("YYYYMMDDhhmmss");

  return (
    <main>
      <Alert setAlert={setAlert} type={alert[0]} message={alert[1]} />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-6 mb-3">
            <h2 className="header mb-0">履歴</h2>
          </div>
          <div className="col-sm-6 mb-3">
            <div className="d-flex justify-content-around align-items-center">
              <a href="/" onClick={(e) => moveDate(e, -1)}>
                <i className="fa-solid fa-angle-left" />
              </a>
              <Calendar
                key={date}
                defaultValue={date}
                fetch={async (start: string, end: string) => {
                  const resp = await (document as any).fetch(
                    `results/${start}/${end}${window.location.search}`
                  );
                  return resp.results;
                }}
                onChange={async (date: string) => {
                  onChange(date);
                }}
              />
              <a href="/" onClick={(e) => moveDate(e, 1)}>
                <i className="fa-solid fa-angle-right" />
              </a>
            </div>
          </div>
        </div>
        <Nav current="results" date={date} />
        {counts.length === 0 ? (
          <div className="alert alert-primary show" role="alert">
            トレーニングしてません
          </div>
        ) : (
          <form
            className="results"
            onSubmit={async (e: any) => {
              e.preventDefault();
              setResp(undefined);

              const formData = new FormData(e.target);
              const data = qs.parse(formDataToQueryString(formData));
              const resp = await (
                await fetch(`/api/results/${date}`, {
                  method: "POST",
                  body: JSON.stringify(data),
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
              ).json();

              setMessage("success", "更新しました");
              setResp(resp);
            }}
          >
            {resp.results.map((training: any, i: number) => (
              <div className="row mb-3" key={training.id}>
                <h2 className={`col-${COL_SIZE}-12`}>{training.name}</h2>
                {training.Results.map((result: any, j: number) => (
                  <div
                    key={result.id || `${j}-${ymdhis}`}
                    className={`col-${COL_SIZE}-${COL_LEN} mt-1`}
                  >
                    {inputRow(
                      result,
                      j,
                      (e: any) => {
                        e.preventDefault();
                        e.target
                          .closest(".input-group")
                          .classList.add("disabled");

                        const _resp = JSON.parse(JSON.stringify(resp));
                        _resp!.results[i].Results.splice(j, 1);
                        setResp(_resp);
                      },
                      (e: any, key: string) => {
                        resp!.results[i].Results[j][key] =
                          parseInt(e.target.value) || 0;
                        // setResp(resp);
                      }
                    )}
                  </div>
                ))}
                {false && (
                  <div className={`col-${COL_SIZE}-${COL_LEN} mt-1`}>
                    <button
                      key={training.Results.length}
                      type="button"
                      className="btn btn-outline-secondary btn-sm w-100"
                      onClick={(e: any) => {
                        e.target.disabled = true;
                        const _resp = JSON.parse(JSON.stringify(resp));
                        _resp!.results[i].Results.push({
                          id: null,
                          weight: null,
                          reps: null,
                          updatedAt: null,
                        });
                        setResp(_resp);
                      }}
                    >
                      <span>追加</span>
                      <i className="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse" />
                    </button>
                  </div>
                )}
              </div>
            ))}
            <div className="text-end">
              <a href="/" className="btn btn-outline-secondary me-3">
                戻す
              </a>
              <button className="btn btn-success">保存する</button>
            </div>
          </form>
        )}
      </div>
    </main>
  );
}
