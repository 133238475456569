import React, { useRef, useState } from "react";
import ThumbPath from "./ThumbPath";

export const resizeImage = async (
  imageData: Blob,
  desiredSize: number
): Promise<string | null> => {
  try {
    const context = document.createElement("canvas").getContext("2d");
    if (context == null) return null;

    const image: HTMLImageElement = await new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", reject);
      image.src = URL.createObjectURL(imageData);
    });
    const { naturalHeight: height, naturalWidth: width } = image;
    let afterWidth = desiredSize;
    let afterHeight = desiredSize;
    let x = 0;
    let y = 0;
    let ratio = 0.0;
    const proportion = height / width;
    if (proportion > 1) {
      // 縦長
      ratio = desiredSize / width;
      afterHeight = height * ratio;
      y = (afterHeight - afterWidth) / -2;
    } else {
      // 横長
      ratio = desiredSize / height;
      afterWidth = width * ratio;
      x = (afterWidth - afterHeight) / -2;
    }

    context.canvas.width = desiredSize;
    context.canvas.height = desiredSize;
    context.drawImage(
      image,
      0,
      0,
      width,
      height,
      x,
      y,
      afterWidth,
      afterHeight
    );
    return context.canvas.toDataURL(imageData.type);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default function Image2(args: { [key: string]: any }) {
  const [base64Image, setBase64Image] = useState<string>(args.defaultValue);
  const [dragOver, setDragOver] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const setFileImage = async (file: any) => {
    if (!file) return;

    const image: string | null = await resizeImage(file, 100);
    if (image) setBase64Image(image);
  };

  const handleInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;

    setFileImage(Array.from(files)[0]);
    if (inputRef.current) inputRef.current.value = "";
  };

  const handleImageClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setBase64Image("");
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);

    const files = e.dataTransfer.files;
    if (files.length === 0) return;

    const file = files[0];
    if (
      !["jpeg", "jpg", "gif", "png"].includes(file.type.replace("image/", ""))
    ) {
      return;
    }

    setFileImage(file);
  };

  return (
    <label className="image-component">
      <div className="form-label">画像</div>
      {base64Image.match(/^data:image\//) ? (
        <input type="hidden" name="img" value={base64Image} />
      ) : base64Image === "" ? (
        <input type="hidden" name="img" value="" />
      ) : (
        <></>
      )}
      <div
        onDragEnter={() => setDragOver(true)}
        onDragLeave={() => setDragOver(false)}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
        className={`preview mb-2 ${dragOver ? "drag-over" : ""}`}
        style={{ backgroundImage: `url("${base64Image || ThumbPath({})}")` }}
      >
        {base64Image && base64Image !== "/imgs/404.png" ? (
          <i
            className="fa-solid fa-trash-can text-danger"
            onClick={handleImageClick}
          />
        ) : (
          <></>
        )}
      </div>
      <input
        type="file"
        accept="image/jpeg, image/jpg, image/gif, image/png"
        onChange={handleInputFile}
        ref={inputRef}
      />
    </label>
  );
}
