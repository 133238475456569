import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Login(args: any) {
  let [error, setError] = useState<string>("");

  return (
    <>
      <div className="login">
        <div className="btns p-3">
          {"apple google line".split(" ").map((brand: string) => (
            <React.Fragment key={brand}>
              {args[brand] && (
                <a href={args[brand].replace("***", "2")} className="text-body">
                  <i className={`fa-brands fa-${brand} fa-fw`} />
                  {brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase()}
                </a>
              )}
            </React.Fragment>
          ))}
        </div>
        <form
          className="py-4 px-3"
          onSubmit={async (e: any) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const data = Object.fromEntries(formData.entries());

            const resp = await (document as any).fetch("login", {
              method: "POST",
              body: JSON.stringify(data),
            });

            if (resp.ok) {
              (window as any).location.href = "/";
            } else {
              setError("メールアドレスまたはパスワードが不正です");
            }
          }}
        >
          <label className="d-block">
            <div className="form-label">メールアドレス</div>
            <div className="input-group">
              <span className="input-group-text">
                <i className="fa-solid fa-envelope fa-fw" />
              </span>
              <input
                type="email"
                name="email"
                className="form-control"
                required
              />
            </div>
          </label>
          <label className="d-block mt-3">
            <div className="form-label">パスワード</div>
            <div className="input-group">
              <span className="input-group-text">
                <i className="fa-solid fa-key fa-fw" />
              </span>
              <input
                type="password"
                name="password"
                className="form-control"
                autoComplete=""
                required
              />
            </div>
          </label>
          <div className="links text-center mt-3">
            <Link to="/forgot">パスワードを忘れた方</Link>
            {false && (
              <>
                <span className="mx-2">/</span>
                <Link to="/">ログインでお困りの方</Link>
              </>
            )}
          </div>
          {error && <div className="text-danger text-center mt-3">{error}</div>}
          <div className="d-grid mt-4">
            <button className="btn btn-lg btn-success">ログイン</button>
          </div>
        </form>
        <div className="signup text-center p-3">
          <a href="/" data-bs-toggle="modal" data-bs-target="#signupModal">
            会員登録はこちら
          </a>
        </div>
      </div>
      <div className="modal fade" id="signupModal" tabIndex={-1} data-x="y">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                会員登録
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="d-grid gap-3">
                <a href="/signup" className="btn btn-lg btn-primary">
                  <i className="fa-solid fa-envelope fa-fw me-2" />
                  メールアドレスで登録
                </a>
                {"apple google line".split(" ").map((brand: string) => (
                  <React.Fragment key={brand}>
                    {args[brand] && (
                      <a
                        href={args[brand].replace("***", "1")}
                        className="btn btn-lg btn-outline-secondary"
                      >
                        <i className={`fa-brands fa-${brand} fa-fw me-2`} />
                        {brand.charAt(0).toUpperCase() +
                          brand.slice(1).toLowerCase()}
                        で登録
                      </a>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
